export var DisplayPosition;
(function (DisplayPosition) {
    DisplayPosition["dialog"] = "display_dialog";
    DisplayPosition["formDialog"] = "display_formDialog";
    DisplayPosition["form"] = "display_form";
    DisplayPosition["filter"] = "display_filter";
    DisplayPosition["tableCell"] = "display_tableCell";
    DisplayPosition["card"] = "display_card";
    DisplayPosition["subCard"] = "display_subCard";
    DisplayPosition["cnArrayTable"] = "display_cnArrayTable";
    DisplayPosition["tableRowDetail"] = "display_tableRowDetail";
    //弹窗标题支持使用业务组件
    DisplayPosition["dialogTitle"] = "display_dialogTitle";
    // 自增卡片
    DisplayPosition["cnArraySubAreaCard"] = "display_cnArraySubAreaCard";
    // 表格顶部插槽
    DisplayPosition["tableTopSlot"] = "display_tableTopSlot";
    // 可编辑表格底部数据汇总
    DisplayPosition["arrayTableSummary"] = "display_arrayTableSummary";
    // 标签项
    DisplayPosition["tabItem"] = "display_tabItem";
    // 信息提示组件
    DisplayPosition["message"] = "display_message";
    // list-item 子项内容自定义渲染
    DisplayPosition["listItemContentRender"] = "display_listItemContentRender";
    // list-item 子项描述自定义渲染
    DisplayPosition["listItemDescriptionRender"] = "display_listItemDescriptionRender";
    // cn-detail-info 描述自定义渲染
    DisplayPosition["detailInfoDescriptionRender"] = "display_detailInfoDescriptionRender";
    // CnFormat
    DisplayPosition["cnFormat"] = "display_cnFormat";
})(DisplayPosition || (DisplayPosition = {}));
