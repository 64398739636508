import { Button as UIButton, formilyReact } from '@cainiaofe/cn-ui';
import { executeFunction } from '@/common/util/util';
const { useField } = formilyReact;
const ArrayTableOptButton = {
    title: '按钮',
    componentName: 'ArrayTableOptButton',
    formComponent: (props) => {
        const { onClick, getChildren } = props;
        const extraProps = {};
        if (onClick) {
            let field;
            try {
                field = useField();
            }
            catch (e) { }
            extraProps.onClick = () => {
                onClick({
                    field,
                });
            };
        }
        if (typeof getChildren === 'function') {
            extraProps.children = executeFunction(getChildren);
            extraProps.getChildren = undefined;
        }
        return <UIButton {...props} {...extraProps}/>;
    },
};
export default ArrayTableOptButton;
