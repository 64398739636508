import { condition, handleSelectDataSource, handleSelectRequestConfig, setAdvancedConfigToProps, } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { getRequestConfigSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { __advancedConfig__ } from '@/common/util/const';
import { Transfer as UITransfer, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
const Transfer = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '穿梭框',
    thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01i1EVG91ng5vLuqJ4l_!!6000000005118-2-tps-240-144.png',
    componentName: 'Transfer',
    formComponent: formComponentMap.CnTransfer || UITransfer,
    getDefaultProps: () => {
        return {
            __advancedConfig__: {
                showSearch: true,
            },
            dataSource: [
                {
                    label: '选项1',
                    value: 'value1',
                },
                {
                    label: '选项2',
                    value: 'value2',
                },
            ],
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
        });
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
        setAdvancedConfigToProps(formItem?.['x-component-props']);
    },
    // RadioGroup的prototype列表
    getPrototypeList: (position) => {
        const extraSetters = [
            {
                name: 'disabled',
                display: 'inline',
                title: '是否禁用',
                setter: 'BoolSetter',
            },
            {
                name: 'description',
                display: 'inline',
                title: '描述',
                setter: 'CnI18nSetter',
            },
        ];
        // extraSetters.push({
        //   name: 'description',
        //   display: 'inline',
        //   title: '描述',
        //   setter: 'CnI18nSetter',
        // })
        const extraStyleSetters = [
            {
                name: 'showSearch',
                title: '开启搜索',
                setter: 'BoolSetter',
            },
            {
                name: 'sortable',
                title: '开启拖拽排序',
                setter: 'BoolSetter',
            },
        ];
        return [
            {
                name: 'dataOrigin',
                title: '数据来源',
                display: 'inline',
                defaultValue: 'static',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: '远程请求', value: 'request' },
                            { title: '静态数据', value: 'static' },
                        ],
                    },
                },
            },
            getRequestConfigSetterSnippet({
                condition: (prop) => {
                    return (condition(prop, 'Transfer', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            }, {
                position,
            }),
            {
                name: 'dataSource',
                title: '穿梭框静态数据',
                display: 'plain',
                // hidden: hidden('CheckboxGroup'),
                condition(prop) {
                    return (condition(prop, 'Transfer', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'static');
                },
                setter: getStaticDataSourceSnippet({
                    extraArraySetterItems: extraSetters,
                }),
            },
            {
                title: '高级配置',
                name: __advancedConfig__,
                display: 'plain',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [...extraStyleSetters],
                        },
                    },
                },
            },
        ];
    },
};
export default Transfer;
