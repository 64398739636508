import React from 'react';
import {
  CnButton,
  CnAsyncSelect,
  CnDialog,
  CnInputGroup,
  CnMessage,
  CnConfirmButton,
} from '@cainiaofe/cn-ui';
import { request } from 'cn-request';
import './index.scss';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import { handleI18nLabel, isArrayNotEmpty } from '@/common/util/util';
import { getRealResponse } from '@/common/util/request';

const params = new URLSearchParams(location.search);
const pageId = params.get('pageId');
const appCode = window.getCnLowcodeStoreItem?.('PageDetail')?.appCode;

export default class BlockSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeSnippet = (v, method, record) => {
    const { field } = this.props;
    const { content } = record || {};
    let contentObj;
    try {
      contentObj = JSON.parse(content);
    } catch (e) {}
    if (
      isPlainObject(contentObj) &&
      typeof field?.parent?.setValue === 'function'
    ) {
      field.parent.setValue(contentObj);
    }
  };

  saveBlock = (e) => {
    const { field, subType } = this.props;
    const allValue = field?.parent?.getValue?.();
    if (isPlainObject(allValue) && pageId && appCode) {
      const label = handleI18nLabel(allValue.label);
      const name = allValue.name;
      if (name) {
        CnDialog.confirm({
          title: '是否将当前字段另存为片段？',
          popupContainer: () => {
            return e?.target;
          },
          onOk: () => {
            const tempValue = cloneDeep(allValue);
            const content = JSON.stringify(tempValue);
            request({
              url: '/nextapi/block/create',
              method: 'post',
              data: {
                appCode,
                type: 'SNIPPET',
                subType: subType || 'FORM',
                content,
                name: name,
                description: label || name,
                sourcePageId: pageId,
              },
            }).catch((err) => {
              console.log(err, 'err');
              if (!err?.hadShowErrorFeedback) {
                CnMessage.error('保存失败');
              }
            });
          },
          // popupContainer:(trigger)=>{
          //   return trigger?.parentNode;
          // }
        });
      } else {
        CnMessage.error('当前字段的编码不能为空');
      }
    }
  };

  render() {
    const { subType } = this.props;
    return (
      <div className={'block-setter-wrap'}>
        <CnInputGroup
          addonAfter={
            <CnButton
              size={'small'}
              onClick={this.saveBlock}
              style={{ flex: 1 }}
            >
              当前字段另存为区块
            </CnButton>
          }
        >
          <CnAsyncSelect
            autoWidth={false}
            style={{ width: '125px' }}
            placeholder={'从字段列表中选择'}
            size={'small'}
            showSearch
            filterLocal={false}
            onVisibleChange={(visible, type, run) => {
              if (visible) {
                run?.();
              }
            }}
            popupContainer={(trigger) => {
              return trigger.parentNode;
            }}
            requestConfig={{
              throttleWait: 800,
              searchKey: 'name',
              url: '/nextapi/block/snippet/list',
              method: 'get',
              params: {
                appCode,
                type: 'SNIPPET',
                subType: subType || 'FORM',
                pageSize: 10,
              },
              formatResult: (res) => {
                const result = [];
                const realRes = getRealResponse(res);
                if (isArrayNotEmpty(realRes?.data?.tableData)) {
                  realRes.data.tableData.forEach((item) => {
                    const { name, description, content } = item || {};
                    if (name && content) {
                      let label = name;
                      if (description) {
                        label = `${description}（${name}）`;
                      }
                      result.push({
                        label,
                        value: name,
                        content,
                      });
                    }
                  });
                }
                return result;
              },
            }}
            onChange={this.changeSnippet}
          />
        </CnInputGroup>
      </div>
    );
  }
}
