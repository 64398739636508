import { componentMap as formComponentMap, CnAsyncSelect, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import { getDataOriginSetterSnippet, getRequestConfigSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { calculateRequestExecute, condition, handleSelectDataSource, handleSelectRequestConfig, } from '@/common/util/util';
import isPlainObject from 'lodash/isPlainObject';
import { __dataSource__, __filterValue__, __formValue__, } from '@/common/util/expr-const';
const AutoComplete = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '辅助完成输入框（AutoComplete）',
    componentName: 'AutoComplete',
    thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01HLoZgU1DmfRUUTGmK_!!6000000000259-2-tps-240-144.png',
    component: CnAsyncSelect.AutoComplete,
    formComponent: formComponentMap.CnSelectAutoComplete,
    getDefaultProps: () => {
        return {
            dataOrigin: 'static',
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
        });
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
        const componentProps = formItem?.['x-component-props'];
        if (isPlainObject(componentProps)) {
            componentProps.onFocus = (e, run) => {
                const execute = calculateRequestExecute(componentProps?.requestConfig, {
                    [__formValue__]: formValue,
                    [__filterValue__]: formValue,
                    [__dataSource__]: state,
                }, formValue || {}, state);
                if (execute !== false) {
                    run?.();
                }
            };
            componentProps.onVisibleChange = undefined;
        }
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        handleSelectRequestConfig(config);
        handleSelectDataSource(config);
    },
    getPrototypeList: (position) => {
        return [
            getDataOriginSetterSnippet({
                display: 'inline',
            }),
            getRequestConfigSetterSnippet({
                condition: (prop) => {
                    return (condition(prop, 'AutoComplete', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            }, {
                position,
            }),
            {
                name: 'dataSource',
                title: '编辑静态数据',
                display: 'plain',
                tip: '编辑静态展示数据',
                setter: getStaticDataSourceSnippet(),
                condition(prop) {
                    return (condition(prop, 'AutoComplete', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') !== 'request');
                },
            },
        ];
    },
};
export default AutoComplete;
