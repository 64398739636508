export const countryList = [
    { value: 'AF', label: '阿富汗（AF）' },
    { value: 'AX', label: '奥兰群岛（AX）' },
    { value: 'AL', label: '阿尔巴尼亚（AL）' },
    { value: 'DZ', label: '阿尔及利亚（DZ）' },
    { value: 'AS', label: '美属萨摩亚（AS）' },
    { value: 'AD', label: '安道尔（AD）' },
    { value: 'AO', label: '安哥拉（AO）' },
    { value: 'AI', label: '安圭拉岛(英)（AI）' },
    { value: 'AQ', label: '南极洲（AQ）' },
    { value: 'AG', label: '安提瓜和巴布达（AG）' },
    { value: 'AR', label: '阿根廷（AR）' },
    { value: 'AM', label: '亚美尼亚（AM）' },
    { value: 'AW', label: '阿鲁巴（AW）' },
    { value: 'AU', label: '澳大利亚（AU）' },
    { value: 'AT', label: '奥地利（AT）' },
    { value: 'AZ', label: '阿塞拜疆（AZ）' },
    { value: 'BS', label: '巴哈马（BS）' },
    { value: 'BH', label: '巴林（BH）' },
    { value: 'BD', label: '孟加拉国（BD）' },
    { value: 'BB', label: '巴巴多斯（BB）' },
    { value: 'BY', label: '白俄罗斯（BY）' },
    { value: 'BE', label: '比利时（BE）' },
    { value: 'BZ', label: '伯利兹（BZ）' },
    { value: 'BJ', label: '贝宁（BJ）' },
    { value: 'BM', label: '百慕大（BM）' },
    { value: 'BT', label: '不丹（BT）' },
    { value: 'BO', label: '多民族玻利维亚国（BO）' },
    { value: 'BA', label: '波斯尼亚和黑塞哥维那（BA）' },
    { value: 'BW', label: '博茨瓦纳（BW）' },
    { value: 'BV', label: '布维岛（BV）' },
    { value: 'BR', label: '巴西（BR）' },
    { value: 'IO', label: '英属印度洋领地（IO）' },
    { value: 'BN', label: '文莱达鲁萨兰国（BN）' },
    { value: 'BG', label: '保加利亚（BG）' },
    { value: 'BF', label: '布基纳法索（BF）' },
    { value: 'BI', label: '布隆迪（BI）' },
    { value: 'KH', label: '柬埔寨（KH）' },
    { value: 'CM', label: '喀麦隆（CM）' },
    { value: 'CA', label: '加拿大（CA）' },
    { value: 'CV', label: '佛得角（CV）' },
    { value: 'BQ', label: '博内尔、圣马丁圣尤斯特和萨巴（BQ）' },
    { value: 'KY', label: '开曼群岛（KY）' },
    { value: 'CF', label: '中非共和国（CF）' },
    { value: 'TD', label: '乍得（TD）' },
    { value: 'CL', label: '智利（CL）' },
    { value: 'CN', label: '中国（CN）' },
    { value: 'CX', label: '圣诞岛（CX）' },
    { value: 'CC', label: '科科斯（基林）群岛（CC）' },
    { value: 'CO', label: '哥伦比亚（CO）' },
    { value: 'KM', label: '科摩罗（KM）' },
    { value: 'CD', label: '刚果民主共和国（CD）' },
    { value: 'CG', label: '刚果（CG）' },
    { value: 'CK', label: '库克群岛（CK）' },
    { value: 'CR', label: '哥斯达黎加（CR）' },
    { value: 'CI', label: '科特迪瓦（CI）' },
    { value: 'HR', label: '克罗地亚（HR）' },
    { value: 'CU', label: '古巴（CU）' },
    { value: 'CW', label: '库拉索（CW）' },
    { value: 'CY', label: '塞浦路斯（CY）' },
    { value: 'CZ', label: '捷克（CZ）' },
    { value: 'DK', label: '丹麦（DK）' },
    { value: 'DJ', label: '吉布提（DJ）' },
    { value: 'DM', label: '多米尼加（DM）' },
    { value: 'DO', label: '多米尼加共和国（DO）' },
    { value: 'TL', label: '东帝汶（TL）' },
    { value: 'EC', label: '厄瓜多尔（EC）' },
    { value: 'EG', label: '埃及（EG）' },
    { value: 'SV', label: '萨尔瓦多（SV）' },
    { value: 'GQ', label: '赤道几内亚（GQ）' },
    { value: 'ER', label: '厄立特里亚（ER）' },
    { value: 'EE', label: '爱沙尼亚（EE）' },
    { value: 'ET', label: '埃塞俄比亚（ET）' },
    { value: 'FK', label: '福克兰 (马尔维纳斯) 群岛（FK）' },
    { value: 'FO', label: '法罗群岛（FO）' },
    { value: 'FJ', label: '斐济（FJ）' },
    { value: 'FI', label: '芬兰（FI）' },
    { value: 'FR', label: '法国（FR）' },
    { value: 'GF', label: '法属圭亚那（GF）' },
    { value: 'PF', label: '法属波利尼西亚（PF）' },
    { value: 'TF', label: '法属南半球领地（TF）' },
    { value: 'GA', label: '加蓬（GA）' },
    { value: 'GM', label: '冈比亚（GM）' },
    { value: 'GE', label: '格鲁吉亚（GE）' },
    { value: 'DE', label: '德国（DE）' },
    { value: 'GH', label: '加纳（GH）' },
    { value: 'GI', label: '直布罗陀（GI）' },
    { value: 'GR', label: '希腊（GR）' },
    { value: 'GL', label: '格陵兰（GL）' },
    { value: 'GD', label: '格林纳达（GD）' },
    { value: 'GP', label: '瓜德罗普岛（GP）' },
    { value: 'GU', label: '关岛（GU）' },
    { value: 'GT', label: '危地马拉（GT）' },
    { value: 'GG', label: '格恩西岛（GG）' },
    { value: 'GN', label: '几内亚（GN）' },
    { value: 'GW', label: '几内亚比绍（GW）' },
    { value: 'GY', label: '圭亚那（GY）' },
    { value: 'HT', label: '海地（HT）' },
    { value: 'HM', label: '赫德岛和麦克唐纳群岛（HM）' },
    { value: 'HN', label: '洪都拉斯（HN）' },
    { value: 'HU', label: '匈牙利（HU）' },
    { value: 'IS', label: '冰岛（IS）' },
    { value: 'IN', label: '印度（IN）' },
    { value: 'ID', label: '印度尼西亚（ID）' },
    { value: 'IR', label: '伊朗 (伊斯兰共和国)（IR）' },
    { value: 'IQ', label: '伊拉克（IQ）' },
    { value: 'IE', label: '爱尔兰（IE）' },
    { value: 'IM', label: '马恩岛（IM）' },
    { value: 'IL', label: '以色列（IL）' },
    { value: 'IT', label: '意大利（IT）' },
    { value: 'JM', label: '牙买加（JM）' },
    { value: 'JP', label: '日本（JP）' },
    { value: 'JE', label: '新泽西（JE）' },
    { value: 'JO', label: '约旦（JO）' },
    { value: 'KZ', label: '哈萨克斯坦（KZ）' },
    { value: 'KE', label: '肯尼亚（KE）' },
    { value: 'KI', label: '基里巴斯（KI）' },
    { value: 'KW', label: '科威特（KW）' },
    { value: 'KG', label: '吉尔吉斯斯坦（KG）' },
    { value: 'LA', label: '老挝人民民主共和国（LA）' },
    { value: 'LV', label: '拉脱维亚（LV）' },
    { value: 'LB', label: '黎巴嫩（LB）' },
    { value: 'LS', label: '莱索托（LS）' },
    { value: 'LR', label: '利比里亚（LR）' },
    { value: 'LY', label: '利比亚（LY）' },
    { value: 'LI', label: '列支敦士登（LI）' },
    { value: 'LT', label: '立陶宛（LT）' },
    { value: 'LU', label: '卢森堡（LU）' },
    { value: 'MK', label: '前南斯拉夫马其顿共和国（MK）' },
    { value: 'MG', label: '马达加斯加（MG）' },
    { value: 'MW', label: '马拉维（MW）' },
    { value: 'MY', label: '马来西亚（MY）' },
    { value: 'MV', label: '马尔代夫（MV）' },
    { value: 'ML', label: '马里（ML）' },
    { value: 'MT', label: '马耳他（MT）' },
    { value: 'MH', label: '马绍尔群岛（MH）' },
    { value: 'MQ', label: '马提尼克（MQ）' },
    { value: 'MR', label: '毛里塔尼亚（MR）' },
    { value: 'MU', label: '毛里求斯（MU）' },
    { value: 'YT', label: '马约特（YT）' },
    { value: 'MX', label: '墨西哥（MX）' },
    { value: 'FM', label: '密克罗尼西亚联邦 (联邦)（FM）' },
    { value: 'MD', label: '摩尔多瓦共和国（MD）' },
    { value: 'MC', label: '摩纳哥（MC）' },
    { value: 'MN', label: '蒙古（MN）' },
    { value: 'ME', label: '黑山（ME）' },
    { value: 'MS', label: '蒙特塞拉特（MS）' },
    { value: 'MA', label: '摩洛哥（MA）' },
    { value: 'MZ', label: '莫桑比克（MZ）' },
    { value: 'MM', label: '缅甸（MM）' },
    { value: 'NA', label: '纳米比亚（NA）' },
    { value: 'NR', label: '瑙鲁（NR）' },
    { value: 'NP', label: '尼泊尔（NP）' },
    { value: 'NL', label: '荷兰（NL）' },
    { value: 'NC', label: '新喀里多尼亚（NC）' },
    { value: 'NZ', label: '新西兰（NZ）' },
    { value: 'NI', label: '尼加拉瓜（NI）' },
    { value: 'NE', label: '尼日尔（NE）' },
    { value: 'NG', label: '尼日利亚（NG）' },
    { value: 'NU', label: '纽埃（NU）' },
    { value: 'NF', label: '诺福克岛（NF）' },
    { value: 'KP', label: '朝鲜民主主义人民共和国（KP）' },
    { value: 'MP', label: '北马里亚纳群岛（MP）' },
    { value: 'NO', label: '挪威（NO）' },
    { value: 'OM', label: '阿曼（OM）' },
    { value: 'PK', label: '巴基斯坦（PK）' },
    { value: 'PW', label: '帕劳（PW）' },
    { value: 'PS', label: '巴勒斯坦国（PS）' },
    { value: 'PA', label: '巴拿马（PA）' },
    { value: 'PG', label: '巴布亚新几内亚（PG）' },
    { value: 'PY', label: '巴拉圭（PY）' },
    { value: 'PE', label: '秘鲁（PE）' },
    { value: 'PH', label: '菲律宾（PH）' },
    { value: 'PN', label: '皮特凯恩（PN）' },
    { value: 'PL', label: '波兰（PL）' },
    { value: 'PT', label: '葡萄牙（PT）' },
    { value: 'PR', label: '波多黎各（PR）' },
    { value: 'QA', label: '卡塔尔（QA）' },
    { value: 'RE', label: '留尼汪（RE）' },
    { value: 'RO', label: '罗马尼亚（RO）' },
    { value: 'RU', label: '俄罗斯联邦（RU）' },
    { value: 'RW', label: '卢旺达（RW）' },
    { value: 'BL', label: '圣巴泰勒米（BL）' },
    { value: 'KN', label: '圣基茨和尼维斯（KN）' },
    { value: 'LC', label: '圣卢西亚（LC）' },
    { value: 'MF', label: '法属圣马丁（MF）' },
    { value: 'VC', label: '圣文森特和格林纳丁斯（VC）' },
    { value: 'WS', label: '萨摩亚（WS）' },
    { value: 'SM', label: '圣马力诺（SM）' },
    { value: 'ST', label: '圣多美和普林西比（ST）' },
    { value: 'SA', label: '沙特阿拉伯（SA）' },
    { value: 'SN', label: '塞内加尔（SN）' },
    { value: 'RS', label: '塞尔维亚（RS）' },
    { value: 'SC', label: '塞舌尔（SC）' },
    { value: 'SL', label: '塞拉利昂（SL）' },
    { value: 'SG', label: '新加坡（SG）' },
    { value: 'SX', label: '荷属圣马丁（SX）' },
    { value: 'SK', label: '斯洛伐克（SK）' },
    { value: 'SI', label: '斯洛文尼亚（SI）' },
    { value: 'SB', label: '所罗门群岛（SB）' },
    { value: 'SO', label: '索马里（SO）' },
    { value: 'ZA', label: '南非（ZA）' },
    { value: 'GS', label: '南乔治亚岛与南桑威奇群岛（GS）' },
    { value: 'KR', label: '大韩民国（KR）' },
    { value: 'SS', label: '南苏丹（SS）' },
    { value: 'ES', label: '西班牙（ES）' },
    { value: 'LK', label: '斯里兰卡（LK）' },
    { value: 'SH', label: '圣赫勒拿（SH）' },
    { value: 'SD', label: '苏丹（SD）' },
    { value: 'SR', label: '苏里南（SR）' },
    { value: 'SJ', label: '斯瓦尔巴特和扬马延岛（SJ）' },
    { value: 'SZ', label: '斯威士兰（SZ）' },
    { value: 'SE', label: '瑞典（SE）' },
    { value: 'CH', label: '瑞士（CH）' },
    { value: 'SY', label: '阿拉伯叙利亚共和国（SY）' },
    { value: 'TJ', label: '塔吉克斯坦（TJ）' },
    { value: 'TZ', label: '坦桑尼亚联合共和国（TZ）' },
    { value: 'TH', label: '泰国（TH）' },
    { value: 'TG', label: '多哥（TG）' },
    { value: 'TK', label: '托克劳（TK）' },
    { value: 'TO', label: '汤加（TO）' },
    { value: 'TT', label: '特立尼达和多巴哥（TT）' },
    { value: 'TN', label: '突尼斯（TN）' },
    { value: 'TR', label: '土耳其（TR）' },
    { value: 'TM', label: '土库曼斯坦（TM）' },
    { value: 'TC', label: '特克斯和凯科斯群岛（TC）' },
    { value: 'TV', label: '图瓦卢（TV）' },
    { value: 'UG', label: '乌干达（UG）' },
    { value: 'UA', label: '乌克兰（UA）' },
    { value: 'AE', label: '阿拉伯联合酋长国（AE）' },
    { value: 'GB', label: '英国（GB）' },
    { value: 'US', label: '美利坚合众国（US）' },
    { value: 'UM', label: '美国本土外小岛屿（UM）' },
    { value: 'UY', label: '乌拉圭（UY）' },
    { value: 'UZ', label: '乌兹别克斯坦（UZ）' },
    { value: 'VU', label: '瓦努阿图（VU）' },
    { value: 'VA', label: '罗马教廷（VA）' },
    { value: 'VE', label: '委内瑞拉玻利瓦尔共和国（VE）' },
    { value: 'VN', label: '越南（VN）' },
    { value: 'VG', label: '英属维尔京群岛（VG）' },
    { value: 'VI', label: '美属维尔京群岛（VI）' },
    { value: 'WF', label: '瓦利斯群岛和富图纳群岛（WF）' },
    { value: 'EH', label: '西撒哈拉（EH）' },
    { value: 'YE', label: '也门（YE）' },
    { value: 'ZM', label: '赞比亚（ZM）' },
    { value: 'ZW', label: '津巴布韦（ZW）' },
];
