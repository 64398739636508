import React from 'react';
import { CnDetailInfo as UICnDetailInfo } from '@cainiaofe/cn-ui';
import { isArrayNotEmpty, isDesignMode, mergeHandleProps } from '@/common/util/util';
import './view.scss';
import isPlainObject from 'lodash/isPlainObject';

const CnDetailInfo = (props) => {
  const {
    _context,
    staticInfo,
    children,
    handleProps,
    getContext,
  } = props;

  let state = _context?.state;
  if (!state && typeof getContext === 'function') {
    state = getContext()?.state || {};
  }

  const description = staticInfo?.description;
  const realDescription = typeof description === 'function' ? description({}, state) :description;

  let componentProps = {
    ...staticInfo,
    description: realDescription,
  };
  if (isArrayNotEmpty(staticInfo?.tagText)) {
    componentProps.tagText = staticInfo?.tagText?.map((item) => item?.name);
  }

  const temp = mergeHandleProps({
    handleProps,
    componentProps,
    _context,
  });
  if (isPlainObject(temp)) {
    componentProps = temp;
  }
  componentProps.rightSlot = children;

  return <UICnDetailInfo {...componentProps} />;
};
CnDetailInfo.displayName = 'CnDetailInfo';
export default CnDetailInfo;

export { CnDetailInfo };
