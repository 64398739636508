import React from 'react';
import { Checkbox, CnButton, CnInput, CnMessage } from '@cainiaofe/cn-ui';

export default class UploadEnhanceSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempBucketName: '',
    };
  }

  getRequestConfigTemplate = (bucketName, apiName) => {
    return {
      url: `/api/tars2/file/upload${bucketName}/${apiName}`,
      contentType: 'application/json;charset=UTF-8',
      serviceType: 'http',
      method: 'get',
    };
  };

  createUrl = () => {
    const { tempBucketName } = this.state;
    const { prop } = this.props;
    const options = prop?.parent?.getValue?.();
    const { webOfficeEnable } = options || {};
    let bucketAddon = '';
    if (tempBucketName) {
      bucketAddon = '/' + tempBucketName;
    }
    prop?.parent?.setPropValue?.(
      'requestConfig',
      this.getRequestConfigTemplate(bucketAddon, 'getUploadFileScrip'),
    );
    if (webOfficeEnable === true) {
      prop?.parent?.setPropValue?.(
        'webOfficeEditRequestConfig',
        this.getRequestConfigTemplate(bucketAddon, 'generateEditUrl'),
      );
      prop?.parent?.setPropValue?.(
        'webOfficePreviewRequestConfig',
        this.getRequestConfigTemplate(bucketAddon, 'generatePreviewUrl'),
      );
    }
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <CnMessage type='notice'>
        <div style={{ lineHeight: 1.6 }}>
          如果您已经接入{' '}
          <a
            target={'_blank'}
            href='https://aliyuque.antfin.com/ei5p3z/heng1f/mb0q9p'
          >
            中台OSS jar包
          </a>
          ，您可以填写
          <CnInput
            value={this.state?.tempBucketName}
            style={{ width: '100px' }}
            onChange={(v) => this.setState({ tempBucketName: v })}
            placeholder={'bucketName（可不填）'}
            size={'small'}
          />{' '}
          <CnButton onClick={this.createUrl} size={'small'}>
            一键生成请求URL
          </CnButton>
        </div>
      </CnMessage>
    );
  }
}
