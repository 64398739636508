import { DisplayPosition } from './position/display-position';
import { ButtonPosition } from './position/button-position';
import { __record__, condition, handleOriginList, isArrayNotEmpty, isRecursionComponent, getJSExpressionPrototype2, } from '../util/util';
import React from 'react';
import { __arrayTableCurrentRow__, __dataSource__, __extraParam__, __flowData__, __permission_point_list__, __permissionGranted__, __tableCurrentRow__, button_view, currentFormCardLabel, currentFormStepLabel, currentPageLabel, formDialogStateLabel, formStateLabel, getFormExprNameByPosition, leftTreeAfterDropDataSourceLabel, leftTreeStateLabel, openDialogModeLabel, pageSizeLabel, } from '@/common/util/expr-const';
import ExprSetter from '@/common/setter/expr-setter';
import { getItem } from '@/common/manager/button';
import { getFormStateSetterSnippet, getJSXTemplate, } from '@/common/manager/setter-snippet';
import { ParamSelectSetter } from '@/common/setter/param-select-setter';
import DocSetter from '@/common/setter/doc-setter';
import DocLinkSetter from '@/common/setter/doc-link-setter';
const type = {
    name: 'type',
    title: '按钮样式',
    // supportVariable: true,
    display: 'inline',
    initialValue: 'normal',
    position: [
        ButtonPosition.dialog,
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.tableOperate,
        ButtonPosition.tableBatchArea,
        ButtonPosition.tableToolArea,
        ButtonPosition.cardAction,
        ButtonPosition.subCardAction,
        ButtonPosition.result,
        ButtonPosition.step,
        ButtonPosition.pageRightButton,
        ButtonPosition.listItemRightButton,
        ButtonPosition.subCardActionNotInForm,
        ButtonPosition.filterExtendButton,
        ButtonPosition.blockButton,
    ],
    setter: {
        componentName: 'SelectSetter',
        props: {
            options: [
                {
                    title: '普通按钮',
                    value: 'normal',
                },
                {
                    title: '主按钮',
                    value: 'primary',
                },
                {
                    title: '次按钮',
                    value: 'secondary',
                },
            ],
        },
    },
};
const text = {
    name: 'text',
    title: '是否文本按钮',
    // supportVariable: true,
    display: 'inline',
    position: [
        ButtonPosition.listItemRightButton,
        ButtonPosition.tileRightButton,
        ButtonPosition.statisticCardRightButton,
        ButtonPosition.subCardActionNotInForm,
        ButtonPosition.blockButton,
    ],
    setter: {
        componentName: 'BoolSetter',
    },
};
const size = {
    name: 'size',
    title: '按钮尺寸',
    display: 'inline',
    position: [ButtonPosition.listItemRightButton],
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: '大', value: 'large' },
                { title: '中', value: 'medium' },
                { title: '小', value: 'small' },
            ],
        },
    },
};
const btnPosition = {
    name: 'btnPosition',
    title: '按钮位置',
    display: 'inline',
    position: [ButtonPosition.form, ButtonPosition.blockButton],
    setter: {
        componentName: 'CnSelectSetter',
        props: {
            options: [
                {
                    title: '在底部按钮栏的左侧',
                    value: 'left',
                },
                {
                    title: '在底部按钮栏的右侧',
                    value: 'right',
                },
            ],
            selectProps: {
                hasClear: true,
                placeholder: '默认在底部按钮栏的右侧',
            },
        },
    },
};
const tableOperateHidden = {
    name: 'hidden',
    title: '隐藏',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'columns'}
                    //   labelKey={'title'}
                    //   valueKey={'dataIndex'}
                    //   groupName={'当前行数据'}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'columns',
                                labelKey: 'title',
                                valueKey: 'dataIndex',
                                groupName: '当前行数据',
                                groupExprName: __tableCurrentRow__,
                                needSecondParam: false,
                            },
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'table' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.tableOperate],
};
const tableOperateDisabled = {
    name: 'disabled',
    title: '禁用',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'columns'}
                    //   labelKey={'title'}
                    //   valueKey={'dataIndex'}
                    //   groupName={'当前行数据'}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'columns',
                                labelKey: 'title',
                                valueKey: 'dataIndex',
                                groupName: '当前行数据',
                                groupExprName: __tableCurrentRow__,
                                needSecondParam: false,
                            },
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'table' }),
            ],
        },
    },
    position: [ButtonPosition.tableOperate],
};
const arrayTableOperateHidden = (position) => {
    return {
        name: 'hidden',
        title: '隐藏',
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    dataKey: 'arrayTable',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: '表格当前行数据',
                                    groupExprName: __arrayTableCurrentRow__,
                                    needSecondParam: false,
                                },
                                {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: '表单字段',
                                    groupExprName: getFormExprNameByPosition({ position }),
                                    needSecondParam: false,
                                },
                                {
                                    groupName: '其他数据',
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: '简单表达式',
                    },
                    getJSExpressionPrototype({ type: 'arrayTableOperate' }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.arrayTableOperate,
            ButtonPosition.arraySubAreaCardOperate,
        ],
    };
};
const arrayTableOperateDisabled = (position) => {
    return {
        name: 'disabled',
        title: '禁用',
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    dataKey: 'arrayTable',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: '表格当前行数据',
                                    groupExprName: __arrayTableCurrentRow__,
                                    needSecondParam: false,
                                },
                                {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: '表单字段',
                                    groupExprName: getFormExprNameByPosition({ position }),
                                    needSecondParam: false,
                                },
                                {
                                    groupName: '其他数据',
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: '简单表达式',
                    },
                    getJSExpressionPrototype({ type: 'arrayTableOperate' }),
                ],
            },
        },
        position: [
            ButtonPosition.arrayTableOperate,
            ButtonPosition.arraySubAreaCardOperate,
        ],
    };
};
const span = {
    name: 'span',
    display: 'inline',
    title: '网格数',
    // setter: <NumberSetter min={1} max={2} />,
    position: [DisplayPosition.filter],
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                {
                    title: '1格（窄）',
                    value: 1,
                },
                {
                    title: '2格（宽）',
                    value: 2,
                },
            ],
        },
    },
};
const required = {
    name: 'required',
    position: [],
    display: 'inline',
    title: '必填',
    // supportVariable: true,
    setter: 'BoolSetter',
};
const requiredMessage = {
    position: [],
    name: 'requiredMessage',
    display: 'inline',
    title: '必填提示',
    tip: '未填写时报的错误提示文案',
    // supportVariable: true,
    setter: 'CnI18nSetter',
};
const hidden = {
    name: 'hidden',
    title: '隐藏',
    display: 'inline',
    // position: [DisplayPosition.form, DisplayPosition.filter,DisplayPosition.formDialog],
    // supportVariable: true,
    setter: 'BoolSetter',
};
const placeholder = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'placeholder',
    title: '空提示信息',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'CnI18nSetter',
                    title: '多语言设置器',
                },
                getJSExpressionPrototype({ type: 'formRequest' }),
            ],
        },
    },
};
const tip = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'tip',
    title: '标签提示信息',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    title: '文本',
                    componentName: 'CnI18nSetter',
                },
                getJSXTemplate({
                    position: ButtonPosition.form,
                }),
            ],
        },
    },
};
const extra = () => {
    const linkGetPrototypeList = getItem(ButtonPosition.form, 'link', 'getPrototypeList');
    return {
        position: [DisplayPosition.form, DisplayPosition.formDialog],
        name: 'extra',
        title: '底部额外提示信息',
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        title: '文本',
                        condition() {
                            return true;
                        },
                        componentName: 'CnI18nSetter',
                        props: {},
                    },
                    {
                        title: '超链接',
                        componentName: 'ObjectSetter',
                        props: {
                            config: {
                                items: [
                                    {
                                        name: 'text',
                                        title: '链接文案',
                                        display: 'inline',
                                        setter: {
                                            componentName: 'MixedSetter',
                                            props: {
                                                setters: [
                                                    {
                                                        componentName: 'CnI18nSetter',
                                                        title: '文本',
                                                    },
                                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                                ],
                                            },
                                        },
                                    },
                                    ...linkGetPrototypeList(ButtonPosition.form),
                                ],
                            },
                        },
                    },
                    getJSXTemplate({
                        position: ButtonPosition.form,
                    }),
                ],
            },
        },
    };
};
// const disabled = {
//   name: 'disabled',
//   title: '禁用',
//   display: 'inline',
//   // supportVariable: true,
//   // setter: {
//   //   componentName: "MixedSetter",
//   //   props: {
//   //     setters: ["BoolSetter"],
//   //   },
//   // },
//   setter: 'BoolSetter',
//   position: [DisplayPosition.formDialog],
// };
const filterItemHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表单字段',
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: '其他数据',
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表格当前行数据',
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'hidden',
        title: '隐藏',
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: '简单表达式',
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const arrayTableColumnHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表单字段',
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: '其他数据',
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    const jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    return {
        name: 'arrayTableColumnHidden',
        title: '整列隐藏',
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        componentName: <ExprSetter configList={configList}/>,
                        title: '简单表达式',
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [DisplayPosition.cnArrayTable],
    };
};
const filterItemDisabled = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表单字段',
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: '其他数据',
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表格当前行数据',
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'disabled',
        title: '禁用',
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: '简单表达式',
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const filterItemReadOnly = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表单字段',
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: '其他数据',
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表格当前行数据',
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'readOnly',
        title: '只读',
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'RadioGroupSetter',
                        props: {
                            options: [
                                {
                                    title: '是',
                                    value: true,
                                },
                                {
                                    title: '否',
                                    value: false,
                                },
                                {
                                    title: '不设置',
                                },
                            ],
                        },
                        title: '布尔（true/false）',
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: '简单表达式',
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const filterItemNotSubmitWhenHidden = (position) => {
    return {
        name: 'notSubmitWhenHidden',
        title: '隐藏时数据不提交',
        display: 'inline',
        // supportVariable: true,
        // className: 'cn-text-expr-setter',
        // setter: {
        //   componentName: 'MixedSetter',
        //   props: {
        //     setters: [
        //       {
        //         componentName: 'RadioGroupSetter',
        //         props: {
        //           options: [
        //             { title: '是', value: 'none' },
        //             { title: '否', value: '' },
        //             { title: '不设置' },
        //           ],
        //         },
        //       },
        //     ],
        //   },
        // },
        setter: 'BoolSetter',
        condition(prop) {
            return prop?.parent?.getPropValue?.('hidden') !== undefined;
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
        ],
    };
};
const hasClear = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'hasClear',
    title: '允许清空',
    display: 'inline',
    // supportVariable: true,
    tip: '设计规范规定，不建议修改',
    setter: 'BoolSetter',
};
// const readOnly = {
//   position: [DisplayPosition.form, DisplayPosition.formDialog,DisplayPosition.cnArrayTable],
//   name: 'readOnly',
//   title: '只读',
//   display: 'inline',
//   // supportVariable: true,
//   tip: '',
//   className: 'cn-text-expr-setter',
//   setter: {
//     componentName: 'MixedSetter',
//     props: {
//       setters: [
//         {
//           // componentName: 'BoolSetter',
//           // title: '布尔（true/false）',
//           title: '是 或 否',
//           componentName: 'RadioGroupSetter',
//           props: {
//             options: [
//               { value: true, title: '是' },
//               { value: false, title: '否' },
//               { title: '不设置' },
//             ],
//           },
//         },
//         {
//           // componentName: <TextExprSetter
//           //   dataKey={'config'}
//           //   labelKey={'label'}
//           //   valueKey={'name'}
//           //   groupName={'表单字段'}
//           // />,
//           componentName: <ExprSetter
//             configList={[
//               {
//                 dataKey:'config',
//                 labelKey:'label',
//                 valueKey:'name',
//                 groupName:'表单字段',
//                 groupExprName: getFormExprNameByPosition({}),
//                 needSecondParam:false,
//               },
//               {
//                 groupName:'其他数据',
//                 groupExprName: __dataSource__,
//                 needSecondParam:true,
//               }
//             ]}
//           />,
//           title: '简单表达式',
//         },
//         getJSExpressionPrototype({ type: 'form' }),
//       ],
//     },
//   },
// };
const tableToolbarHidden = {
    name: 'hidden',
    title: '隐藏',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'aa'}
                    //   labelKey={'aa'}
                    //   valueKey={'aa'}
                    //   groupName={' '}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'form' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.tableToolArea, ButtonPosition.tableBatchArea],
};
const tableToolbarDisabled = {
    name: 'disabled',
    title: '禁用',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'aa'}
                    //   labelKey={'aa'}
                    //   valueKey={'aa'}
                    //   groupName={' '}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'tableBatchArea' }),
            ],
        },
    },
    position: [ButtonPosition.tableToolArea, ButtonPosition.tableBatchArea],
};
const formButtonHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: '表单字段',
            groupExprName: getFormExprNameByPosition({}),
            needSecondParam: false,
        },
        {
            groupName: '其他数据',
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if ([ButtonPosition.form, ButtonPosition.formDialog].includes(position)) {
        const stateConfig = {
            title: formStateLabel,
            children: [
                {
                    label: currentFormStepLabel,
                    value: `${__extraParam__}.currentFormStep`,
                },
            ],
            extraConfig: {
                renderTypeDom: undefined,
                renderValueDom: undefined,
            },
        };
        configList.splice(1, 0, getFormStateSetterSnippet(stateConfig));
    }
    return {
        name: 'hidden',
        title: '隐藏',
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段列表'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: '简单表达式',
                    },
                    getJSExpressionPrototype({ type: 'form' }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.formDialog,
            ButtonPosition.form,
            ButtonPosition.cardAction,
            ButtonPosition.subCardAction,
        ],
    };
};
const formButtonDisabled = {
    name: 'disabled',
    title: '禁用',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'config'}
                    //   labelKey={'label'}
                    //   valueKey={'name'}
                    //   groupName={'表单字段列表'}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'config',
                                labelKey: 'label',
                                valueKey: 'name',
                                groupName: '表单字段',
                                groupExprName: getFormExprNameByPosition({}),
                                needSecondParam: false,
                            },
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'form' }),
            ],
        },
    },
    position: [
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.cardAction,
        ButtonPosition.subCardAction,
    ],
};
const dialogButtonHidden = (position) => {
    return {
        name: 'hidden',
        title: '隐藏',
        display: 'inline',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: '布尔（true/false）',
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    groupName: '其他数据',
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: '简单表达式',
                    },
                    getJSExpressionPrototype({ type: 'base' }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.dialog,
            ButtonPosition.treeNode,
            ButtonPosition.blockButton,
        ],
    };
};
const dialogButtonDisabled = {
    name: 'disabled',
    title: '禁用',
    display: 'inline',
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'base' }),
            ],
        },
    },
    position: [
        ButtonPosition.dialog,
        ButtonPosition.treeNode,
        ButtonPosition.blockButton,
    ],
};
const stepButtonHidden = {
    name: 'hidden',
    title: '隐藏',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'step' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.step],
};
const stepButtonDisabled = {
    name: 'disabled',
    title: '禁用',
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: '布尔（true/false）',
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: '其他数据',
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: '简单表达式',
                },
                getJSExpressionPrototype({ type: 'step' }),
            ],
        },
    },
    position: [ButtonPosition.step],
};
const width = {
    name: 'width',
    title: '列宽度',
    display: 'inline',
    setter: 'NumberSetter',
    position: [DisplayPosition.cnArrayTable],
};
const iconType = {
    name: 'iconType',
    title: '按钮图标',
    display: 'inline',
    setter: 'CnIconSetter',
    position: [
        ButtonPosition.dialog,
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.tableBatchArea,
        ButtonPosition.tableToolArea,
        ButtonPosition.cardAction,
        ButtonPosition.filterExtendButton,
        ButtonPosition.blockButton,
    ],
};
const iconTypeHelp = {
    display: 'none',
    setter: (<DocSetter content={<div className=''>
          请到{' '}
          <a target={'_blank'} href={'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'} rel='noreferrer'>
            图标库
          </a>{' '}
          中寻找想用的图标，填写图标type即可。
          <a target={'_blank'} href={'https://yuque.antfin.com/c-one/next/ld6nao#L77sK'} rel='noreferrer'>
            体验Demo
          </a>
        </div>}/>),
    position: [
        ButtonPosition.dialog,
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.tableBatchArea,
        ButtonPosition.tableToolArea,
        ButtonPosition.cardAction,
        ButtonPosition.filterExtendButton,
        ButtonPosition.blockButton,
    ],
};
const colSpan = {
    name: 'colSpan',
    title: '当前组件在一行占几列',
    display: 'inline',
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: '无', value: undefined },
                { title: '一', value: 1 },
                { title: '二', value: 2 },
                { title: '三', value: 3 },
                { title: '四', value: 4 },
                { title: '五', value: 5 },
                { title: '六', value: 6 },
            ],
        },
    },
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
};
const buttonHoverInfo = (position) => {
    const paramSelectSetter = getParamSetterPrototype({
        type: 'filter',
        prototypeConfig: {},
    });
    const jsExpressionSetter = getJSExpressionPrototype({
        type: [ButtonPosition.cardAction, ButtonPosition.subCardAction].includes(position)
            ? 'form'
            : 'tableBatchArea',
        prototypeConfig: {},
    });
    return {
        name: 'buttonHoverInfo',
        title: '气泡提示',
        display: 'plain',
        setter: {
            componentName: 'ObjectSetter',
            props: {
                config: {
                    items: [
                        {
                            name: 'needHover',
                            title: '气泡提示',
                            display: 'inline',
                            setter: {
                                componentName: 'BoolSetter',
                            },
                        },
                        {
                            name: 'hoverInfo',
                            display: 'plain',
                            title: '悬浮配置项',
                            setter: {
                                componentName: 'ObjectSetter',
                                props: {
                                    config: {
                                        items: [
                                            {
                                                name: 'title',
                                                title: '气泡提示标题',
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'MixedSetter',
                                                    props: {
                                                        setters: [
                                                            {
                                                                componentName: 'CnI18nSetter',
                                                                title: '字符串',
                                                            },
                                                            paramSelectSetter,
                                                            jsExpressionSetter ||
                                                                getJSExpressionPrototype({
                                                                    type: 'tableRequest',
                                                                }),
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'content',
                                                title: '气泡提示内容',
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'MixedSetter',
                                                    props: {
                                                        setters: [
                                                            {
                                                                componentName: 'CnI18nSetter',
                                                                title: '字符串',
                                                            },
                                                            paramSelectSetter,
                                                            jsExpressionSetter ||
                                                                getJSExpressionPrototype({
                                                                    type: 'tableRequest',
                                                                }),
                                                            getJSXTemplate({ position }),
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'align',
                                                title: '气泡位置',
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'SelectSetter',
                                                    props: {
                                                        options: [
                                                            {
                                                                label: '左',
                                                                value: 'l',
                                                            },
                                                            {
                                                                label: '上左',
                                                                value: 'tl',
                                                            },
                                                            {
                                                                label: '上',
                                                                value: 't',
                                                            },
                                                            {
                                                                label: '上右',
                                                                value: 'tr',
                                                            },
                                                            {
                                                                label: '右',
                                                                value: 'r',
                                                            },
                                                            {
                                                                label: '下右',
                                                                value: 'br',
                                                            },
                                                            {
                                                                label: '下',
                                                                value: 'b',
                                                            },
                                                            {
                                                                label: '下左',
                                                                value: 'bl',
                                                            },
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'handleProps',
                                                title: '手动设置气泡组件props',
                                                display: 'inline',
                                                setter: getJSExpressionPrototype({
                                                    type: 'props',
                                                }),
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },
        position: [
            ButtonPosition.tableBatchArea,
            ButtonPosition.tableToolArea,
            ButtonPosition.cardAction,
            ButtonPosition.subCardAction,
        ],
    };
};
const fixed = {
    name: 'fixed',
    title: '列固定',
    display: 'inline',
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: '不固定' },
                { value: 'left', title: '左侧' },
                { value: 'right', title: '右侧' },
            ],
        },
    },
    position: [DisplayPosition.cnArrayTable],
};
const allStyleMap = {
    width,
    type,
    text,
    size,
    btnPosition,
    tableOperateHidden,
    tableOperateDisabled,
    span,
    required,
    requiredMessage,
    hidden,
    placeholder,
    tip,
    extra,
    hasClear,
    // disabled,
    filterItemHidden,
    filterItemNotSubmitWhenHidden,
    arrayTableColumnHidden,
    filterItemDisabled,
    filterItemReadOnly,
    fixed,
    // readOnly,
    tableToolbarHidden,
    tableToolbarDisabled,
    formButtonHidden,
    formButtonDisabled,
    dialogButtonHidden,
    dialogButtonDisabled,
    arrayTableOperateHidden,
    arrayTableOperateDisabled,
    stepButtonDisabled,
    stepButtonHidden,
    buttonHoverInfo,
    iconType,
    iconTypeHelp,
    colSpan,
};
// 注册样式
export function registerStyle(plugin) {
    const { name } = plugin || {};
    if (name) {
        allStyleMap[name] = plugin;
    }
}
// 获取样式列表
export function getStyleListByPosition(position) {
    const defaultStyleList = Object.keys(allStyleMap);
    const result = [];
    if (position !== undefined) {
        defaultStyleList.forEach((name) => {
            let item = allStyleMap[name];
            if (typeof item === 'function') {
                item = item(position);
            }
            if (item && item.position && item.position.includes(position)) {
                result.push(item);
            }
        });
    }
    return result;
}
export function getJSExpressionPrototype(config) {
    return getJSExpressionPrototype2(config);
}
export function getPermissionListPrototype() {
    return {
        name: 'permissionHidden',
        componentName: 'CnSelectSetter',
        title: '权限控制',
        props(prop) {
            const permissionList = window.getCnLowcodeStoreItem?.(__permission_point_list__) || [];
            return {
                selectProps: {
                    hasClear: true,
                    showSearch: true,
                    menuProps: {
                        header: (<DocLinkSetter link={'https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?iframeQuery=anchorId%3Dlc5uji34n8fs2ylqgaa'} content={'点击查看权限点控制的使用姿势'}/>),
                    },
                    onChange: (v) => {
                        if (!v) {
                            prop?.parent?.setPropValue?.('hidden', undefined);
                            return;
                        }
                        prop?.parent?.setPropValue?.('hidden', {
                            group: __dataSource__,
                            param: `valueOf.${__permissionGranted__}`,
                            secondParam: `${v}`,
                            thirdParam: button_view,
                            symbol: '!=',
                            type: 'boolean',
                            value: true,
                        });
                    },
                    value: prop?.parent?.getPropValue?.('hidden')?.secondParam,
                },
                options: permissionList.filter((i) => i.type === button_view),
            };
        },
    };
}
export function getParamSetterPrototype(config) {
    const { type, prototypeConfig } = config || {};
    const { flowList, currentFlowIndex, additionalPosition } = prototypeConfig || {};
    if (type === 'formDialog') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: '当前表单',
                    },
                    {
                        groupName: formDialogStateLabel,
                        handleCustomGroup: (extra) => {
                            if (Array.isArray(extra?.children)) {
                                extra.children.push({
                                    label: openDialogModeLabel,
                                    value: `${__extraParam__}.openDialogMode`,
                                });
                            }
                        },
                    },
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'form') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'config',
            //   labelKey: 'label',
            //   valueKey: 'name',
            //   groupName: '当前表单',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: '当前表单',
                    },
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'table') {
        const configList = [
            {
                dataKey: 'columns',
                labelKey: 'title',
                valueKey: 'dataIndex',
                groupName: '当前行数据',
                // groupExprName: __tableCurrentRow__,
                needSecondParam: false,
            },
            {
                groupName: '动作编排的数据',
                groupExprName: __flowData__,
                needSecondParam: true,
                flowList,
                currentFlowIndex,
            },
            {
                groupName: '其他数据',
                groupExprName: __dataSource__,
                needSecondParam: true,
            },
        ];
        if (additionalPosition === ButtonPosition.childTableOperate) {
            configList[0] = {
                groupName: '当前行数据',
                groupExprName: __record__,
                handleCustomGroup: (extra, field) => {
                    if (Array.isArray(extra?.children) && field) {
                        const originList = field
                            ?.getNode?.()
                            ?.getPropValue?.('childTable')?.columns;
                        if (isArrayNotEmpty(configList)) {
                            extra.children = handleOriginList(originList, 'title', 'dataIndex', __record__);
                        }
                    }
                },
            };
        }
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'columns',
            //   labelKey: 'title',
            //   valueKey: 'dataIndex',
            //   groupName: '当前行数据',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList,
            },
            title: '选择参数',
        };
    }
    else if (type === 'filter') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                dataKey: 'config',
                labelKey: 'label',
                valueKey: 'name',
                groupName: '筛选栏',
                relativeDataSourceName: '_bindFilterDataSourceName',
                relativeDataSourceNameKey: '_dataSourceName',
            },
            // props: {
            //   configList:[
            //     {
            //       dataKey: 'config',
            //       labelKey: 'label',
            //       valueKey: 'name',
            //       groupName: '筛选栏',
            //       relativeDataSourceName: '_bindFilterDataSourceName',
            //       relativeDataSourceNameKey: '_dataSourceName',
            //     },
            //     {
            //       groupName:'动作编排的数据',
            //       groupExprName: __flowData__,
            //       needSecondParam:true,
            //       flowList,
            //       currentFlowIndex,
            //     },
            //     {
            //       groupName:'其他数据',
            //       groupExprName: __dataSource__,
            //       needSecondParam:true,
            //     },
            //   ]
            // },
            title: '选择参数',
        };
    }
    else if (type === 'arrayTable') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                dataKey: 'arrayTable',
                labelKey: 'label',
                valueKey: 'name',
                groupName: '当前行数据',
            },
            title: '选择参数',
        };
    }
    else if (type === 'arrayTableItemEvent') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'arrayTable',
            //   labelKey: 'label',
            //   valueKey: 'name',
            //   groupName: '当前行数据',
            // },
            props: {
                configList: [
                    {
                        groupName: '当前行数据',
                        groupExprName: __arrayTableCurrentRow__,
                        handleCustomGroup: (extra, field) => {
                            const path = field?.path;
                            if (Array.isArray(path) &&
                                path.length > 5 &&
                                path[0] === 'config' &&
                                path[2] === 'options' &&
                                (path[3] === 'config' || path[3] === 'buttons')) {
                                const arrayTableIndex = path[1];
                                if (typeof arrayTableIndex === 'number') {
                                    const formConfig = field
                                        ?.getNode?.()
                                        ?.getPropValue?.('config');
                                    const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                                    if (isRecursionComponent(arrayTableConfig?.componentName) &&
                                        arrayTableConfig?.options?.config?.length > 0) {
                                        const originList = arrayTableConfig?.options?.config;
                                        const tempList = handleOriginList(originList, 'label', 'name', `${__extraParam__}.${__arrayTableCurrentRow__}`);
                                        if (isArrayNotEmpty(tempList)) {
                                            extra.children = tempList;
                                        }
                                    }
                                }
                            }
                        },
                    },
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'base') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'aa',
            //   labelKey: 'aa',
            //   valueKey: 'aa',
            //   groupName: '参数列表',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'filterWithPagination') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: '筛选栏',
                        relativeDataSourceName: '_bindFilterDataSourceName',
                        relativeDataSourceNameKey: '_dataSourceName',
                    },
                    {
                        groupName: '当前表格的的分页信息',
                        groupExprName: __extraParam__,
                        handleCustomGroup: (extra) => {
                            if (Array.isArray(extra?.children)) {
                                extra.children.push({
                                    label: currentPageLabel,
                                    value: `${__extraParam__}.currentPage`,
                                });
                                extra.children.push({
                                    label: pageSizeLabel,
                                    value: `${__extraParam__}.pageSize`,
                                });
                            }
                        },
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'tree') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        groupName: leftTreeStateLabel,
                        handleCustomGroup: (extra) => {
                            if (Array.isArray(extra?.children)) {
                                extra.children.push({
                                    label: leftTreeAfterDropDataSourceLabel,
                                    value: `${__extraParam__}.afterDropDataSource`,
                                });
                            }
                        },
                    },
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
    else if (type === 'arrayCardSubCardButton') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                configList: [
                    {
                        groupName: '当前卡片数据',
                        groupExprName: __arrayTableCurrentRow__,
                        handleCustomGroup: (extra, field) => {
                            const path = field?.path;
                            if (Array.isArray(path) &&
                                path.length > 5 &&
                                path[0] === 'config' &&
                                path[2] === 'options' &&
                                (path[3] === 'config' || path[3] === 'buttons')) {
                                const arrayTableIndex = path[1];
                                if (typeof arrayTableIndex === 'number') {
                                    const formConfig = field
                                        ?.getNode?.()
                                        ?.getPropValue?.('config');
                                    const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                                    if (isRecursionComponent(arrayTableConfig?.componentName) &&
                                        arrayTableConfig?.options?.config?.length > 0) {
                                        const originList = arrayTableConfig?.options?.config;
                                        const tempList = handleOriginList(originList, 'label', 'name', `${__extraParam__}.${__arrayTableCurrentRow__}`);
                                        if (isArrayNotEmpty(tempList)) {
                                            extra.children = tempList;
                                        }
                                    }
                                }
                            }
                        },
                    },
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: '当前表单',
                    },
                    {
                        groupName: '动作编排的数据',
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: '其他数据',
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: '选择参数',
        };
    }
}
