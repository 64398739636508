import React from 'react';
import { CnButton } from '@cainiaofe/cn-ui';
import './index.scss';

export default class DocLinkSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content, link, ...rest } = this.props;
    return (
      <div className=''>
        <CnButton
          style={{ width: '100%' }}
          onClick={() => {
            if (link) {
              window.open(link);
            }
          }}
          {...rest}
        >
          {content || '点击跳转到文档地址'}
        </CnButton>
      </div>
    );
  }
}
