import { DisplayPosition } from '../position/display-position';
import { CnRangeNumberPicker, } from '@cainiaofe/cn-ui';
const RangeNumberPicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN015GYkNj1yLRqOOx5ag_!!6000000006562-2-tps-240-144.png',
    title: '范围数字输入框',
    componentName: 'RangeNumberPicker',
    component: CnRangeNumberPicker,
    formComponent: CnRangeNumberPicker,
    getPrototypeList: () => {
        return [
            {
                name: 'step',
                title: '步长',
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 1,
                    },
                },
            },
            {
                name: 'precision',
                title: '精度（小数点位数）',
                display: 'inline',
                setter: 'NumberSetter',
            },
            {
                name: 'max',
                title: '最大值',
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'min',
                title: '最小值',
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'innerAfter',
                title: '数字输入框后附加内容',
                display: 'inline',
                setter: 'StringSetter',
            },
        ];
    },
};
export default RangeNumberPicker;
