import { cnUtils } from '@cainiaofe/cn-ui';
export function emitEvent(eventName, ...args) {
    const events = cnUtils?.events;
    if (events && eventName) {
        events?.emit(eventName, ...args);
    }
}
export function onEvent(eventName, func) {
    const events = cnUtils?.events;
    if (events && eventName && typeof func === 'function') {
        events?.on(eventName, func);
    }
}
export const CnFilterOnSearch = 'L2_CnFilter_onSearch';
// 筛选栏数据改变时的回调
export const CnFilterOnChange = 'L2_CnFilter_onChange';
// cnPage的所有请求完成
export const CnPageRequestFinish = 'L2_CnPageRequestFinish';
// 数据源改变的事件
export const CnDataSourceChange = 'L2_CnDataSourceChange';
// 表格每次请求完成的事件
export const CnTableRequestFinish = 'L2_CnTableRequestFinish';
// cnPage的所有请求完成
export const CnPageForceUpdate = 'L2_CnPageForceUpdate';
// 筛选栏默认值准备完成的回调
export const CnFilterDefaultValueFinished = 'L2_CnFilterDefaultValueFinished';
