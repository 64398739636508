import React from 'react';
import { Slider as UICnSlider } from '@cainiaofe/cn-ui';
import { isDesignMode, mergeHandleProps } from '@/common/util/util';
import { useData } from '@/common/hook/useData';
import './index.scss';
import isPlainObject from 'lodash/isPlainObject';

const CnSlider = (props) => {
  const {
    title = '',
    _context,
    subTitle,
    type,
    buttons,
    handleProps,
    dataFrom,
    events,
    normalStyle,
    ...rest
  } = props;
  const isDesign = isDesignMode(props);
  const { height } = normalStyle || {};

  const { data = [] } = useData({
    dataFrom,
    _context,
    isDesign,
    designMockData: [
      {
        url: 'https://img.alicdn.com/imgextra/i2/O1CN01NnuVGH29BnDaaAZQG_!!6000000008030-0-tps-2960-1184.jpg',
      },
    ],
  });

  let realComponentProps = {};
  const temp = mergeHandleProps({
    handleProps,
    componentProps: realComponentProps,
    _context,
  });
  if (isPlainObject(temp)) {
    realComponentProps = temp;
  }

  return (
    <UICnSlider {...realComponentProps}>
      {data.map((item, index) => {
        const { label, value, url } = item || {};
        return (
          <div
            alt={label || ''}
            className={'l2-cn-slider-item-wrap'}
            style={{
              height: `${height || 200}px`,
              backgroundImage: `url(${url || value})`,
            }}
            key={index}
          />
        );
      })}
    </UICnSlider>
  );
};
CnSlider.displayName = 'CnSlider';
export default CnSlider;

export { CnSlider };
