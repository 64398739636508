import React from 'react';
import { calculateTextExprValue, condition, getBizComponent, getBizComponentNameList, getRunTimeBizComponent, isArrayNotEmpty, } from '../util/util';
import { DisplayPosition } from './position/display-position';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import isPlainObject from 'lodash/isPlainObject';
import './cell.scss';
import { CnTooltip } from '@cainiaofe/cn-ui';
import uniq from 'lodash/uniq';
import { __dataSource__, getCommonBoolDataSource, } from '@/common/util/expr-const';
import { dataOriginRequest, dataOriginStatic, } from '@/common/util/const';
import { transRequestConfigToRemote } from '@/common/util/request';
const { ActionSetter } = window.VisualEngineUtils || {};
function renderEnumOne(enumMap, value) {
    if (value !== undefined) {
        const label = enumMap[value];
        if (label !== undefined) {
            return label;
        }
    }
    else {
        return '';
    }
    return value;
}
function renderUserOne(value) {
    if (isPlainObject(value)) {
        let nameDom;
        let nickNameDom;
        let workNoDom;
        const { name, nickName, workNo } = value;
        if (name) {
            nameDom = name;
        }
        if (nickName) {
            nickNameDom = `(${nickName})`;
        }
        if (workNo) {
            workNoDom = workNo;
        }
        const dom = (<div className='table-format-user-content'>
        <img src={`https://work.alibaba-inc.com/photo/${workNo}.25x25.jpg`} className={'table-format-user-avatar'}/>
        {nameDom}
        {nickNameDom}
      </div>);
        if (workNoDom) {
            return (<CnTooltip align={'t'} trigger={dom}>
          {workNoDom}
        </CnTooltip>);
        }
        else {
            return dom;
        }
    }
}
const presetTagMap = {
    success: 'green',
    normal: 'blue',
    warning: 'orange',
    error: 'red',
};
const tag = {
    title: '标签',
    position: DisplayPosition.tableCell,
    componentName: 'tag',
    // component: (props) => {
    //   const { cellConfig, value, record, index } = props;
    //   let temp = value;
    //   function renderOne(item) {
    //     const itemProps = {
    //       type: 'normal',
    //       color: 'blue',
    //     };
    //     let children = item;
    //     if (is.object(item)) {
    //       const { text, type } = item;
    //       itemProps.color = presetTagMap[type] || 'blue';
    //       children = text;
    //     }
    //     return <Tag {...itemProps}>{children}</Tag>;
    //   }
    //   if (!is.array(temp)) {
    //     temp = [value];
    //   }
    //   return temp.map((item) => {
    //     return renderOne(item);
    //   });
    // },
};
const enumeration = {
    componentName: 'enumeration',
    title: '枚举',
    position: DisplayPosition.tableCell,
    beforeHandler: (props, config) => {
        const { _context } = config || {};
        const { format, options } = props || {};
        if (format === 'enumeration') {
            const { enumDataOrigin, enumRequestConfig, enumDataSource } = options || {};
            if (enumDataOrigin === dataOriginRequest && enumRequestConfig) {
                props.options = {
                    requestConfig: transRequestConfigToRemote({
                        requestConfig: enumRequestConfig,
                        state: _context?.state,
                        needFormatResult: true,
                    }),
                };
            }
            else if (enumDataOrigin === dataOriginStatic) {
                const realDataSource = calculateTextExprValue(enumDataSource, {
                    state: _context?.state,
                    recordDataSource: _context?.state,
                });
                if (isArrayNotEmpty(realDataSource)) {
                    props.options = {
                        dataSource: realDataSource,
                    };
                }
            }
        }
    },
    component: (props) => {
        let result;
        const { value, enumMap = {} } = props;
        if (Array.isArray(value)) {
            result = value
                .map((item) => renderEnumOne(enumMap, item))
                .filter((item) => item !== undefined && item !== '')
                .join(', ');
        }
        else {
            result = renderEnumOne(enumMap, value);
        }
        return <div style={{ display: 'flex', flexWrap: 'noWrap' }}>{result}</div>;
    },
    getDefaultProps: () => {
        return {
            enumDataOrigin: 'request',
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'enumDataOrigin',
                title: '数据来源',
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: '远程请求', value: 'request' },
                            { title: '静态数据', value: 'static' },
                        ],
                    },
                },
            },
            {
                name: 'enumRequestConfig',
                title: '查询服务',
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        mockDataTemplate: {
                            success: true,
                            data: [
                                {
                                    label: '选项一',
                                    value: 'first',
                                },
                                {
                                    label: '选项二',
                                    value: 'second',
                                },
                            ],
                        },
                        paramTitleDom: <div className=''>请求参数配置：</div>,
                        buttonText: '选择请求API',
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    {
                                        componentName: 'ParamSelectSetter',
                                        props: {
                                            dataKey: 'aa',
                                            labelKey: 'aa',
                                            valueKey: 'aa',
                                            groupName: 'aa',
                                        },
                                        title: '选择参数',
                                    },
                                    {
                                        componentName: 'StringSetter',
                                        title: '字符串',
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: `function(res) {
  // 需要返回的如下的数据结构
  // return {
  //   success: true,
  //   data: [
  //     {
  //        label:"xx",
  //        value:"xx",
  //     }
  //   ]
  // }
 return res;
}`,
                    },
                },
                condition(prop) {
                    return (condition(prop, 'enumeration', 'format') &&
                        prop?.parent?.getPropValue?.('enumDataOrigin') === 'request');
                },
            },
            {
                name: 'enumDataSource',
                title: '编辑静态数据',
                display: 'plain',
                tip: '编辑静态展示数据',
                // initialValue: [],
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'ArraySetter',
                                title: '设置静态数据',
                                props: {
                                    mode: 'list',
                                    itemSetter: {
                                        componentName: 'ObjectSetter',
                                        initialValue: {
                                            label: '名称',
                                            value: 'jack',
                                        },
                                        props: {
                                            config: {
                                                items: [
                                                    {
                                                        name: 'label',
                                                        display: 'inline',
                                                        title: '标签',
                                                        isRequired: true,
                                                        setter: 'CnI18nSetter',
                                                    },
                                                    {
                                                        name: 'value',
                                                        display: 'inline',
                                                        title: '值',
                                                        isRequired: true,
                                                        setter: {
                                                            componentName: 'MixedSetter',
                                                            props: {
                                                                setters: [
                                                                    {
                                                                        componentName: 'StringSetter',
                                                                        title: '字符串',
                                                                    },
                                                                    {
                                                                        componentName: 'NumberSetter',
                                                                        title: '数字',
                                                                    },
                                                                    {
                                                                        componentName: 'CnSelectSetter',
                                                                        props: {
                                                                            options: getCommonBoolDataSource(),
                                                                            selectProps: {
                                                                                hasClear: true,
                                                                            },
                                                                        },
                                                                        title: '布尔（true/false）',
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                componentName: 'ParamSelectSetter',
                                props: {
                                    configList: [
                                        {
                                            groupName: '其他数据',
                                            groupExprName: __dataSource__,
                                            needSecondParam: true,
                                        },
                                    ],
                                },
                                title: '从数据源选择',
                            },
                            {
                                componentName: (<ActionSetter defaultCode={`/**
 * getStaticEnumDataSource
 */
function getStaticEnumDataSource() {
  // 需要返回数组，格式如下
  return [
    {
      label: '选项一',
      value: 'first'
    }
  ];
}`} defaultActionName='getStaticEnumDataSource'/>),
                                title: 'JS代码编辑',
                            },
                            getJSExpressionPrototype({
                                type: 'select',
                                offlineTip: '动态控制数据推荐使用「JS代码编辑」设置该属性',
                            }),
                        ],
                    },
                },
                condition(prop) {
                    return (condition(prop, 'enumeration', 'format') &&
                        prop?.parent?.getPropValue?.('enumDataOrigin') === 'static');
                },
            },
        ];
    },
};
export const allCellMap = {
    text: {
        componentName: 'text',
        title: '文本',
        position: DisplayPosition.tableCell,
    },
    ellipsis: {
        componentName: 'ellipsis',
        title: '文本-溢出省略',
        position: DisplayPosition.tableCell,
    },
    number: {
        componentName: 'number',
        title: '数字',
        position: DisplayPosition.tableCell,
    },
    currency: {
        componentName: 'currency',
        title: '金额展示',
        position: DisplayPosition.tableCell,
    },
    'currency-with-unit': {
        componentName: 'currency-with-unit',
        title: '金额展示（带币种）',
        position: DisplayPosition.tableCell,
    },
    percent: {
        componentName: 'percent',
        title: '百分比',
        position: DisplayPosition.tableCell,
    },
    bank: {
        componentName: 'bank',
        title: '银行卡号',
        position: DisplayPosition.tableCell,
    },
    date: {
        componentName: 'date',
        title: '日期',
        position: DisplayPosition.tableCell,
    },
    'date-time': {
        componentName: 'date-time',
        title: '时间',
        position: DisplayPosition.tableCell,
    },
    tag,
    progress: {
        componentName: 'progress',
        title: '进度条',
        position: DisplayPosition.tableCell,
    },
    enumeration,
    user: {
        componentName: 'user',
        title: '人员',
        position: DisplayPosition.tableCell,
    },
    'multiple-text': {
        componentName: 'multiple-text',
        title: '多行文本',
        position: DisplayPosition.tableCell,
    },
    'image-text': {
        componentName: 'image-text',
        title: '图文',
        position: DisplayPosition.tableCell,
    },
    file: {
        componentName: 'file',
        title: '文件预览',
        position: DisplayPosition.tableCell,
    },
};
// 获取cell列表
export function getCellList() {
    const defaultCellList = Object.keys(allCellMap);
    const bizComponentNameList = getBizComponentNameList();
    const allCellList = uniq([...defaultCellList, ...bizComponentNameList]);
    const result = [];
    allCellList.forEach((name) => {
        const component = getCell(name);
        if (component) {
            const { bizInfo = [] } = component;
            if (bizInfo.length > 0) {
                bizInfo.forEach((item) => {
                    const { label, value } = item;
                    const existGroup = result.find((item2) => item2.value === value);
                    if (existGroup) {
                        existGroup?.children.push(component);
                    }
                    else {
                        result.push({
                            title: label,
                            value,
                            children: [component],
                        });
                    }
                });
                return;
            }
            result.push(component);
        }
    });
    return result;
}
export function getCell(format, propKey) {
    let result;
    if (format) {
        let item = allCellMap[format];
        if (!item) {
            item = getBizComponent(format, DisplayPosition.tableCell);
        }
        if (item) {
            if (!item.label) {
                item.label = item.title;
            }
            if (!item.value) {
                item.value = item.componentName;
            }
            if (propKey) {
                result = item[propKey];
            }
            else {
                result = item;
            }
        }
    }
    return result;
}
// 获取button的node
export function getRunTimeCell(format) {
    let result;
    if (format) {
        result = allCellMap[format];
        if (!result) {
            result = getRunTimeBizComponent(format);
        }
    }
    return result;
}
