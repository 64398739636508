// 打开弹窗的按钮信息
export const _buttonConfig = '_buttonConfig';
export const _buttonPosition = '_buttonPosition';
export const _context = '_context';
export const _state = '_state';
// 弹框实例
export const _dialogRef = '_dialogRef';
export const _bindFilterData = '_bindFilterData';
export const _getBindFilterData = '_getBindFilterData';
export const _getCurrentTableInfo = '_getCurrentTableInfo';
export const _selectedRowKeys = '_selectedRowKeys';
export const _selectedRowRecords = '_selectedRowRecords';
export const _tableRef = '_tableRef';
// 表格的请求结果
export const _tableResponse = '_tableResponse';
//表格cell属性
export const _columnInfo = '_columnInfo';
//弹窗表单相关
export const _getFormValues = '_getFormValues';
// 表单项相关
export const _getContext = '_getContext';
