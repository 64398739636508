import React from 'react';
import CnStepView from './step-view';

class CnStep extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  changeStep = (...arg) => {
    this.ref?.current?.changeStep?.(...arg);
  };

  load = (...arg) => {
    this.ref?.current?.load?.(...arg);
  };
  render() {
    return <CnStepView ref={this.ref} {...this.props} />;
  }
}
CnStep.displayName = 'CnStep';
export default CnStep;

export { CnStep };
