import React from 'react';
import { CnCard as UICnCard } from '@cainiaofe/cn-ui';

const CnCard = (props) => {
  const { title, titleTips, desc, children, foldable, titleTag } = props;
  return (
    <UICnCard
      title={title}
      titleTips={titleTips}
      desc={desc}
      foldable={foldable}
      titleTag={titleTag}
    >
      {children}
    </UICnCard>
  );
};
CnCard.displayName = 'CnCard';

export default CnCard;

export { CnCard };
