import React from 'react';

const Page = (props) => {
  const { children } = props;
  return <div>{children}</div>;
};
Page.displayName = 'Page';
export default Page;

export { Page };
