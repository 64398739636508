import { CnDepartmentSelect } from '@cainiaofe/cn-ui';
import { condition } from '../../util/util';
import { DisplayPosition } from '../position/display-position';

// function getInitialValue(initialValue){
//   if(this.parent.getParam('componentName').toData() !== 'EmployeeSelect'){
//     return initialValue
//   }
// }
const DepartmentSelect = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  title: '部门选择器',
  componentName: 'DepartmentSelect',
  component: CnDepartmentSelect,
  formComponent: CnDepartmentSelect,
  getDefaultProps: () => {
    return {
      multiple: false,
      showSearch: true,
    };
  },
  // select的prototype列表
  getPrototypeList: () => {
    return [
      // {
      //   title: '部门选择器配置',
      //   type:'group',
      //   display: 'accordion',
      //   collapsed:true,
      //   // hidden: hidden('DepartmentSelect'),
      //   condition(prop){
      //     return condition(prop,'DepartmentSelect','componentName')
      //   },
      // },
      {
        name: 'multiple',
        title: '选择模式',
        display: 'inline',
        // supportVariable: true,
        // hidden:hidden('DepartmentSelect'),
        condition(prop) {
          return condition(prop, 'DepartmentSelect', 'componentName');
        },
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '单选',
                value: false,
              },
              {
                title: '多选',
                value: true,
              },
            ],
          },
        },
      },
      {
        name: 'showSearch',
        title: '支持选择',
        // supportVariable: true,
        // hidden:hidden('DepartmentSelect'),
        condition(prop) {
          return false;
        },
        setter: 'BoolSetter',
      },
      {
        name: 'mokelay',
        display: 'inline',
        title: '是否中心化',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '是',
                value: true,
              },
              {
                title: '否',
                value: false,
              },
              {
                title: '不设置',
              },
            ],
          },
        },
      },
    ];
  },
};
export default DepartmentSelect;
