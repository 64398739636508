import CnAddressSelect from '@cainiaofe/cn-address';
import {
  calculateTextExprValue,
  condition,
  isEmptyButNotZero,
  isValueObjectExpr,
  makeFormItemSchema,
} from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { countryList } from '@/common/util/country-datasource';
import locale from '@cainiaofe/cn-address/lib/locale';
import $i18n from 'panda-i18n';
import DocSetter from '@/common/setter/doc-setter';
import isPlainObject from 'lodash/isPlainObject';
import set from 'lodash/set';

// function getInitialValue(initialValue){
//   if(this.parent.getParam('componentName').toData() !== 'EmployeeSelect'){
//     return initialValue
//   }
// }

const AddressSelect = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  thumbUrl:
    'https://img.alicdn.com/imgextra/i3/O1CN01i1EVG91ng5vLuqJ4l_!!6000000005118-2-tps-240-144.png',
  title: '地址选择器',
  componentName: 'AddressSelect',
  component: CnAddressSelect,
  formComponent: CnAddressSelect,
  getDefaultProps: () => {
    return {
      multiple: false,
      showTopLevel: false,
      maxLevel: 4,
      showSearch: true,
    };
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    const { componentProps } = config || {};
    if (isPlainObject(componentProps)) {
      const { extra } = componentProps;
      const { realm, needSkipLevelData, showDisabledDivision } = extra || {};
      if (realm) {
        set(componentProps, 'config.realm', realm);
      }
      if (needSkipLevelData) {
        set(componentProps, 'needSkipLevelData', needSkipLevelData);
      }
      if (showDisabledDivision) {
        set(componentProps, 'showDisabledDivision', showDisabledDivision);
      }
      delete componentProps.extra;
    }
  },
  formItemBeforeHandler: (formItem, config) => {
    if (formItem) {
      const componentProps = formItem['x-component-props'];
      if (isPlainObject(componentProps)) {
        const { extra = {} } = componentProps;
        if (isPlainObject(extra)) {
          for (let key in extra) {
            const temV = extra[key];
            if (key && !isEmptyButNotZero(temV)) {
              if (
                key === 'needSkipLevelData' ||
                key === 'showDisabledDivision' ||
                key === 'isSearchById'
              ) {
                set(componentProps, `${key}`, temV);
              } else {
                set(componentProps, `config.${key}`, temV);
              }
            }
          }
        }
        delete componentProps.extra;
      }
    }
  },
  getPrototypeList: () => {
    return [
      // {
      //   title: '员工选择器配置',
      //   type: 'group',
      //   display: 'accordion',
      //   collapsed: true,
      //   // hidden: hidden('AddressSelect'),
      //   condition(prop){
      //     return condition(prop,'AddressSelect','componentName')
      //   },
      // },
      {
        name: 'multiple',
        title: '选择模式',
        display: 'inline',
        // supportVariable: true,
        condition(prop) {
          return condition(prop, 'AddressSelect', 'componentName');
        },
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '单选',
                value: false,
              },
              {
                title: '多选',
                value: true,
              },
            ],
          },
        },
      },
      {
        name: 'showTopLevel',
        title: '选择国家',
        display: 'inline',
        condition(prop) {
          return condition(prop, 'AddressSelect', 'componentName');
        },
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'RadioGroupSetter',
                title: '是 或 否',
                props: {
                  options: [
                    {
                      title: '全部国家',
                      value: true,
                    },
                    {
                      title: '不支持',
                      value: false,
                    },
                  ],
                },
              },
              {
                componentName: 'CnSelectSetter',
                title: '选择部分国家',
                props: {
                  options: countryList,
                  selectProps: {
                    mode: 'multiple',
                    showSearch: true,
                  },
                },
              },
            ],
          },
        },
      },
      {
        name: 'maxLevel',
        title: '最多展示几级',
        display: 'inline',
        setter: {
          componentName: 'NumberSetter',
          props: {
            hasClear: true,
          },
        },
      },
      {
        name: 'useDetailValue',
        title: '使用详细数据 ',
        display: 'inline',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              { title: '是', value: true },
              { title: '否', value: false },
              { title: '不设置' },
            ],
          },
        },
      },
      {
        name: 'showSearch',
        title: '支持选择',
        condition(prop) {
          return false;
        },
        setter: 'BoolSetter',
      },
      {
        name: 'changeOnSelect',
        title: '支持选中非最后一级',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              { title: '是', value: true },
              { title: '否', value: false },
              { title: '不设置' },
            ],
          },
        },
      },
      {
        title: '高级配置',
        name: 'extra',
        display: 'accordion',
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                {
                  name: 'realm',
                  title: '数据版本',
                  setter: 'StringSetter',
                },
                {
                  display: 'none',
                  setter: (
                    <DocSetter
                      content={
                        <a target={'_blank'}>
                          正常您不需要配置该属性，当您对数据有定制要求时请联系星马
                        </a>
                      }
                    />
                  ),
                },
                {
                  name: 'needSkipLevelData',
                  title: '是否需要跳级数据',
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        { title: '是', value: true },
                        { title: '否', value: false },
                        { title: '不设置' },
                      ],
                    },
                  },
                },
                {
                  name: 'showDisabledDivision',
                  title: '地址数据库中,是否展示已删除数据',
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        { title: '是', value: true },
                        { title: '否', value: false },
                        { title: '不设置' },
                      ],
                    },
                  },
                },
                {
                  name: 'isSearchById',
                  title: '开启ID搜索',
                  display: 'inline',
                  setter: 'BoolSetter',
                },
                {
                  name: 'apiModel',
                  title: 'apiModel',
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        { title: 'mtop', value: 'mtop' },
                        { title: 'cdn', value: 'cdn' },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      },
    ];
  },
};

CnAddressSelect.filterBeforeHandler = (props) => {
  const newProps = {
    ...props,
  };
  const { showTopLevel } = newProps || {};
  if (!newProps.config) {
    newProps.config = {
      apiModel: 'mtop', // 'mtop'
      businessId: 'toffee',
    };
  }
  if (showTopLevel === true) {
    // newProps.maxLevel = 5
    newProps.iso = '';
  } else if (Array.isArray(showTopLevel) && showTopLevel.length > 0) {
    newProps.iso = '';
    newProps.includeList = showTopLevel;
  }
  delete newProps.showTopLevel;

  const lang = $i18n.getLang();
  if (lang) {
    const tempLocale = locale?.[lang];
    if (tempLocale) {
      newProps.locale = tempLocale;
    }

    newProps.dataLanguage = lang;
  }

  return newProps;
};
CnAddressSelect.formBeforeHandler = CnAddressSelect.filterBeforeHandler;
CnAddressSelect.defautlProps = {
  prefix: 'cn-next-',
};

export default AddressSelect;
