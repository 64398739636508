import View from './index';
import packageJson from '../../package.json';
import { makeRequest, transRequestConfigToRemote } from '@/common/util/request';
import { calculateTextExprValue, dialogOpen, dialogClose, tableLoad, } from '@/common/util/util';
import { getSelectDialogSnippet, getSelectTableSnippet, } from '@/common/manager/setter-snippet';
import './view.scss';
import { getCommonOpenUtil } from '@/common/util';
console.log(`%cCN_UI_LOWCODE %c${packageJson?.version}`, 'color:#000;font-weight:bold;', 'color:green;font-weight:bold;');
export default View;
/**
 * 通用的辅助方法，供 L2 业务组件使用，挂载在 window 上
 */
window.cnLowCodeUtils = {
    /**
     * 【运行时】发请求
     */
    makeRequest,
    /**
     * 【运行时】获取配置表达式的值
     */
    getExpressionValue: calculateTextExprValue,
    /**
     * 【运行时】将低码 API 配置转换成 cn-ui service 配置对象
     */
    transformRequestConfigToRemote: transRequestConfigToRemote,
    /**
     * 【运行时】表达式相关方法
     */
    expression: {
        /**
         * 【运行时】获取配置表达式的值
         */
        getExpressionValue: calculateTextExprValue,
        /**
         * 【运行时】将低码 API 配置转换成 cn-ui service 配置对象
         */
        transformRequestConfigToRemote: transRequestConfigToRemote,
    },
    /**
     * 【运行时】请求相关方法
     */
    request: {
        /**
         * 【运行时】发请求
         */
        makeRequest,
    },
    /**
     * 【运行时】弹窗相关方法
     */
    dialog: {
        /**
         * 【运行时】打开弹窗
         */
        open: dialogOpen,
        /**
         * 【运行时】关闭弹窗
         */
        close: dialogClose,
        /**
         * 【运行时】打开弹窗
         */
        getSelectDialogSnippet,
    },
    /**
     * 表格相关方法
     */
    table: {
        /**
         * 【运行时】打开弹窗
         */
        load: tableLoad,
        /**
         * 选择表格setter
         */
        getSelectTableSnippet,
    },
    ...getCommonOpenUtil(),
};
