import {
  getFormItemDefaultProps,
  getItem,
  getItemDefaultProps,
  getItemInitialValue,
  getItemListByPosition,
  getItemPrototypeListByPosition,
  getItemStylePrototype,
} from '@/common/manager/filter-item';
import { CnAsyncSelect, CnTooltip } from '@cainiaofe/cn-ui';
import { AddOneBtn } from './add-one';
import { createValidatorSetters } from '@/common/manager/validator';
import { createEventSetters } from '@/common/manager/event';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import {
  generateDataSource,
  getDsNameFromVariable,
  handleI18nLabel,
  isArrayNotEmpty,
} from '@/common/util/util';
import DataSourceSetter from '@/common/setter/datasource-setter/datasource-setter';
import { createRequestSuccessPrototype } from '@/common/util/request';
import DefaultValueSetter from '@/common/setter/default-value-setter';
import ExprSetter from '@/common/setter/expr-setter';
import {
  __dataSource__,
  __extraParam__,
  formDialogStateLabel,
  getOpenDialogModeEnum,
  openDialogModeLabel,
} from '@/common/util/expr-const';
import { DisplayPosition } from '@/common/manager/position/display-position';
import FormilyReactionSetter from '@/common/setter/formily-reaction-setter';
import { ButtonPosition } from '@/common/manager/position/button-position';
import {
  getCnSelectSetterSnippet,
  getColsSetterSnippet,
  getExprSetterSnippet,
  getLabelAlignSetterSnippet,
  getNodeIdSetterSnippet,
  getRequestExecuteSetter,
  getRightButtonSetterSnippet,
  getTableCellClickSetterSnippet,
} from '@/common/manager/setter-snippet';
import { ParamSelectSetter } from '@/common/setter/param-select-setter';
import BlockSetter from '@/common/setter/block-setter';
import { TableAddOneBtn } from '@/lowcode/cn-table-alc/component/table-add-one';
import { __advancedCodeConfig__ } from '@/common/util/const';
import DocSetter from '@/common/setter/doc-setter';

const { ActionSetter } = window.VisualEngineUtils || {};

const Engine = window.VisualEngine;

export function createFormItemSetters(config) {
  const { position } = config || {};
  const itemList = getItemListByPosition({ position });
  let configure = [
    {
      name: 'id',
      setter: 'StringSetter',
      condition() {
        return false;
      },
    },
    // {
    //   name: "primaryKey",
    //   title: "唯一标识",
    //   condition: {
    //     "type": "JSFunction",
    //     "value": "() => false"
    //   },
    //   initialValue: {
    //     "type": "JSFunction",
    //     "value": "val => {\n                        if (val) return val;\n                        return String(Math.floor(Math.random() * 10000));\n                      }"
    //   },
    //   setter: "StringSetter"
    // },
    {
      name: 'label',
      title: '字段名称',
      display: 'inline',
      editable: true,
      isRequired: true,
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              componentName: 'CnI18nSetter',
              title: '多语言设置器',
            },
            getJSExpressionPrototype({ type: 'form' }),
          ],
        },
      },
    },
    {
      name: 'name',
      title: '字段编码',
      display: 'inline',
      // supportVariable: true,
      // initialValue: function init(val) {
      //   if (val) return val;
      //   return uniqueId(null, 'dataIndex');
      // },
      editable: true,
      isRequired: true,
      setter: 'StringSetter',
    },
    {
      name: 'componentName',
      title: '组件类型',
      display: 'inline',
      extraProps: {
        setValue(target, value) {
          const defaultProps = getItemDefaultProps(position, value);
          if (defaultProps) {
            target?.parent?.setPropValue?.('options', defaultProps);
          } else {
            target?.parent?.setPropValue?.('options', {});
          }
          const formItemDefaultProps = getFormItemDefaultProps(position, value);
          if (formItemDefaultProps) {
            target?.parent?.setPropValue?.('placeholder', undefined);
            const keys = Object.keys(formItemDefaultProps);
            keys.forEach((key) => {
              target?.parent?.setPropValue?.(key, formItemDefaultProps[key]);
            });
          }
        },
      },
      setter: {
        componentName: 'CnComponentSetter',
        props: () => {
          return {
            dataSource: getItemListByPosition({ position }),
          };
        },
      },
    },
    {
      condition(prop) {
        return prop?.getValue?.().componentName === 'ReadOnly';
      },
      setter: (
        <DocSetter
          content={
            <a
              target={'_blank'}
              href={
                'https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?utm_scene=team_space&iframeQuery=anchorId%3Dlid1e2up49x38wps57r'
              }
              rel='noreferrer'
            >
              展示内容格式化文档链接
            </a>
          }
        />
      ),
    },
  ];

  configure.push({
    name: 'options',
    // display: 'accordion',
    display: 'block',
    title: '请完善组件的配置项',
    condition(prop) {
      const componentName = prop?.parent?.getPropValue?.('componentName');
      if (componentName) {
        const item = getItem(position, componentName) || {};
        const { getPrototypeList, configure = [] } = item;
        if (
          (getPrototypeList && getPrototypeList()?.length > 0) ||
          configure?.length > 0
        ) {
          return true;
        }
      }
      return false;
    },
    extraProps: {
      defaultCollapsed: false,
    },
    setter: {
      componentName: 'ObjectSetter',
      props: () => {
        return {
          config: {
            items: [...getItemPrototypeListByPosition({ position })],
          },
        };
      },
    },
  });
  if (position) {
    if ([DisplayPosition.cnArrayTable].includes(position)) {
      configure.push({
        name: 'readOnlyClick',
        display: 'accordion',
        title: '配置单元格的点击行为',
        extraProps: {
          defaultCollapsed: true,
        },
        setter: getTableCellClickSetterSnippet({
          buttonPosition: ButtonPosition.arrayTableCell,
        }),
      });
    }
    configure = [
      ...configure,
      ...createValidatorSetters({
        position: position.replace('display', 'validator'),
      }),
      ...getItemStylePrototype({ position }),
    ];

    if (
      [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
      ].includes(position)
    ) {
      configure.push(
        getRightButtonSetterSnippet({
          position: ButtonPosition.formItemRightButton,
        }),
      );
    }

    const eventSetters = createEventSetters({
      position: `${position.replace('display', 'btn')}ItemEvent`,
    });
    if (eventSetters?.length > 0) {
      configure = [...configure, ...eventSetters];
    }
    configure = [
      ...configure,
      {
        title: '高级配置（请谨慎使用）',
        name: __advancedCodeConfig__,
        display: 'accordion',
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                {
                  name: 'handleProps',
                  title: '手动设置组件props',
                  display: 'inline',
                  setter: getJSExpressionPrototype({
                    type: 'props',
                  }),
                },
              ],
            },
          },
        },
      },
    ];
    if ([DisplayPosition.form, DisplayPosition.formDialog].includes(position)) {
      configure.push({
        name: '_block',
        display: 'plain',
        setter: <BlockSetter />,
      });
    } else if ([DisplayPosition.filter].includes(position)) {
      configure.push({
        name: '_block',
        display: 'plain',
        setter: <BlockSetter subType={'FILTER'} />,
      });
    }
  }
  return {
    componentName: 'ArraySetter',
    props: {
      mode: 'list',
      extraProps: {
        renderFooter(props) {
          if (DisplayPosition.cnArrayTable === position) {
            return (
              <TableAddOneBtn
                handleFieldList={(originalList) => {
                  const newList = [];
                  if (isArrayNotEmpty(originalList)) {
                    originalList.forEach((item) => {
                      if (item?.dataIndex) {
                        newList.push({
                          name: item.dataIndex,
                          label: handleI18nLabel(item.title),
                          componentName: 'Input',
                        });
                      }
                    });
                  }
                  return newList;
                }}
                dataPrimaryKey={'name'}
                {...props}
              />
            );
          } else {
            return <AddOneBtn position={position} {...props} />;
          }
        },
      },
      itemSetter: {
        componentName: 'ObjectSetter',
        // initialValue: {
        //   "type": "JSFunction",
        //   "value": "() => {return {primaryKey: String(Math.floor(Math.random() * 10000)),children: \"Title\",optType:\"link\"               };}"
        // },
        initialValue(prop) {
          let toAddInitialValue = getItemInitialValue({
            position,
            prop,
            componentName: itemList[0]?.value,
          });
          const tempCurrentAddComponent =
            window.__lowcode_form_current_add_componentName__;
          if (tempCurrentAddComponent) {
            toAddInitialValue = {
              ...getFormItemDefaultProps(position, tempCurrentAddComponent),
              componentName: tempCurrentAddComponent,
            };
            window.__lowcode_form_current_add_componentName__ = undefined;

            const sel =
              window?.AliLowCodeEngine?.project?.currentDocument?.selection;
            sel?.select?.();
            const id = prop?.node?.id;
            if (id) {
              setTimeout(() => {
                sel?.select?.(id);
              });
            }
          }

          return toAddInitialValue;
        },

        //       if(this.initialData) {
        //   return this.initialData;
        // }
        // const existFields = this.props?.parent?.prop?.getValue() || [];
        // const defaultFieldName = '文本';
        // const newFieldIndex = generateIndex(existFields, defaultFieldName, 'label')
        // return `${defaultFieldName}${newFieldIndex}` || defaultFieldName
        props: {
          config: {
            items: configure,
          },
        },
      },
    },
  };
}

export function createFormStyleSetters(config) {
  const { position } = config || {};
  const readOnlyConfigList = [
    {
      groupName: '其他数据',
      groupExprName: __dataSource__,
      needSecondParam: true,
    },
  ];
  if (ButtonPosition.formDialog === position) {
    readOnlyConfigList.unshift({
      groupExprName: __extraParam__,
      handleCustomGroup: () => {
        return {
          label: formDialogStateLabel,
          children: [
            {
              label: openDialogModeLabel,
              value: `${__extraParam__}.openDialogMode`,
            },
          ],
        };
      },
      renderTypeDom: () => {
        return null;
      },
      renderValueDom: (props) => {
        const { value, onChange } = props || {};
        return (
          <CnAsyncSelect
            size={'small'}
            hasClear
            value={value?.value}
            dataSource={getOpenDialogModeEnum()}
            onChange={(v) => {
              onChange(value, {
                value: v,
              });
            }}
          />
        );
      },
    });
  }
  const result = [
    {
      name: 'layoutMode',
      title: '布局模式',
      initialValue: 'multiple',
      defaultValue: 'multiple',
      setter: {
        title: '选择布局模式',
        componentName: 'RadioGroupSetter',
        props: {
          options: [
            {
              value: 'multiple',
              title: '普通表单',
            },
            {
              value: 'single',
              title: '信箱表单',
            },
          ],
        },
      },
    },
    getColsSetterSnippet({
      initialValue: 1,
      condition(prop) {
        return (
          prop?.getNode?.()?.getPropValue?.('formStyle.layoutMode') ===
          'multiple'
        );
      },
    }),
    {
      name: 'readOnly',
      title: '只读',
      display: 'inline',
      tip: '',
      // supportVariable: false,
      className: 'cn-text-expr-setter',
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              // componentName: 'BoolSetter',
              // title: '布尔（true/false）',
              title: '是 或 否',
              componentName: 'RadioGroupSetter',
              props: {
                options: [
                  {
                    value: true,
                    title: '是',
                  },
                  {
                    value: false,
                    title: '否',
                  },
                  { title: '不设置' },
                ],
              },
            },
            {
              // componentName: <TextExprSetter
              //   dataKey={'aa'}
              //   labelKey={'aa'}
              //   valueKey={'aa'}
              //   groupName={'aa'}
              // />,
              componentName: <ExprSetter configList={readOnlyConfigList} />,
              title: '简单表达式',
            },
            getJSExpressionPrototype({ type: 'form' }),
          ],
        },
      },
    },
    {
      name: 'allowCopy',
      title: '只读时显示数据拷贝图标',
      display: 'inline',
      className: 'cn-text-expr-setter',
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              title: '是 或 否',
              componentName: 'RadioGroupSetter',
              props: {
                options: [
                  {
                    value: true,
                    title: '是',
                  },
                  {
                    value: false,
                    title: '否',
                  },
                  { title: '不设置' },
                ],
              },
            },
          ],
        },
      },
      condition(prop) {
        return prop?.getNode?.()?.getPropValue?.('formStyle.readOnly') === true;
      },
    },
    getLabelAlignSetterSnippet(),
  ];
  if ([DisplayPosition.form, ButtonPosition.formDialog].includes(position)) {
    result.push({
      name: 'isAnchorForm',
      title: '开启电梯表单',
      setter: 'BoolSetter',
    });
    result.push({
      name: 'headingLevel',
      title: '电梯表单显示层级',
      // condition(prop){
      //   const isAnchorForm = prop?.parent?.getValue?.()?.isAnchorForm;
      //   if(isAnchorForm === false) {
      //     return false
      //   }
      //   return true;
      // },
      setter: getCnSelectSetterSnippet({
        options: [
          {
            label: '卡片',
            value: 1,
          },
          {
            label: '子卡片',
            value: 2,
          },
        ],
        propsConfig: {
          mode: 'multiple',
        },
      }),
    });
    if (DisplayPosition.form === position) {
      result.push({
        name: 'mode',
        title: '操作栏位置',
        initialValue: 'page',
        defaultValue: 'page',
        setter: getCnSelectSetterSnippet({
          options: [
            {
              title: '在页面底部吸底',
              value: 'page',
            },
            {
              title: '在容器底部吸底',
              value: 'block',
            },
          ],
        }),
      });
    }
  }
  return result;
}

export function createDataSourceSetters() {
  return [
    {
      name: '_dataSource',
      title: '数据源',
      display: 'inline',
      supportVariable: false,
      mutator(value) {
        const node = this.getProps().getNode();
        const result = getDsNameFromVariable(value);
        if (result) {
          node.setPropValue('_dataSourceName', result);
        }
      },
      initialValue(value) {
        const v = this.getValue();
        // if (value) {
        //   return value;
        // }
        // if(Engine?.Exchange?.hoveringNode){
        //   const node = this.getNode();
        //   const {componentName} = node;
        //   const currentDataSource = node.getPropValue('_dataSource');
        const { componentName } = this;
        const currentDataSource = this.getPropValue('_dataSource');
        if (!currentDataSource && !v) {
          const ds = generateDataSource({ componentName });
          if (ds?.name) {
            // this.setPropValue('_dataSourceName',ds.name)
            this.parent?.setPropValue?.('_dataSourceName', ds.name);
            return {
              type: 'variable',
              variable: `state.${ds.name}`,
            };
          }
        }
      },
      setter: <DataSourceSetter />,
    },
    {
      name: '_dataSourceName',
      title: '数据源名称',
      display: 'none',
      // supportVariable: true,
      initialValue() {
        const value = this.parent?.getPropValue?.('_dataSourceName');
        if (!value) {
          const node = this.getNode();
          const dsExpr = node?.getPropValue('_dataSource');
          return getDsNameFromVariable(dsExpr);
        }
        return value;
      },
    },
  ];
}

export function createFormDataSourceSetters(config) {
  const { extraConfigure = [] } = config || {};
  return [
    {
      title: '高级配置',
      type: 'group',
      collapsed: true,
      display: 'accordion',
      items: [
        getNodeIdSetterSnippet(),
        ...createDataSourceSetters(),
        ...extraConfigure,
      ],
    },
  ];
}

export function createFormDefaultValueSetters(config) {
  const { afterRequestSuccessPosition, position } = config || {};
  const extraServiceProps = {};
  if (DisplayPosition.formDialog === position) {
    extraServiceProps.dynamicUrlTemplate = `function(state, { openDialogMode }) {
  // openDialogMode: ${openDialogModeLabel}: 新增(new)、编辑(edit)、详情(detail)
  return '/xxx';
}`;
  }
  let extraConfigSetter;
  const requestExecuteSetter = getRequestExecuteSetter({
    exprSetter: getExprSetterSnippet({
      position: 'formDefaultValue',
    }),
  });
  if (requestExecuteSetter) {
    extraConfigSetter = [requestExecuteSetter];
  }
  const configure = [
    {
      name: 'defaultParams',
      display: 'accordion',
      title: '设置表单数据',
      collapsed: true,
      extraProps: {
        defaultCollapsed: true,
      },
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              {
                name: 'dataOrigin',
                title: '数据来源类型',
                display: 'inline',
                extraProps: {
                  defaultValue: 'static',
                },
                defaultValue: 'static',
                initialValue: 'static',
                setter: {
                  componentName: 'MixedSetter',
                  props: {
                    setters: [
                      {
                        componentName: 'RadioGroupSetter',
                        props: {
                          options: [
                            {
                              title: '静态数据',
                              value: 'static',
                            },
                            {
                              title: '远程请求',
                              value: 'request',
                            },
                          ],
                        },
                      },
                      getJSExpressionPrototype({ type: 'dataOrigin' }),
                    ],
                  },
                },
              },
              {
                name: 'requestConfig',
                title: '查询服务',
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    buttonText: '选择请求API',
                    responseDom: (
                      <div style={{ paddingTop: '10px' }}>
                        请求返回结果的数据结构：
                        <CnTooltip align={'t'} trigger={<a>查看</a>}>
                          <div
                            style={{
                              width: '200px',
                              height: '170px',
                            }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src='https://img.alicdn.com/imgextra/i4/O1CN0158wCxG1ddDBKBvDA7_!!6000000003758-0-tps-356-296.jpg'
                            />
                          </div>
                        </CnTooltip>
                      </div>
                    ),
                    resultProcessFuncTemplate: `function(res, state) {
  return res;
}`,
                    params: {
                      env: 'pre',
                      pageSize: 999,
                      // serviceType: 'HSF',
                    },
                    extraConfigSetter,
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              ParamSelectSetterComponent: ParamSelectSetter,
                              configList: [
                                {
                                  groupName: '其他数据',
                                  groupExprName: __dataSource__,
                                  needSecondParam: true,
                                },
                              ],
                              // dataKey: 'aa',
                              // labelKey: 'aa',
                              // valueKey: 'aa',
                              // groupName: '参数列表',
                            },
                            title: '选择参数',
                          },
                          {
                            componentName: 'StringSetter',
                            title: '字符串',
                          },
                          getJSExpressionPrototype({ type: 'base' }),
                        ],
                      },
                    },
                    ...extraServiceProps,
                  },
                },
                condition(prop) {
                  return (
                    prop?.parent?.getPropValue?.('dataOrigin') !== 'static'
                  );
                },
              },
              {
                name: 'defaultValue',
                title: '编辑默认值',
                display: 'inline',
                tip: '编辑静态展示数据',
                className: 'cn-param-select-setter-style-fix',
                setter: {
                  componentName: 'MixedSetter',
                  props: {
                    setters: [
                      {
                        componentName: 'JsonSetter',
                        title: 'JSON编辑器',
                      },
                      {
                        componentName: 'ParamSelectSetter',
                        props: {
                          ParamSelectSetterComponent: ParamSelectSetter,
                          configList: [
                            {
                              groupName: '其他数据',
                              groupExprName: __dataSource__,
                              needSecondParam: true,
                            },
                          ],
                          // dataKey: 'aa',
                          // labelKey: 'aa',
                          // valueKey: 'aa',
                          // groupName: '参数列表',
                        },
                        title: '从数据源选择',
                      },
                      {
                        title: '高级编辑器',
                        componentName: (
                          <DefaultValueSetter
                            dataKey={'config'}
                            labelKey={'label'}
                            valueKey={'name'}
                            groupName={'表单字段'}
                            position={position}
                          />
                        ),
                      },
                      {
                        title: '绑定默认值方法',
                        componentName: (
                          <ActionSetter
                            supportTypes={['page']}
                            defaultCode={`/**
 * getDefaultValue 返回表单默认值
 * 返回格式为: { key: value }
 */
function getDefaultValue() {
   return {};
}`}
                            defaultActionName='getDefaultValue'
                          />
                        ),
                      },
                      getJSExpressionPrototype({ type: 'base' }),
                    ],
                  },
                },
                condition(prop) {
                  return (
                    prop?.parent?.getPropValue?.('dataOrigin') !== 'request'
                  );
                },
              },
            ],
          },
        },
      },
    },
  ];
  if (afterRequestSuccessPosition) {
    configure[0].setter.props.config.items.push(
      createRequestSuccessPrototype(afterRequestSuccessPosition, {
        afterRequestCondition(prop) {
          return prop?.parent?.getPropValue?.('dataOrigin') !== 'static';
        },
      }),
    );
  }
  if (
    [
      DisplayPosition.form,
      DisplayPosition.formDialog,
      DisplayPosition.filter,
    ].includes(position)
  ) {
    configure[0].setter.props.config.items.push({
      name: 'deferRender',
      title: '请求完成后再渲染',
      setter: 'BoolSetter',
      condition(prop) {
        return prop?.parent?.getPropValue?.('dataOrigin') === 'request';
      },
    });
  }
  return configure;
}

// 动态表单setter
export function createDynamicConfigSetter() {
  const configure = [
    {
      name: 'dynamicConfig',
      // display: 'inline',
      title: '原动态表单配置（废弃）',
      // collapsed: true,
      // extraProps: {
      //   defaultCollapsed: true,
      // },
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              // {
              //   name: 'schema',
              //   title:'表单配置',
              //   setter: <FormExportSetter  />
              // },
              {
                name: 'requestConfig',
                title: '动态表单查询服务',
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    paramTitleDom: <div className=''>请求参数配置：</div>,
                    // responseDom: <div style={{paddingTop:'10px'}}>
                    //   请求返回结果的数据结构：<a target={'_blank'} href="https://yuque.antfin.com/cn-framework-committee/cn-ui-data-structure/cn-async-select#iEuF0">接口文档</a> <CnTooltip
                    //   v2
                    //   align={'t'}
                    //   trigger={<a>接口预览</a>}
                    // >
                    //   <div style={{width:'200px',height:'260px'}}>
                    //     <img style={{width:'100%'}} src="https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg"/>
                    //   </div>
                    // </CnTooltip>
                    // </div>,
                    buttonText: '选择请求API',
                    params: {
                      env: 'pre',
                      pageSize: 999,
                      // serviceType: 'HSF',
                    },
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              dataKey: 'aa',
                              labelKey: 'aa',
                              valueKey: 'aa',
                              groupName: 'aa',
                            },
                            title: '选择参数',
                          },
                          {
                            componentName: 'StringSetter',
                            title: '字符串',
                          },
                          getJSExpressionPrototype({ type: 'formRequest' }),
                        ],
                      },
                    },
                    resultProcessFuncTemplate: `function(res) {
  // 需要返回的如下的数据结构
  // return {
  //   success: true,
  //   data: [
  //   ]
  // }
 return res;
}`,
                  },
                },
              },
            ],
          },
        },
      },
    },
  ];
  return configure;
}

// 动态表单setter
export function createDynamicFormConfigSetter() {
  return [
    {
      name: 'dynamicFormConfig',
      display: 'accordion',
      title: '动态表单配置',
      collapsed: true,
      extraProps: {
        defaultCollapsed: true,
      },
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              {
                name: 'active',
                title: '开启动态表单',
                setter: 'BoolSetter',
              },
              {
                name: 'requestConfig',
                title: '动态表单查询服务',
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    paramTitleDom: <div className=''>请求参数配置：</div>,
                    // responseDom: <div style={{paddingTop:'10px'}}>
                    //   请求返回结果的数据结构：<a target={'_blank'} href="https://yuque.antfin.com/cn-framework-committee/cn-ui-data-structure/cn-async-select#iEuF0">接口文档</a> <CnTooltip
                    //   v2
                    //   align={'t'}
                    //   trigger={<a>接口预览</a>}
                    // >
                    //   <div style={{width:'200px',height:'260px'}}>
                    //     <img style={{width:'100%'}} src="https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg"/>
                    //   </div>
                    // </CnTooltip>
                    // </div>,
                    buttonText: '选择请求API',
                    params: {
                      env: 'pre',
                      pageSize: 999,
                      // serviceType: 'HSF',
                    },
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              ParamSelectSetterComponent: ParamSelectSetter,
                              configList: [
                                {
                                  groupName: '其他数据',
                                  groupExprName: __dataSource__,
                                  needSecondParam: true,
                                },
                              ],
                              // dataKey: 'aa',
                              // labelKey: 'aa',
                              // valueKey: 'aa',
                              // groupName: 'aa',
                            },
                            title: '选择参数',
                          },
                          {
                            componentName: 'StringSetter',
                            title: '字符串',
                          },
                          getJSExpressionPrototype({ type: 'formRequest' }),
                        ],
                      },
                    },
                    resultProcessFuncTemplate: `function(res) {
    // 需要返回的如下的数据结构
    // return {
    //   success: true,
    //   data: [
    //   ]
    // }
   return res;
  }`,
                  },
                },
                condition(prop) {
                  return prop?.parent?.getPropValue?.('active') === true;
                },
              },
              {
                name: 'reactions',
                title: '配置动态表单联动',
                setter: <FormilyReactionSetter />,
              },
            ],
          },
        },
      },
    },
  ];
}
