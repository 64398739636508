import { DisplayPosition } from '../position/display-position';
import React from 'react';
import { isArrayNotEmpty, uuid } from '../../util/util';
const FormItemSlot = {
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '表格插槽',
    thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01AEPsrs1EnPhgs1wsH_!!6000000000396-2-tps-240-144.png',
    componentName: 'FormItemSlot',
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig, isDesign } = config || {};
        const { options } = formItemConfig || {};
        const { componentSlot } = options || {};
        const componentProps = formItem?.['x-component-props'];
        if (componentProps) {
            componentProps.getComponentSlot = () => {
                return componentSlot;
            };
            componentProps.componentSlot = undefined;
            if (isDesign) {
                componentProps._designStamp = Date.now();
            }
        }
    },
    formComponent: (props) => {
        const { getComponentSlot, useDetailValue } = props || {};
        const componentSlot = getComponentSlot?.();
        if (isArrayNotEmpty(componentSlot)) {
            return (<div>
          {componentSlot.map((item, index) => {
                    return React.cloneElement(item, {
                        value: props?.value,
                        onChange: props?.onChange,
                        key: index,
                        useDetailValue,
                        isFromFormItem: true,
                    });
                })}
        </div>);
        }
        return <div>{componentSlot}</div>;
    },
    getFormItemDefaultProps: () => {
        return {
            label: '表格',
            name: `formItemSlot_${uuid()}`,
        };
    },
    getDefaultProps: () => {
        return {
            useDetailValue: false,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'componentSlot',
                title: '开启插槽',
                setter: {
                    componentName: 'SlotSetter',
                    title: '组件坑位',
                    initialValue: {
                        type: 'JSSlot',
                        value: [],
                    },
                },
            },
            {
                name: 'useDetailValue',
                title: '使用详细数据',
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: '是', value: true },
                            { title: '否', value: false },
                            { title: '不设置' },
                        ],
                    },
                },
            },
        ];
    },
};
export default FormItemSlot;
