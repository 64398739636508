import { DisplayPosition } from '../position/display-position';
import { UploadCom } from './components/upload';
import { getJSExpressionPrototype } from '../common-style';
import { handleRequestParams, handleResultProcessFunc, transRequestConfigToRemote, } from '../../util/request';
import { __advancedConfig__ } from '@/common/util/const';
import { setAdvancedConfigToProps } from '@/common/util/util';
import { getRequestConfigSetterSnippet } from '@/common/manager/setter-snippet';
import DocSetter from '@/common/setter/doc-setter';
import UploadEnhanceSetter from '@/common/setter/upload-enhance-setter';
const Upload = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '文件上传',
    componentName: 'Upload',
    component: UploadCom,
    formComponent: UploadCom,
    thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN017DgkUq1IzYfWBFOAD_!!6000000000964-2-tps-240-144.png',
    getDefaultProps: () => {
        return {
            mode: 'oss',
            useDetailValue: false,
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { state, formValue, urlParams } = config;
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                const { requestConfig, uploadProps, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = componentProps;
                if (uploadProps && Array.isArray(uploadProps.accept)) {
                    uploadProps.accept = uploadProps.accept.join(',');
                }
                if (webOfficeEnable === true) {
                    componentProps.webOfficeEditRequestConfig =
                        transRequestConfigToRemote({
                            requestConfig: webOfficeEditRequestConfig,
                            urlParamsDataSource: urlParams,
                            recordDataSource: formValue,
                            state,
                        });
                    componentProps.webOfficePreviewRequestConfig =
                        transRequestConfigToRemote({
                            requestConfig: webOfficePreviewRequestConfig,
                            urlParamsDataSource: urlParams,
                            recordDataSource: formValue,
                            state,
                        });
                }
                else {
                    delete componentProps.webOfficeEditRequestConfig;
                    delete componentProps.webOfficePreviewRequestConfig;
                }
                if (requestConfig) {
                    const { url, method, serviceType, params, resultProcessFunc, __extraConfig__, } = requestConfig;
                    if (url) {
                        const service = {
                            url,
                        };
                        const ossUploadConfig = {};
                        const { withCredentials } = __extraConfig__ || {};
                        if (withCredentials === true) {
                            service.withCredentials = true;
                            // 2023.3.23 文件上传组件升级后，获取凭证和推送文件两个请求拆分为两个requestConfig
                            ossUploadConfig.withCredentials = true;
                            componentProps.ossUploadConfig = ossUploadConfig;
                        }
                        if (serviceType === 'mokelay') {
                            service.method = 'post';
                        }
                        else if (method) {
                            service.method = method;
                        }
                        componentProps.service = service;
                        if (Array.isArray(params) && params.length > 0) {
                            const realParams = handleRequestParams(params, {
                                urlParamsDataSource: urlParams,
                                recordDataSource: formValue,
                                state,
                            });
                            if (realParams && Object.keys(realParams).length > 0) {
                                if (service.method === 'post') {
                                    service.data = realParams;
                                }
                                else {
                                    service.params = realParams;
                                }
                            }
                        }
                        if (typeof resultProcessFunc === 'function') {
                            service.formatResult = handleResultProcessFunc(requestConfig);
                        }
                    }
                    delete componentProps.requestConfig;
                }
                if (componentProps?.service) {
                    componentProps.requestConfig = componentProps.service;
                }
                setAdvancedConfigToProps(componentProps);
                formItem['x-component-props'] = {
                    ...componentProps,
                    ...uploadProps,
                };
            }
        }
    },
    // filterItemBeforeHandler: (filterItemProps, componentProps)=> {
    //   if(componentProps) {
    //     const { requestConfig } = componentProps;
    //     if(requestConfig){
    //       const { url, method, serviceType } = requestConfig
    //       if(url) {
    //         componentProps.action = url;
    //       }
    //       if(serviceType === 'mokelay') {
    //         componentProps.method = 'post'
    //       }else if(method){
    //         componentProps.method = method;
    //       }
    //     }
    //     delete componentProps.requestConfig;
    //   }
    // },
    getPrototypeList: () => {
        let extraConfigSetter = [
            {
                name: 'withCredentials',
                title: '跨域带cookie（withCredentials）',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'BoolSetter',
                                title: '是 / 否',
                            },
                        ],
                    },
                },
            },
        ];
        return [
            {
                name: 'mode',
                title: '上传模式',
                // display: 'inline',
                condition() {
                    return false;
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { value: 'java', title: '上传到后端服务' },
                            { value: 'oss', title: '前端直接上传到oss' },
                        ],
                    },
                },
            },
            {
                name: '',
                setter: <UploadEnhanceSetter />,
            },
            {
                name: 'requestConfig',
                title: '上传地址',
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: '选择请求API',
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramTitleDom: <div className=''>请求参数配置：</div>,
                        responseDom: (<div style={{ paddingTop: '10px' }}>
                请求返回结果的数据结构：
                <a target={'_blank'} href='https://yuque.antfin.com/docs/share/ae7a75a0-f1b1-48f4-82ac-8ea75e78f7f5#euKLg'>
                  数据结构文档
                </a>
              </div>),
                        extraConfigSetter,
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    {
                                        componentName: 'ParamSelectSetter',
                                        props: {
                                            dataKey: 'config',
                                            labelKey: 'label',
                                            valueKey: 'name',
                                            groupName: '参数列表',
                                        },
                                        title: '选择参数',
                                    },
                                    {
                                        componentName: 'StringSetter',
                                        title: '字符串',
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: `function(res) {
  // 需要返回的如下的数据结构
  // return {
  //   success: true,
  //   data: [
  //     {
  //        host:"xx",
  //        key:"xx",
  //        name:"xx",
  //        ossAccessKeyId:"xx",  // 对应 OSSAccessKeyId
  //        policy:"xx",
  //        signature:"xx",
  //        expire:"xx",
  //        success_action_status:"xx",
  //     }
  //   ]
  // }
 return res;
}`,
                    },
                },
            },
            {
                name: 'uploadProps',
                display: 'plain',
                title: '上传props',
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'limit',
                                    title: '最大文件上传个数',
                                    setter: 'NumberSetter',
                                },
                                {
                                    name: 'multiple',
                                    title: '支持多选文件',
                                    setter: 'BoolSetter',
                                },
                                // {
                                //   name: 'name',
                                //   title: '发到后台的文件参数名',
                                //   setter: {
                                //     componentName: 'StringSetter',
                                //     props: {
                                //       placeholder: '默认是file'
                                //     }
                                //   },
                                // },
                                {
                                    name: 'accept',
                                    title: '接受上传的文件类型',
                                    setter: {
                                        componentName: 'MixedSetter',
                                        props: {
                                            setters: [
                                                {
                                                    componentName: 'CnSelectSetter',
                                                    props: {
                                                        mode: 'multiple',
                                                        options: [
                                                            { value: 'image/*', title: '图片（image/*）' },
                                                            { value: '.png', title: 'png图片（.png）' },
                                                            { value: '.jpg', title: 'jpg图片（.jpg）' },
                                                            { value: '.jpeg', title: 'jpeg图片（.jpeg）' },
                                                            { value: '.pdf', title: 'pdf文件（.pdf）' },
                                                            { value: '.doc', title: 'doc文件（.doc）' },
                                                            { value: '.zip', title: 'zip压缩文件（.zip）' },
                                                            {
                                                                value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                                                                title: 'excel文件',
                                                            },
                                                            { value: '.csv', title: 'csv文件（.csv）' },
                                                            { value: 'audio/*', title: '音频（audio/*）' },
                                                            { value: 'video/*', title: '视频（video/*）' },
                                                        ],
                                                        selectProps: {
                                                            hasClear: true,
                                                        },
                                                    },
                                                    title: '常用格式',
                                                },
                                                {
                                                    componentName: 'StringSetter',
                                                    title: '手写accept',
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: 'description',
                                    title: '帮助信息',
                                    setter: 'CnI18nSetter',
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: 'webOfficeEnable',
                title: '开启AliYunWebOffice服务',
                setter: 'BoolSetter',
            },
            {
                display: 'none',
                setter: (<DocSetter content={<a target={'_blank'} href={'https://alidocs.dingtalk.com/i/nodes/ZK0RbznqyYaWdQgoodjBV953mBE4AdDw?dontjump=true'} rel='noreferrer'>
                阿里云WebOffice服务接入手册
              </a>}/>),
            },
            getRequestConfigSetterSnippet({
                name: 'webOfficeEditRequestConfig',
                title: 'WebOffice在线编辑凭证请求配置',
                condition: (prop) => {
                    return prop?.parent?.getPropValue?.('webOfficeEnable') === true;
                },
            }),
            getRequestConfigSetterSnippet({
                name: 'webOfficePreviewRequestConfig',
                title: 'WebOffice在线预览凭证请求配置',
                condition: (prop) => {
                    return prop?.parent?.getPropValue?.('webOfficeEnable') === true;
                },
            }),
            {
                title: '高级配置',
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'shape',
                                    title: '样式',
                                    setter: {
                                        componentName: 'RadioGroupSetter',
                                        props: {
                                            options: [
                                                { title: '普通', value: 'normal' },
                                                { title: '精简', value: 'mini' },
                                                { title: '可拖拽', value: 'dragger' },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: 'useDetailValue',
                                    title: '使用详细数据',
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default Upload;
