import React from 'react';
// import { Checkbox } from '@cainiaofe/cn-ui';
import './index.scss';

export default class DocSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content } = this.props;
    return <div className='cn-doc-setter'>{content}</div>;
  }
}
