import { DisplayPosition } from '../position/display-position';
import { componentMap as formComponentMap, formilyReact, } from '@cainiaofe/cn-ui';
import { calculateTextExprValue, executeFunction, getArrayTableCurrentRowByField2, makeButtons, uuid, } from '../../util/util';
import { createButtonListSetters } from '@/common/manager/button';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { getColsSetterSnippet, getMixedSetterSnippet, } from '@/common/manager/setter-snippet';
import { __arrayTableCurrentRow__ } from '@/common/util/expr-const';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
const { useField } = formilyReact;
const CnFormGrid = 'CnFormGrid';
const CnCardSubCard = {
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01Dg50E61XolZZhZJYJ_!!6000000002971-2-tps-240-144.png',
    title: '子卡片',
    componentName: 'CnCardSubCard',
    formComponent: (props) => {
        let Component = formComponentMap.CnCardSubCard;
        const extraProps = {};
        if (props.shape === 'CnCardSubAreaCard') {
            Component = formComponentMap.CnCardSubAreaCard;
            const { subTitle, subDesc } = props || {};
            extraProps.title = subTitle;
            extraProps.desc = subDesc;
        }
        return <Component {...props} {...extraProps}/>;
    },
    isFormContainer: true,
    allowParentList: ['CnCard', 'CnFormStepItem', 'CnFormTabItem'],
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, parentPosition, } = config || {};
        const { formStyle = {} } = formProps;
        const { cols, readOnly } = formStyle || {};
        if (formItem) {
            const { label, name, readOnly: formItemReadOnly, options = {}, } = formItemConfig;
            const { buttons = [] } = options;
            const componentProps = formItem['x-component-props'] || {};
            if (componentProps) {
                if (componentProps.subTitle) {
                    componentProps.subTitle = calculateTextExprValue(componentProps.subTitle, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (label && !componentProps.subTitle) {
                    componentProps.subTitle = label;
                }
                if (Array.isArray(buttons) && buttons.length > 0) {
                    let action;
                    const makeButtonConfig = {
                        buttons: buttons.map((item) => {
                            let realChildren = item?.children;
                            if (typeof item?.children === 'function') {
                                realChildren = executeFunction(item.children, formValue, state);
                            }
                            return {
                                ...item,
                                style: { marginLeft: '8px' },
                                size: 'small',
                                position: ButtonPosition.subCardAction,
                                children: realChildren,
                            };
                        }),
                        _context,
                        state,
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        formInstance,
                        parentPosition,
                    };
                    if (parentPosition === DisplayPosition.cnArraySubAreaCard) {
                        action = () => {
                            const field = useField?.();
                            if (field) {
                                const currentRow = getArrayTableCurrentRowByField2(field);
                                makeButtonConfig?.buttons?.forEach?.((item) => {
                                    item.getExtraParam = () => {
                                        return {
                                            [__arrayTableCurrentRow__]: currentRow,
                                        };
                                    };
                                });
                                makeButtonConfig.arrayTableCurrentRow = currentRow;
                            }
                            return makeButtons(makeButtonConfig);
                        };
                    }
                    else {
                        action = makeButtons(makeButtonConfig);
                    }
                    if (action) {
                        componentProps.action = action;
                        delete componentProps.buttons;
                    }
                }
            }
            // if(formItemReadOnly === undefined) {
            //   formItem.readOnly = readOnly;
            // }else if(isBoolean(formItemReadOnly)) {
            //   formItem.readOnly = formItemReadOnly;
            // }
            delete formItem['x-decorator'];
            formItem.title = '';
            formItem.type = 'void';
            const { cols: subCardCols } = componentProps;
            const formGridProps = {};
            if (subCardCols) {
                formGridProps.cols = subCardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            };
        }
    },
    getFormItemDefaultProps: () => {
        return {
            label: '子卡片标题',
            name: `subCard_${uuid()}`,
        };
    },
    getDefaultProps: () => {
        return {
            shape: 'CnCardSubCard',
        };
    },
    getPrototypeList: (position) => {
        return [
            {
                name: 'shape',
                title: '样式',
                display: 'inline',
                setter: {
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: '子卡片',
                                value: 'CnCardSubCard',
                            },
                            {
                                label: '三级卡片',
                                value: 'CnCardSubAreaCard',
                            },
                        ],
                    },
                },
            },
            {
                name: 'subTitle',
                title: '标题',
                display: 'inline',
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            title: '文本',
                            componentName: 'CnI18nSetter',
                        },
                        getJSExpressionPrototype({
                            type: 'formRequest',
                        }),
                    ],
                }),
            },
            {
                name: 'subDesc',
                title: '描述',
                display: 'inline',
                setter: 'CnI18nSetter',
            },
            getColsSetterSnippet(),
            {
                name: 'buttons',
                title: '卡片右侧按钮',
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: createButtonListSetters({
                    position: ButtonPosition.subCardAction,
                    additionalPosition: position,
                }),
            },
        ];
    },
};
export default CnCardSubCard;
