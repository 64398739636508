import { DisplayPosition } from '@/common/manager/position/display-position';
import lt from 'lodash/lt';
import lte from 'lodash/lte';
import eq from 'lodash/eq';
import _includes from 'lodash/includes';
export const __formValue__ = '__formValue__';
export const __filterValue__ = '__filterValue__';
export const __tableCurrentRow__ = '__tableCurrentRow__';
export const __dataSource__ = '__dataSource__';
export const __permissionGranted__ = '__permissionGranted__';
export const __permission_point_list__ = '__permission_point_list__';
export const __arrayTableCurrentRow__ = '__arrayTableCurrentRow__';
export const __arrayTableCurrentRowIndex__ = '__arrayTableCurrentRowIndex__';
// 点击整列
export const __arrayTableCurrentRowByWhole__ = '__arrayTableCurrentRowByWhole__';
// 点击整列
export const __arrayTableCurrentRowIndexByWhole__ = '__arrayTableCurrentRowIndexByWhole__';
// 点击卡片整列
export const __arrayCardCurrentRowByWhole__ = '__arrayCardCurrentRowByWhole__';
// 点击卡片整列
export const __arrayCardCurrentRowIndexByWhole__ = '__arrayCardCurrentRowIndexByWhole__';
export const __selectedRowKeys__ = '__selectedRowKeys__';
export const __selectedRowRecords__ = '__selectedRowRecords__';
export const __totalCount__ = '__totalCount__';
export const __tableExtra__ = '__tableExtra__';
// 当前第几步
export const __step_current__ = '__step_current__';
export const __step_list__ = '__step_list__';
export const __step_activeKey__ = '__step_activeKey__';
// 当前步骤详细信息
export const __step_currentItem__ = '__step_currentItem__';
export const __paging__ = '__paging__';
export const __tableData__ = '__tableData__';
export const __tableColumns__ = '__tableColumns__';
export const __flowData__ = '__flowData__';
export const __tab_activeKey__ = '__tab_activeKey__';
export const __tab_removeKey__ = '__tab_removeKey__';
export const __statisticCard_activeKey__ = '__statisticCard_activeKey__';
export const __statisticCard_currentItem__ = '__statisticCard_currentItem__';
export const __statisticCard_clickKey__ = '__statisticCard_clickKey__';
export const __statisticCard_clickItem__ = '__statisticCard_clickItem__';
export const __list_activeKey__ = '__list_activeKey__';
export const __list_currentItem__ = '__list_currentItem__';
// 左树当前选中的值
export const __left_tree_currentValue__ = '__left_tree_currentValue__';
// 左树当前选中的项
export const __left_tree_currentItem__ = '__left_tree_currentItem__';
// 公共参数
export const __extraParam__ = '__extraParam__';
// 挂到valueOf上的属性间填充符号
export const __stateValueOfSplit__ = '.';
export const openDialogModeLabel = '弹窗表单本次打开的用途';
export const formDialogStateLabel = '[当前弹窗表单的状态信息]';
export const formStateLabel = '[当前表单的状态信息]';
export const tableCurrentRowByOperateClickLabel = '操作列按钮点击后的行数据';
export const tableCurrentRowIndexByOperateClickLabel = '操作列按钮点击后的行Index';
export const arrayTableCurrentRowLabel = '当前正在操作的行数据';
export const arrayTableCurrentRowIndex = '当前正在操作的行Index';
export const arrayCardCurrentRowLabel = '当前正在操作的卡片数据';
export const arrayCardCurrentRowIndex = '当前正在操作的卡片Index';
export const tableCurrentRowLabel = '点击的当前行数据';
export const tableCurrentRowIndex = '点击的当前行Index';
export const currentPageLabel = '表格当前点击的页码(currentPage)';
export const pageSizeLabel = '表格当前一页展示几条(pageSize)';
export const currentFormCardLabel = '当前卡片的数据';
export const currentFormStepLabel = '分步表单-当前是第几步';
export const leftTreeStateLabel = '当前树的信息';
export const leftTreeAfterDropDataSourceLabel = '拖拽后树的完整数据（dataSource）';
// 分步表单当前第几步
export const __form_step_current__ = '__form_step_current__';
export const componentRefSplit = '_$ref$_';
// 权限点变量
export const button_view = 'button_view';
export function getFormExprNameByPosition(config) {
    const { position } = config;
    let result;
    switch (position) {
        case DisplayPosition.filter:
            result = __filterValue__;
            break;
        case DisplayPosition.form:
        case DisplayPosition.cnArrayTable:
        case DisplayPosition.formDialog:
            result = __formValue__;
            break;
        default:
            result = __formValue__;
    }
    return result;
}
function equal(a, b, config) {
    const { type } = config || {};
    if (type === 'array') {
        return (a === undefined ||
            a === null ||
            a === '' ||
            (Array.isArray(a) && a.length === 0));
    }
    else if (b === undefined || b === null || b === '') {
        if (a === 0 || a === false) {
            return false;
        }
        else {
            return !a;
        }
    }
    else {
        return eq(a, b);
    }
}
function notEqual(a, b, config) {
    const { type } = config || {};
    if (type === 'array') {
        return Array.isArray(a) && a.length > 0;
    }
    else if (b === undefined || b === null || b === '') {
        if (a === 0 || a === false) {
            return true;
        }
        else {
            return !!a;
        }
    }
    else {
        return !eq(a, b);
    }
}
function includes(a, b, config) {
    const { type } = config || {};
    return _includes(a, b);
}
function notIncludes(a, b, config) {
    const { type } = config || {};
    return !_includes(a, b);
}
export const symbolMap = {
    '<': lt,
    '<=': lte,
    '>': function (a, b) {
        return a > b;
    },
    '>=': function (a, b) {
        return a >= b;
    },
    '==': equal,
    '=': equal,
    '===': equal,
    '!=': notEqual,
    includes,
    notIncludes,
};
export const compareList = [
    {
        label: '大于',
        value: '>',
    },
    {
        label: '大于等于',
        value: '>=',
    },
    {
        label: '小于',
        value: '<',
    },
    {
        label: '小于等于',
        value: '<=',
    },
];
export const compareMap = {};
compareList.forEach((item) => {
    const { label, value } = item || {};
    compareMap[value] = label;
});
export const timeUnitList = [
    {
        label: '天',
        value: 'day',
    },
    {
        label: '周',
        value: 'week',
    },
    {
        label: '月',
        value: 'month',
    },
    {
        label: '年',
        value: 'year',
    },
];
export const timeUnitList2 = [
    {
        label: '秒',
        value: 'second',
    },
    {
        label: '分钟',
        value: 'minute',
    },
    {
        label: '小时',
        value: 'hour',
    },
];
export const timeUnitMap = {};
timeUnitList.forEach((item) => {
    const { label, value } = item || {};
    timeUnitMap[value] = label;
});
timeUnitList2.forEach((item) => {
    const { label, value } = item || {};
    timeUnitMap[value] = label;
});
export const timeMillisecondMap = {
    hour: 3600000,
    minute: 60000,
    second: 1000,
};
export const getCommonBoolDataSource = () => {
    return [
        {
            label: '是',
            value: true,
        },
        {
            label: '否',
            value: false,
        },
    ];
};
export function getOpenDialogModeEnum(config) {
    const { prefix = '', suffix = '' } = config || {};
    return [
        {
            label: `${prefix}新增${suffix}`,
            value: 'new',
        },
        {
            label: `${prefix}编辑${suffix}`,
            value: 'edit',
        },
        {
            label: `${prefix}详情${suffix}`,
            value: 'detail',
        },
    ];
}
// @ice/stark-data hack取法
export const iceStore = window?.ICESTARK?.store?.store || {};
