import React, { useCallback, useEffect, useState } from 'react';
import { CnMessage as UICnMessage } from '@cainiaofe/cn-ui';
import { CnDataSourceChange, onEvent } from '@/common/util/event-name';
import {
  isArrayNotEmpty,
  calculateTextExprValue,
  executeFunction,
} from '@/common/util/util';

const CnMessage = (props) => {
  const {
    content = '',
    _context,
    getContext,
    _getFormValue,
    visible,
    type,
    ...rest
  } = props;
  const { needMargin } = rest || {};
  let realContent = content;
  let state = _context?.state;
  const [forceUpdate, setForceUpdate] = useState();
  if (!state && typeof getContext === 'function') {
    state = getContext()?.state || {};
  }
  const formValue = _getFormValue?.();

  useEffect(() => {
    if (typeof content === 'function') {
      onEvent(CnDataSourceChange, () => {
        setForceUpdate(Date.now());
      });
    }
  }, []);

  if (typeof content === 'function') {
    realContent = content(state, formValue || {});
  }

  const realVisible = calculateTextExprValue(visible, {
    recordDataSource: {},
    state,
  });

  const extraProps = {};
  if (needMargin === true) {
    extraProps.style = {
      margin: '12px 0',
    };
  } else if (isArrayNotEmpty(needMargin)) {
    extraProps.style = {};
    needMargin.forEach((item) => {
      if (item === true) {
        extraProps.style.margin = '12px 0';
      } else if (typeof item === 'string') {
        extraProps.style[`margin-${item}`] = '12px';
      }
    });
  }
  extraProps.type = type;
  if (typeof type === 'function') {
    extraProps.type = executeFunction(type, formValue || {}, state);
  }
  return realVisible !== false ? (
    <UICnMessage {...rest} {...extraProps}>
      {realContent}
    </UICnMessage>
  ) : (
    ''
  );
};
CnMessage.displayName = 'CnMessage';
export default CnMessage;

export { CnMessage };
