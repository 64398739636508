import { DisplayPosition } from '../position/display-position';
import { uuid } from '../../util/util';
const CnFormGrid = 'CnFormGrid';
const CnFormTabItem = {
    position: [DisplayPosition.formDialog, DisplayPosition.form],
    title: 'Tab项',
    componentName: 'CnFormTabItem',
    isFormContainer: true,
    allowParentList: ['root'],
    thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01rDkVjF1agvdmpT6nt_!!6000000003360-2-tps-240-144.png',
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, } = config || {};
        const { formStyle = {} } = formProps;
        const { cols } = formStyle || {};
        if (formItem) {
            const { label, name, options = {} } = formItemConfig;
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                if (label && !componentProps.title) {
                    componentProps.title = label;
                }
            }
            delete formItem['x-decorator'];
            formItem.properties = {};
            formItem.title = '';
            formItem.type = 'void';
            const { cols: cardCols } = componentProps;
            const formGridProps = {};
            if (cardCols) {
                formGridProps.cols = cardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            };
        }
    },
    getDefaultProps: () => {
        return {};
    },
    getFormItemDefaultProps: () => {
        return {
            label: 'Tab标签',
            name: `form_tab_${uuid()}`,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'title',
                title: 'Tab项标题',
                display: 'inline',
                setter: 'CnI18nSetter',
            },
        ];
    },
};
export default CnFormTabItem;
