import { calculateTextExprValue, condition } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { CnReadOnly } from '@cainiaofe/cn-ui';
import { getSetterSnippet } from '@/common/manager/setter-snippet';
import isPlainObject from 'lodash/isPlainObject';
import { handleRequestParams } from '@/common/util/request';
import qs from 'query-string';
const ReadOnly = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
    ],
    title: '展示内容格式化（带样式文本、标签、...）',
    thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01OBsa3d1l288LJiQMg_!!6000000004760-0-tps-240-144.jpg',
    componentName: 'ReadOnly',
    getDefaultProps: () => {
        return {
            format: 'style-text',
        };
    },
    formComponent: (props) => {
        const { getRequestParams, getFormItemConfig, ...rest } = props || {};
        const extraProps = {};
        const formItemConfig = getFormItemConfig?.();
        const _params = getRequestParams?.();
        if (isPlainObject(formItemConfig)) {
            const { options = {} } = formItemConfig;
            const { format, text, url, urlParams } = options;
            if (format === 'style-text') {
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format: 'text',
                    tableCellItem: rest?.value,
                };
            }
            else if (format === 'text') {
                const realText = calculateTextExprValue(text, _params) || rest?.value;
                let realUrl = calculateTextExprValue(url, _params);
                const realParams = qs.stringify(handleRequestParams(urlParams, _params));
                if (realUrl && realUrl.indexOf('?') !== -1) {
                    realUrl += `&${realParams}`;
                }
                else {
                    if (realParams) {
                        realUrl += `?${realParams}`;
                    }
                }
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format,
                    tableCellItem: {
                        text: realText,
                        link: realUrl,
                    },
                };
            }
            else if ([
                'progress',
                'tag',
                'percent',
                'multiple-text',
                'currency',
                'currency-with-unit',
            ].includes(format)) {
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format,
                    tableCellItem: rest?.value,
                };
                if (format === 'percent' ||
                    format === 'currency' ||
                    format === 'currency-with-unit') {
                    extraProps.tableCellProps.column = {
                        align: 'left',
                    };
                }
                if (format === 'multiple-text') {
                    extraProps.value = undefined;
                }
            }
        }
        return <CnReadOnly {...rest} {...extraProps}/>;
    },
    formItemBeforeHandler: (formItem, config) => {
        const { urlParams, formValue, state, formItemConfig } = config;
        if (formItem) {
            formItem['x-component-props'] = {
                getRequestParams: () => {
                    return {
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        state,
                    };
                },
                getFormItemConfig: () => {
                    return formItemConfig;
                },
            };
        }
    },
    getPrototypeList: (position) => {
        let paramSetter = 'StringSetter';
        let urlSetter = 'StringSetter';
        const setterSnippet = getSetterSnippet({
            position,
            optType: 'link',
        });
        const { urlSetter: newUrlSetter, requestParamSetter } = setterSnippet || {};
        if (requestParamSetter) {
            paramSetter = requestParamSetter;
        }
        if (newUrlSetter) {
            urlSetter = newUrlSetter;
        }
        return [
            {
                name: 'format',
                title: '展示类型',
                display: 'inline',
                defaultValue: 'static',
                setter: {
                    componentName: 'CnSelectSetter',
                    props: {
                        options: [
                            { value: 'style-text', title: '带样式文本' },
                            { value: 'text', title: '链接' },
                            { value: 'tag', title: '标签' },
                            { value: 'progress', title: '进度条' },
                            { value: 'percent', title: '百分比' },
                            { value: 'multiple-text', title: '多行文本' },
                            { value: 'currency', title: '金额展示' },
                            { value: 'currency-with-unit', title: '金额展示（带币种）' },
                        ],
                        selectProps: {},
                    },
                },
            },
            {
                title: '链接文案',
                name: 'text',
                setter: urlSetter,
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
            {
                title: '链接地址',
                name: 'url',
                className: 'cn-link-url-setter',
                setter: urlSetter,
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
            {
                title: 'url参数',
                name: 'urlParams',
                description: 'url参数',
                className: 'cn-button-url-param-setter',
                setter: {
                    componentName: 'ArraySetter',
                    props: {
                        mode: 'list',
                        itemSetter: {
                            componentName: 'ObjectSetter',
                            initialValue: {
                                label: 'param',
                            },
                            props: {
                                config: {
                                    items: [
                                        {
                                            title: '参数名',
                                            name: 'label',
                                            isRequired: true,
                                            description: '参数名',
                                            setter: 'StringSetter',
                                        },
                                        {
                                            title: '参数值',
                                            name: 'value',
                                            isRequired: true,
                                            description: '参数值',
                                            // supportVariable: true,
                                            className: 'cn-param-select-setter',
                                            setter: paramSetter,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
        ];
    },
};
export default ReadOnly;
