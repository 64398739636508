import { getItemListByPosition, getItemPrototypeListByPosition, } from '../util/util';
import { getButtonListByPosition, getButtonPrototypeListByPosition, } from './button';
import { ButtonPosition } from './position/button-position';
const onFieldInputValueChange = {
    title: '当用户输入改变时（onChange）',
    value: 'onFieldInputValueChange',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
    // getDefaultProps: () => {
    //   return {
    //   };
    // },
    //   getPrototypeList:(position)=>{
    //     if([EventPosition.formItemEvent,EventPosition.formDialogItemEvent].includes(position)) {
    //       return [
    //         {
    //           title: '自定义脚本',
    //           name: 'jsFunction',
    //           setter: <ActionSetter
    //             supportTypes={['page']}
    //             defaultCode={`/**
    // * onFieldValueChange
    // * 请参考formily官方示例 https://formilyjs.org/zh-CN/guide/advanced/linkages#effects-%E7%94%A8%E4%BE%8B-2
    // *
    // * @param field 当前字段
    // * @param form 当前表单实例
    // */
    // function onFieldValueChange(field, form) {
    //
    // }`}
    //         defaultActionName="onFieldValueChange"
    //           />
    //         }
    //       ]
    //     }
    //   }
};
const onFieldInitialValueChange = {
    title: '当字段初始值变化时',
    value: 'onFieldInitialValueChange',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.filterItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
};
const onFieldInit = {
    title: '字段初始化(onFieldInit)',
    value: 'onFieldInit',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.filterItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
};
const onFieldValueChange = (config) => {
    const { position } = config || {};
    let title = '【已废弃】改用 用户输入改变时';
    if (ButtonPosition.filterItemEvent === position) {
        title = '当用户输入改变时（onChange）';
    }
    return {
        title,
        value: 'onFieldValueChange',
        position: [
            ButtonPosition.filterItemEvent,
            ButtonPosition.formItemEvent,
            ButtonPosition.formDialogItemEvent,
            ButtonPosition.cnArrayTableItemEvent,
        ],
        // getDefaultProps: () => {
        //   return {
        //   };
        // },
        //   getPrototypeList:(position)=>{
        //     if([EventPosition.formItemEvent,EventPosition.formDialogItemEvent].includes(position)) {
        //       return [
        //         {
        //           title: '自定义脚本',
        //           name: 'jsFunction',
        //           setter: <ActionSetter
        //             supportTypes={['page']}
        //             defaultCode={`/**
        // * onFieldValueChange
        // * 请参考formily官方示例 https://formilyjs.org/zh-CN/guide/advanced/linkages#effects-%E7%94%A8%E4%BE%8B-2
        // *
        // * @param field 当前字段
        // * @param form 当前表单实例
        // */
        // function onFieldValueChange(field, form) {
        //
        // }`}
        //         defaultActionName="onFieldValueChange"
        //           />
        //         }
        //       ]
        //     }
        //   }
    };
};
const onChange = (config) => {
    const { position } = config || {};
    let title = '标签页切换时（onChange）';
    if (ButtonPosition.treeEvent === position) {
        title = '树节点点击时（onChange）';
    }
    return {
        title,
        value: 'onChange',
        position: [ButtonPosition.tabEvent, ButtonPosition.treeEvent],
    };
};
const onTabDeleteItemClick = {
    title: 'tab项删除时',
    value: 'onTabDeleteItemClick',
    position: [ButtonPosition.tabEvent],
};
const onTabAddItemClick = {
    title: 'tab项添加时',
    value: 'onTabAddItemClick',
    position: [ButtonPosition.tabEvent],
};
const onStepItemClick = {
    title: '步骤项点击时（onClick）',
    value: 'onStepItemClick',
    position: [ButtonPosition.stepEvent],
};
const onStatisticCardClick = {
    title: '指标卡选中时（需开启选中）',
    value: 'onStatisticCardClick',
    position: [ButtonPosition.statisticCardEvent],
};
const onStatisticCardItemClick = {
    title: '指标卡点击时（onClick）',
    value: 'onStatisticCardItemClick',
    position: [ButtonPosition.statisticCardEvent],
};
const onStatisticCardRequestFinish = {
    title: '指标卡远程请求完成后',
    value: 'onStatisticCardRequestFinish',
    position: [ButtonPosition.statisticCardEvent],
};
const onTabRequestFinish = {
    title: '远程请求加载完成时',
    value: 'onTabRequestFinish',
    position: [ButtonPosition.tabEvent],
};
const onSearch = {
    title: '点击查询按钮时',
    value: 'onSearch',
    position: [ButtonPosition.filterEvent],
};
const onDefaultValueFinished = {
    title: '设置默认值完成后',
    value: 'onDefaultValueFinished',
    position: [ButtonPosition.filterEvent, ButtonPosition.formEvent],
};
const onBlur = {
    title: '输入框失去焦点时（onBlur）',
    value: 'onBlur',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
        ButtonPosition.filterItemEvent,
    ],
};
const onFieldReact = {
    title: '动态计算本字段的值（onFieldReact）',
    value: 'onFieldReact',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
    ],
};
const onDrop = {
    title: '拖拽完成后（onDrop）',
    value: 'onDrop',
    position: [ButtonPosition.treeEvent],
};
const onFormStepItemClick = {
    title: '步骤点击时',
    value: 'onFormStepItemClick',
    position: [ButtonPosition.formEvent],
};
const onTablePageChange = {
    title: '表格分页切换时',
    value: 'onTablePageChange',
    position: [ButtonPosition.tableEvent],
};
const onTableRemoteDataChange = {
    title: '表格远程请求成功',
    value: 'onTableRemoteDataChange',
    position: [ButtonPosition.tableEvent],
};
const onEntryItemClick = {
    title: '入口点击后',
    value: 'onEntryItemClick',
    position: [ButtonPosition.entryPointEvent],
};
const onListItemClick = {
    title: '列表项点击后',
    value: 'onListItemClick',
    position: [ButtonPosition.listItemEvent],
};
const onListOnChange = {
    title: '列表选中项改变时（onChange 需开启选中模式）',
    value: 'onListActivityKeyChange',
    position: [ButtonPosition.listItemEvent],
};
const onDialogClose = {
    title: '弹窗关闭时',
    value: 'onDialogClose',
    position: [ButtonPosition.dialogEvent],
};
export const componentMap = {
    onFieldInputValueChange,
    onChange,
    onTabRequestFinish,
    onFieldValueChange,
    onSearch,
    onBlur,
    onStepItemClick,
    onFieldReact,
    onDrop,
    onFormStepItemClick,
    onDefaultValueFinished,
    onFieldInitialValueChange,
    onFieldInit,
    onTablePageChange,
    onTableRemoteDataChange,
    onStatisticCardClick,
    onStatisticCardRequestFinish,
    onTabDeleteItemClick,
    onTabAddItemClick,
    onEntryItemClick,
    onListItemClick,
    onDialogClose,
    onStatisticCardItemClick,
    onListOnChange,
};
// // 获取列表
// export function getValidatorListByPosition(position) {
//   const defaultValidatorList = Object.keys(componentMap);
//   const result = [];
//   if (position !== undefined) {
//     defaultValidatorList.forEach((name) => {
//       const item = componentMap[name];
//       if (item && item.position && item.position.includes(position)) {
//         result.push(item);
//       }
//     });
//   }
//   return result;
// }
export function createEventSetters(config = {}) {
    const { position, initialValue, title } = config;
    const eventList = getItemListByPosition({
        position,
        componentMap,
    });
    const eventPrototypeList = getItemPrototypeListByPosition({
        position,
        componentMap,
        primaryKey: 'name',
    });
    if (eventList?.length === 0) {
        return [];
    }
    // const tempButtonList = getButtonListByPosition(position);
    // let configure = [];
    // if (buttonPrototypeList?.length > 0 || eventPrototypeList?.length > 0) {
    // configure.push({
    //   name: 'options',
    //   display: 'plain',
    //   title: '请完善事件的配置',
    //   setter: {
    //     componentName: 'ObjectSetter',
    //     props: {
    //       config: {
    //         items: [
    //           ...eventPrototypeList,
    //           ...buttonPrototypeList,
    //         ],
    //       },
    //     },
    //   },
    // });
    // configure = [...buttonPrototypeList];
    // }
    return [
        {
            name: 'events',
            title: title || '配置字段的回调事件',
            display: 'accordion',
            collapsed: true,
            setter: {
                componentName: 'ArraySetter',
                props: {
                    mode: 'list',
                    itemSetter: {
                        componentName: 'ObjectSetter',
                        // initialValue: {
                        //   "type": "JSFunction",
                        //   "value": "() => {return {primaryKey: String(Math.floor(Math.random() * 10000)),children: \"Title\",optType:\"link\"               };}"
                        // },
                        initialValue: initialValue || {
                            optType: 'jsAction',
                        },
                        props: (field) => {
                            const configure = [
                                ...getButtonPrototypeListByPosition(position, {
                                    __field: field,
                                }),
                            ];
                            let defaultButtonList = [
                                {
                                    label: '动作编排',
                                    value: 'flowAction',
                                },
                                {
                                    label: 'js脚本（建议使用动作编排）',
                                    value: 'jsAction',
                                },
                            ];
                            if ([
                                ButtonPosition.filterItemEvent,
                                ButtonPosition.formItemEvent,
                                ButtonPosition.formDialogItemEvent,
                                ButtonPosition.tabEvent,
                                ButtonPosition.filterEvent,
                                ButtonPosition.treeEvent,
                                ButtonPosition.cnArraySubAreaCardItemEvent,
                                ButtonPosition.treeEvent,
                                ButtonPosition.formEvent,
                                ButtonPosition.statisticCardEvent,
                                ButtonPosition.entryPointEvent,
                                ButtonPosition.listItemEvent,
                                ButtonPosition.dialogEvent,
                            ].includes(position)) {
                                const buttonList = getButtonListByPosition(position);
                                if (Array.isArray(buttonList) && buttonList.length > 0) {
                                    const extraButtonList = buttonList
                                        .filter((item) => {
                                        return (item?.value !== 'jsAction' &&
                                            item?.value !== 'flowAction');
                                    })
                                        .map((item) => ({ label: item.title, value: item.value }));
                                    defaultButtonList = [
                                        ...extraButtonList,
                                        ...defaultButtonList,
                                    ];
                                }
                            }
                            if (Array.isArray(configure)) {
                                configure.forEach((item) => {
                                    if (item) {
                                        item.condition = function (prop) {
                                            if (item?.__optType) {
                                                const tempResult = item?.__optType ===
                                                    prop?.parent?.getValue?.()?.optType;
                                                if (typeof item?.originalCondition === 'function') {
                                                    return tempResult && item?.originalCondition(prop);
                                                }
                                                return tempResult;
                                            }
                                        };
                                    }
                                });
                            }
                            return {
                                config: {
                                    items: [
                                        {
                                            name: 'name',
                                            display: 'inline',
                                            title: '事件类型',
                                            isRequired: true,
                                            setter: {
                                                componentName: 'SelectSetter',
                                                props: {
                                                    options: eventList,
                                                },
                                            },
                                            // extraProps: {
                                            //   setValue(target, value) {
                                            //     const defaultProps = getItemDefaultProps(componentMap, position, value);
                                            //     if (defaultProps && Object.keys(defaultProps)?.length > 0) {
                                            //       target?.parent?.setPropValue?.('options', defaultProps);
                                            //     } else {
                                            //       target?.parent?.setPropValue?.('options', {});
                                            //     }
                                            //   },
                                            // },
                                        },
                                        {
                                            name: 'optType',
                                            title: '动作类型',
                                            isRequired: true,
                                            // defaultValue: 'jsAction',
                                            // extraProps: {
                                            //   setValue(target, value) {
                                            //     const currentRowValue = target?.parent?.getValue();
                                            //     const newButtonInitialValue = getItemDefaultProps(position, value);
                                            //     target?.parent.setValue({
                                            //       ...currentRowValue,
                                            //       optType: value,
                                            //       options: newButtonInitialValue,
                                            //     });
                                            //   },
                                            // },
                                            // "extraProps": {
                                            //   "setValue": {
                                            //     "type": "JSFunction",
                                            //     "value": "(target, value) => {console.log(value,1111)}"
                                            //   }
                                            // },
                                            // initialValue: tempButtonList[0]?.value,
                                            setter: {
                                                componentName: 'CnSelectSetter',
                                                props: {
                                                    options: defaultButtonList,
                                                    selectProps: {
                                                        showSearch: true,
                                                    },
                                                },
                                            },
                                        },
                                        ...configure,
                                    ],
                                },
                            };
                        },
                    },
                },
            },
        },
    ];
    return [];
}
