import { useEffect, useState } from 'react';
import { dataOriginRequest, dataOriginStatic } from "@/common/util/const";
import { calculateTextExprValue } from "@/common/util/util";
import { makeRequest } from "@/common/util/request";
export function useData(config) {
    const [data, setData] = useState();
    const { dataFrom, _context, isDesign, designMockData } = config || {};
    const { dataOrigin, dataSource, requestConfig } = dataFrom || {};
    const calculateData = async () => {
        if (dataOrigin === dataOriginStatic) {
            return calculateTextExprValue(dataSource, {
                recordDataSource: {},
                state: _context?.state,
            });
        }
        else if (dataOrigin === dataOriginRequest) {
            return makeRequest({
                buttonConfig: {
                    options: {
                        requestConfig,
                    },
                },
                recordDataSource: {},
                state: _context?.state,
                needSuccessToast: false,
                _context,
            }).then((res) => {
                return res?.data;
            }).catch(() => {
                return undefined;
            });
        }
    };
    useEffect(() => {
        async function fetchData() {
            const tempData = await calculateData();
            if (tempData) {
                setData(tempData);
            }
            else if (isDesign && designMockData) {
                setData(designMockData);
            }
        }
        fetchData();
    }, []);
    useEffect(() => {
        if (isDesign) {
            if (dataFrom?.dataOrigin === dataOriginStatic) {
                const p = calculateData();
                p.then(res => {
                    setData(res);
                });
            }
        }
    }, [dataFrom?.dataSource]);
    return {
        data,
        setData,
        calculateData,
    };
}
