import { executeFunction, isArrayNotEmpty, isEmptyButNotZero, } from '@/common/util/util';
import { __bizExtendConfig__ } from '@/common/util/const';
import isPlainObject from 'lodash/isPlainObject';
import { cnUtils } from '@cainiaofe/cn-ui';
const { pluginManager } = cnUtils || {};
if (pluginManager) {
    pluginManager?.getGlobalPlugin?.();
    pluginManager?.loadFullPlugins?.();
}
function getAllPluginSetterByComponentName(componentName) {
    let result = [];
    const plugins = pluginManager?.getPluginsByComponentName?.(componentName);
    if (isArrayNotEmpty(plugins)) {
        plugins.forEach((item) => {
            const temp = executeFunction(item?.lowCodeConfig?.getPrototypeList);
            if (isArrayNotEmpty(temp)) {
                result = [...result, ...temp];
            }
        });
    }
    return result;
}
function getBizExtendPrototype(config) {
    const { componentName } = config || {};
    if (pluginManager && componentName) {
        const setters = getAllPluginSetterByComponentName(componentName);
        if (isArrayNotEmpty(setters)) {
            return [
                {
                    name: __bizExtendConfig__,
                    title: '业务扩展配置',
                    display: 'accordion',
                    extraProps: {
                        defaultCollapsed: true,
                    },
                    setter: {
                        componentName: 'ObjectSetter',
                        props: {
                            config: {
                                items: [...setters],
                            },
                        },
                    },
                },
            ];
        }
    }
    return [];
}
function getBizExtendProps(config) {
    const { props } = config || {};
    let result;
    const { __bizExtendConfig__ } = props || {};
    if (isPlainObject(__bizExtendConfig__)) {
        result = { ...__bizExtendConfig__ };
    }
    return result;
}
/**
 * 获取包含插件扩展props的全量组件props
 * @param props
 * @param componentName
 */
function getAllComponentProps(props, componentName) {
    const extendProps = getBizExtendProps({
        componentName,
        props,
    });
    if (isPlainObject(extendProps)) {
        return { ...extendProps, ...props, __bizExtendConfig__: undefined };
    }
    else {
        return props;
    }
}
/**
 * 处理插件扩展的props
 * @param props
 * @param componentName
 */
function handleBizExtendComponentProps(props, componentName) {
    if (props) {
        const extendProps = getBizExtendProps({
            componentName,
            props,
        });
        if (isPlainObject(extendProps)) {
            Object.keys(extendProps).forEach((key) => {
                const tempV = extendProps[key];
                if (key && !isEmptyButNotZero(tempV)) {
                    props[key] = tempV;
                }
            });
            delete props.__bizExtendConfig__;
        }
    }
}
export { getBizExtendPrototype, getBizExtendProps, getAllComponentProps, handleBizExtendComponentProps, };
