import { DisplayPosition } from '../position/display-position';
import React from 'react';
import { createFormItemSetters } from '@/lowcode/cn-form-alc/prototype/form-item';
import { makeFilterItem, makeFormItemSchema, uuid, } from '@/common/util/util';
import isPlainObject from 'lodash/isPlainObject';
import { componentMap as formComponentMap, CnFilterItem as UICnFilterItem, } from '@cainiaofe/cn-ui';
import './compose.scss';
const Compose = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN014dAJQR1EYIdGPUVaL_!!6000000000363-2-tps-240-144.png',
    title: '组合框',
    componentName: 'Compose',
    component: 'div',
    formComponent: formComponentMap.CnFormGrid,
    filterItemBeforeHandler: (filterItemProps, componentProps) => {
        if (filterItemProps) {
            filterItemProps.name = undefined;
            filterItemProps.className = 'cn-filter-item-compose';
        }
    },
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, isDesign, usedComponentList, } = config || {};
        if (formItem) {
            const { label, name, readOnly: formItemReadOnly, options = {}, } = formItemConfig;
            const { config = [], style } = options;
            const { cols } = style || {};
            const componentProps = formItem['x-component-props'];
            if (isPlainObject(componentProps)) {
                componentProps.config = undefined;
                componentProps.hasClear = undefined;
                // componentProps.cols = 1;
                componentProps.style = {
                    display: 'flex',
                };
            }
            // delete formItem['x-decorator'];
            // delete formItem['x-decorator-props'];
            // formItem.type = 'void';
            formItem.properties = {};
            if (Array.isArray(config) && config.length > 0) {
                config.forEach((item) => {
                    const { label, name, componentName } = item || {};
                    const gridFormItem = makeFormItemSchema({
                        formItemConfig: item,
                        isDesign,
                        urlParams,
                        formValue,
                        state,
                        usedComponentList,
                        formProps,
                        _context,
                        formInstance,
                        decoratorDefaultProps: {
                            style: {
                                marginBottom: 0,
                            },
                        },
                    });
                    if (gridFormItem?.['x-decorator-props']) {
                        gridFormItem['x-decorator-props'].labelCol = {
                            hidden: true,
                        };
                    }
                    formItem.properties[name] = gridFormItem;
                });
            }
        }
    },
    getFormItemDefaultProps: () => {
        return {
            label: '组合框',
            name: `compose_${uuid()}`,
        };
    },
    getRenderDom: (iconfig) => {
        const { filterItemConfig, _context } = iconfig || {};
        const { options } = filterItemConfig || {};
        const { config } = options || {};
        if (Array.isArray(config) && config.length > 0) {
            const result = [];
            config.forEach((item, index) => {
                const { name } = item || {};
                if (name) {
                    const filterItemResult = makeFilterItem({
                        ...iconfig,
                        filterItemConfig: item,
                    });
                    if (isPlainObject(filterItemResult)) {
                        const { filterItemProps, component, componentProps, dom } = filterItemResult;
                        let realDom;
                        if (component) {
                            realDom = (<UICnFilterItem key={index} {...filterItemProps} label={undefined} style={{ width: '100%' }}>
                  {React.createElement(component, {
                                    ...componentProps,
                                    name,
                                    _context,
                                })}
                </UICnFilterItem>);
                        }
                        if (realDom) {
                            result.push(realDom);
                        }
                    }
                }
            });
            if (result.length > 0) {
                return (<div className='cn-filter-item-compose-form-item-control'>
            {result}
          </div>);
            }
        }
    },
    getPrototypeList: (position) => {
        return [
            {
                name: 'config',
                title: '筛选项配置',
                // display: 'accordion',
                setter: createFormItemSetters({ position }),
            },
        ];
    },
};
export default Compose;
