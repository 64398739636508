import React from 'react';
import { CnNumberPicker } from '@cainiaofe/cn-ui';

export default class NumberPickerSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, onChange, ...rest } = this.props;
    return (
      <CnNumberPicker
        {...rest}
        value={value}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
}
