import { Timeline as UITimeLine, CnTimeline } from '@cainiaofe/cn-ui';
import { executeFunction, isArrayNotEmpty } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { getBizExtendPrototype } from '@/common/manager/plugin';
import { getJSXTemplate } from "@/common/manager/setter-snippet";
const RealTimeLine = CnTimeline || UITimeLine;
const TimelineItem = CnTimeline?.Item || UITimeLine.Item;
const TimeLine = {
    position: [DisplayPosition.form, DisplayPosition.formDialog],
    title: '时间轴',
    componentName: 'TimeLine',
    thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN015DNHPt1JaekcaWKrp_!!6000000001045-2-tps-240-144.png',
    formItemBeforeHandler: (formItem, config) => {
        const { state } = config || {};
        if (formItem) {
            const componentProps = formItem?.['x-component-props'];
            if (componentProps) {
                componentProps.getRequestParams = () => {
                    return {
                        state,
                    };
                };
            }
        }
    },
    formComponent: (props) => {
        const { value, __bizExtendConfig__ = {}, getRequestParams, _customRenderContent } = props || {};
        if (isArrayNotEmpty(value)) {
            let _params = {};
            if (typeof getRequestParams === 'function') {
                _params = getRequestParams();
            }
            return (<RealTimeLine {...(__bizExtendConfig__ ?? {})}>
          {value.map((item) => {
                    const { title, time, state, content, ...rest } = item || {};
                    let realContent = content;
                    if (typeof _customRenderContent === 'function') {
                        const temp = executeFunction(_customRenderContent, item, _params?.state);
                        if (temp) {
                            realContent = temp;
                        }
                    }
                    return (<TimelineItem {...rest} content={realContent} title={title} time={time} state={state}/>);
                })}
        </RealTimeLine>);
        }
        return null;
    },
    getDefaultProps: () => {
        return {};
    },
    // RadioGroup的prototype列表
    getPrototypeList: (position) => {
        return [
            ...getBizExtendPrototype({
                componentName: 'CnTimeline',
            }),
            {
                name: '_customRenderContent',
                title: '自定义渲染内容',
                display: 'inline',
                // className:'cn-inline-setter-reduce',
                setter: getJSXTemplate({
                    position: DisplayPosition.tabItem,
                }),
            },
        ];
    },
};
export default TimeLine;
