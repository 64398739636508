import {
  condition,
  handlePropsDynamicUrl,
  handleSelectDataSource,
  handleSelectRequestConfig,
  setAdvancedConfigToProps,
} from '../../util/util';
import { forwardRef } from 'react';
import {
  CnRadioGroup,
  CnComplexRadioGroup,
  formilyReact,
  componentMap as formComponentMap,
} from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import {
  getDataOriginSetterSnippet,
  getRequestConfigSetterSnippet,
  getStaticDataSourceSnippet,
} from '@/common/manager/setter-snippet';
import { __arrayTableCurrentRow__ } from '@/common/util/expr-const';
import isPlainObject from 'lodash/isPlainObject';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import DocSetter from '@/common/setter/doc-setter';
import {
  getBizExtendPrototype,
  handleBizExtendComponentProps,
} from '@/common/manager/plugin';
const { useField } = formilyReact || {};

const RadioGroup = {
  position: [
    DisplayPosition.filter,
    DisplayPosition.form,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  thumbUrl:
    'https://img.alicdn.com/imgextra/i4/O1CN01gnIgI21wcjYnEdKDO_!!6000000006329-2-tps-240-144.png',
  title: '单选框组',
  componentName: 'RadioGroup',
  component: (props) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    let shape;
    if (type === 'complex') {
      return <CnComplexRadioGroup {...rest} />;
    }
    if (type === 'button') {
      shape = type;
    }
    return <CnRadioGroup shape={shape} {...rest} />;
  },
  formComponent: forwardRef((props, ref) => {
    const field = useField?.();
    handlePropsDynamicUrl(props, {
      field,
    });
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    let shape;
    if (type === 'complex') {
      return <formComponentMap.CnComplexRadioGroup {...rest} />;
    }
    if (type === 'button') {
      shape = type;
    }
    return <formComponentMap.CnRadioGroup shape={shape} {...rest} ref={ref} />;
  }),
  formItemBeforeHandler: (formItem, config) => {
    const { isDesign, urlParams, formValue, state } = config;
    const tempProps = formItem?.['x-component-props'];
    if (isPlainObject(tempProps)) {
      tempProps.getRequestParams = () => {
        return {
          state,
          recordDataSource: formValue,
        };
      };
      const { extra } = tempProps;
      const { direction } = extra || {};
      if (direction) {
        tempProps.direction = direction;
      }
    }
    handleSelectRequestConfig({
      componentProps: formItem?.['x-component-props'],
      isDesign,
      urlParamsDataSource: urlParams,
      recordDataSource: formValue,
      state,
      handleDynamicUrl: true,
    });
    handleSelectDataSource({
      componentProps: formItem?.['x-component-props'],
      state,
      recordDataSource: formValue,
    });
    const componentProps = formItem?.['x-component-props'];
    handleBizExtendComponentProps(componentProps, 'CnRadioGroup');
    setAdvancedConfigToProps(componentProps);
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    handleSelectRequestConfig(config);
    handleSelectDataSource(config);
  },
  getDefaultProps: () => {
    return {
      extra: {
        direction: 'hoz',
      },
      dataSource: [
        {
          label: '选项1',
          value: 'radio1',
        },
        {
          label: '选项2',
          value: 'radio2',
        },
      ],
    };
  },
  // RadioGroup的prototype列表
  getPrototypeList: (position) => {
    const extraServiceProps = {};
    const extraSetters = [
      {
        name: 'disabled',
        display: 'inline',
        title: '是否禁用',
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'BoolSetter',
                title: '布尔（true/false）',
              },
              getJSExpressionPrototype({ type: 'form' }),
            ],
          },
        },
      },
    ];
    const extraStyleSetters = [
      {
        name: 'type',
        title: '展示样式',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '普通型',
                value: 'normal',
              },
              {
                title: '按钮型',
                value: 'button',
              },
            ],
          },
        },
      },
    ];
    if (DisplayPosition.cnArrayTable === position) {
      extraServiceProps.dynamicUrlTemplate = `function(state, { ${__arrayTableCurrentRow__} }) {
  // __arrayTableCurrentRow__: 可编辑表格当前行的数据。
  return '/xxx';
}`;
    }
    // if(DisplayPosition.filter === position) {
    extraSetters.push({
      name: 'description',
      display: 'inline',
      title: '描述',
      setter: 'CnI18nSetter',
    });
    extraSetters.push({
      name: 'icon',
      display: 'inline',
      title: '图标',
      setter: 'CnIconSetter',
    });
    extraSetters.push({
      display: 'none',
      setter: (
        <DocSetter
          content={
            <div className=''>
              请到{' '}
              <a
                target={'_blank'}
                href={
                  'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'
                }
              >
                图标库
              </a>{' '}
              中寻找想用的图标，填写图标type即可。
              <a
                target={'_blank'}
                href={'https://yuque.antfin.com/c-one/next/ld6nao#L77sK'}
              >
                体验Demo
              </a>
            </div>
          }
        />
      ),
    });
    extraStyleSetters[0].setter.props.options.push({
      title: '增强型',
      value: 'complex',
    });
    // }
    const result = [
      getDataOriginSetterSnippet({
        display: 'inline',
        defaultValue: 'static',
      }),
      getRequestConfigSetterSnippet(
        {
          condition: (prop) => {
            return (
              condition(prop, 'RadioGroup', 'componentName') &&
              prop?.parent?.getPropValue?.('dataOrigin') === 'request'
            );
          },
        },
        {
          extraServiceProps,
          position,
        },
      ),
      {
        name: 'dataSource',
        title: '单选框组静态数据',
        display: 'plain',
        // hidden: hidden('RadioGroup'),
        condition(prop) {
          return (
            condition(prop, 'RadioGroup', 'componentName') &&
            prop?.parent?.getPropValue?.('dataOrigin') !== 'request'
          );
        },
        setter: getStaticDataSourceSnippet({
          extraArraySetterItems: extraSetters,
        }),
      },
      {
        name: 'extra',
        title: '样式',
        display: 'plain',
        collapsed: true,
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                ...extraStyleSetters,
                {
                  name: 'direction',
                  title: '选项的排列方式',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        {
                          title: '水平排列',
                          value: 'hoz',
                        },
                        {
                          title: '竖直排列',
                          value: 'ver',
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      },
      ...getBizExtendPrototype({
        componentName: 'CnRadioGroup',
      }),
    ];
    return result;
  },
};
export default RadioGroup;
