import React from 'react';
import CnFormView from './form-view';

class CnForm extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  load = () => {
    this.ref?.current?.load?.();
  };

  reRender = () => {
    this.ref?.current?.reRender?.();
  };

  getFormInstance = () => {
    return this.ref?.current?.getFormInstance?.();
  };

  getFormRef = () => {
    return this.ref?.current?.getFormRef?.();
  };

  render() {
    return <CnFormView ref={this.ref} {...this.props} />;
  }
}
CnForm.displayName = 'CnForm';
export default CnForm;

export { CnForm };
