import { DisplayPosition } from '../position/display-position';
import React from 'react';
import { CnImageViewer } from '@cainiaofe/cn-ui';
import './image-viewer.scss';
const ImageViewer = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN019wOECT1escDB52Zx6_!!6000000003927-2-tps-240-144.png',
    title: '图片浏览',
    componentName: 'ImageViewer',
    // formItemBeforeHandler:(formItem, config)=>{
    //   const { urlParams, formValue, state } = config || {}
    //   if(formItem) {
    //     const componentProps = formItem['x-component-props']
    //     if(componentProps) {
    //
    //     }
    //   }
    // },
    // filterItemBeforeHandler: (filterItemProps, config)=> {
    //   const { componentProps } = config || {};
    //   if(componentProps) {
    //   }
    // },
    getDefaultProps: () => {
        return {
            width: 100,
            height: 100,
        };
    },
    formComponent: (props) => {
        const { value, width, height } = props || {};
        return (<CnImageViewer className={'cn-image-viewer-alc'} src={value} width={width} height={height}/>);
    },
    getPrototypeList: () => {
        return [
            {
                name: 'width',
                title: '图片宽度',
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'NumberSetter',
                                title: '设置宽度（px）',
                            },
                            {
                                componentName: 'CnSelectSetter',
                                title: '常用设置',
                                props: {
                                    options: [
                                        { value: '100%', title: '100%宽' },
                                        { value: 'auto', title: '自动宽度(auto)' },
                                    ],
                                    selectProps: {},
                                },
                            },
                            {
                                componentName: 'StringSetter',
                                title: '设置宽度（字符串）',
                            },
                        ],
                    },
                },
            },
            {
                name: 'height',
                title: '图片高度',
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'NumberSetter',
                                title: '设置高度（px）',
                            },
                            {
                                componentName: 'CnSelectSetter',
                                title: '常用设置',
                                props: {
                                    options: [
                                        { value: '100%', title: '100%高' },
                                        { value: 'auto', title: '自动高度（auto）' },
                                    ],
                                    selectProps: {},
                                },
                            },
                            {
                                componentName: 'StringSetter',
                                title: '设置高度度（字符串）',
                            },
                        ],
                    },
                },
            },
        ];
    },
};
export default ImageViewer;
