import React, { Component } from 'react';
import { Select, Input } from '@cainiaofe/cn-ui';
import isPlainObject from 'lodash/isPlainObject';
import {
  getFlowListDataSource,
  getNodeTitle,
  handleI18nLabel,
  isArrayNotEmpty,
} from '@/common/util/util';
import './index.scss';
import {
  __left_tree_currentItem__,
  __left_tree_currentValue__,
  __statisticCard_activeKey__,
  __statisticCard_currentItem__,
  __list_activeKey__,
  __list_currentItem__,
  __step_activeKey__,
  __step_current__,
  __step_currentItem__,
  __statisticCard_clickKey__,
  __statisticCard_clickItem__,
} from '@/common/util/expr-const';

const { VisualEngine } = window;
const __record__ = '__record__';
const __blank__ = '__blank__';
const __dataSource__ = '__dataSource__';
const __selectedRowKeys__ = '__selectedRowKeys__';
const __selectedRowRecords__ = '__selectedRowRecords__';
const __tableCurrentRow__ = '__tableCurrentRow__';
const __paging__ = '__paging__';
const __tableExtra__ = '__tableExtra__';
const __tableData__ = '__tableData__';
const __tableColumns__ = '__tableColumns__';
const __flowData__ = '__flowData__';
const __tab_activeKey__ = '__tab_activeKey__';
const __tab_removeKey__ = '__tab_removeKey__';
const rangePickerList = [
  {
    label: '开始时间',
    value: '[0]',
  },
  {
    label: '结束时间',
    value: '[1]',
  },
];
const rangePickerMap = {};
rangePickerList.forEach((item) => {
  rangePickerMap[item.value] = item.label;
});

export class ParamSelectSetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      map: {},
    };
  }

  isDataSource = (str) => {
    return str?.group === __dataSource__;
  };

  getDataSourceList = (config) => {
    const { typeList } = config || {};
    const dataSource = VisualEngine?.context?.getManager('dataPool');
    const items = dataSource?.getItems();
    const result = [];
    const dsNodeMap = this.getDsNodeMap();
    if (items?.length > 0) {
      items.forEach((item) => {
        const { name, type, config: dsConfig } = item;
        const label = dsConfig?.description || name;
        const ds = {
          text: label,
          label,
          value: name,
        };
        const node = dsNodeMap[name];
        const title = node?.title;
        ds.componentName = node?.node?.componentName;
        if (title) {
          ds.text = title;
          ds.label = title;
        }
        if (Array.isArray(typeList) && typeList.length > 0) {
          if (typeList.includes(type)) {
            if (title) {
              result.unshift(ds);
            } else {
              result.push(ds);
            }
          }
        } else if (title) {
          result.unshift(ds);
        } else {
          result.push(ds);
        }
      });
    }
    return result;
  };

  getDsNodeMap = () => {
    const nodesMap = VisualEngine?.designer?.currentDocument?.nodesMap;
    const result = {};
    if (nodesMap?.values?.()) {
      for (const item of nodesMap?.values()) {
        const dsName = item?.getPropValue?.('_dataSourceName');
        if (dsName) {
          const title = getNodeTitle(item);
          if (title) {
            result[dsName] = {
              node: item,
              title,
            };
          }
        }
      }
    }
    return result;
  };

  componentDidMount() {
    const { configList, field, dataKey, labelKey, valueKey } = this.props;
    let result = [];
    let map = {};
    if (field) {
      if (Array.isArray(configList) && configList.length > 0) {
        const temp = this.getListByConfigList() || {};
        const { list } = temp;
        if (list?.length > 0) {
          result = list;
        }
        map = temp.map || {};
      } else if (dataKey && labelKey && valueKey) {
        result = this.getList();
      }
    }
    const state = {};
    if (result?.length > 0) {
      state.list = result;
    }
    state.map = map;
    this.setState(state);
  }

  getListByConfigList = () => {
    const { configList, field } = this.props;
    const result = [];
    const map = {};
    if (Array.isArray(configList) && configList.length > 0) {
      configList.forEach((item) => {
        const {
          dataKey,
          labelKey,
          valueKey,
          groupName,
          groupExprName,
          relativeDataSourceName,
          relativeDataSourceNameKey,
          flowList,
          currentFlowIndex,
          handleCustomGroup,
        } = item;
        // if(groupExprName) {
        const extra = {
          label: groupName,
          children: [],
        };
        if (relativeDataSourceName && relativeDataSourceNameKey) {
          const dsName = field
            ?.getNode?.()
            ?.getPropValue?.(relativeDataSourceName);
          if (dsName) {
            const nodesMap = field?.getNode?.()?.document?.nodesMap;
            if (nodesMap?.values()) {
              for (const item of nodesMap.values()) {
                if (
                  item?.getPropValue?.(relativeDataSourceNameKey) === dsName
                ) {
                  const originList = item?.getPropValue?.(dataKey);
                  if (Array.isArray(originList) && originList?.length > 0) {
                    extra.children = this.handleOriginList(
                      originList,
                      labelKey,
                      valueKey,
                      groupExprName,
                    );
                    result.push(extra);
                  }
                }
              }
            }
          }
        } else if (groupExprName === __dataSource__) {
          const dsList = this.getDataSourceList({ typeList: ['URI', 'VALUE'] });
          if (dsList.length > 0) {
            dsList.forEach((item) => {
              if (item) {
                const { label, value, componentName } = item;
                extra.children.push({
                  label: handleI18nLabel(label),
                  value: `${groupExprName}.${value}`,
                  componentName,
                });
              }
            });
          }
          result.push(extra);
        } else if (typeof handleCustomGroup === 'function') {
          handleCustomGroup(extra, field, { itemConfig: item });
          result.push(extra);
        } else if (groupExprName === __flowData__) {
          const flowListDataSource = getFlowListDataSource({
            currentFlowIndex,
            flowList,
          });
          if (isArrayNotEmpty(flowListDataSource)) {
            extra.children = flowListDataSource;
            result.push(extra);
          }
        } else if (field) {
          const originList = field?.getNode?.()?.getPropValue?.(dataKey);
          if (Array.isArray(originList) && originList?.length > 0) {
            extra.children = this.handleOriginList(
              originList,
              labelKey,
              valueKey,
              groupExprName,
            );
            result.push(extra);
          }
        }
        if (groupExprName) {
          map[groupExprName] = {
            ...item,
          };
        }
        // }
      });
    }
    result.push({
      label: '空数据',
      children: [
        {
          label: '不传（空）',
          value: `${__blank__}.undefined`,
        },
      ],
    });

    return {
      list: result,
      map,
    };
  };

  handleOriginList = (originList, labelKey, valueKey, groupExprName) => {
    const result = [];
    if (Array.isArray(originList) && originList.length > 0) {
      originList.forEach((item) => {
        const label = handleI18nLabel(item[labelKey]);
        const value = item[valueKey];
        if (value) {
          result.push({
            label: `${label} （${value}）`,
            value: `${groupExprName || __record__}.${value}`,
            componentName: item?.componentName,
          });
        }
      });
    }
    return result;
  };

  getList = () => {
    const {
      field,
      dataKey,
      labelKey,
      valueKey,
      groupName,
      groupExprName,
      relativeDataSourceName,
      relativeDataSourceNameKey,
    } = this.props;
    // const dialog = this.findParentDialog();

    let originList = [];
    if (relativeDataSourceName && relativeDataSourceNameKey) {
      const dsName = field?.getNode?.()?.getPropValue?.(relativeDataSourceName);
      if (dsName) {
        const nodesMap = field?.getNode?.()?.document?.nodesMap;
        if (nodesMap?.values()) {
          for (const item of nodesMap.values()) {
            if (item?.getPropValue?.(relativeDataSourceNameKey) === dsName) {
              originList = item?.getPropValue?.(dataKey);
              break;
            }
          }
        }
      }
    } else if (dataKey === 'arrayTable') {
      const path = field?.path;
      if (Array.isArray(path) && path.length > 3) {
        const arrayTableIndex = path[1];
        if (arrayTableIndex !== undefined) {
          const formConfig = field?.getNode?.()?.getPropValue?.('config');
          const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
          if (
            arrayTableConfig?.componentName === 'CnArrayTable' &&
            arrayTableConfig?.options?.config?.length > 0
          ) {
            originList = arrayTableConfig?.options?.config;
          }
        }
      }
    } else {
      originList = field?.getNode?.()?.getPropValue?.(dataKey);
    }
    let result = [];
    let newList = [];
    if (originList?.length > 0) {
      newList = this.handleOriginList(
        originList,
        labelKey,
        valueKey,
        groupExprName,
      );
    }
    if (newList?.length > 0) {
      if (groupName) {
        result.push({
          label: groupName,
          children: newList,
        });
      } else {
        result = [...newList];
      }
    }
    const extra = {
      label: '其他数据',
      children: [],
    };

    const dsList = this.getDataSourceList({ typeList: ['URI', 'VALUE'] });

    if (dsList.length > 0) {
      dsList.forEach((item) => {
        if (item) {
          const { label, value, componentName } = item;
          extra.children.push({
            label,
            value: `${__dataSource__}.${value}`,
            componentName,
          });
        }
      });
    }
    result.push(extra);
    result.push({
      label: '空数据',
      children: [
        {
          label: '不传（空）',
          value: `${__blank__}.undefined`,
        },
      ],
    });
    return result;
  };

  handleValue = (value) => {
    if (isPlainObject(value)) {
      return value;
    }
    return {};
  };

  changeValue = (value, newValue) => {
    const { onChange } = this.props;
    const newExprObj = { ...value, ...newValue };
    onChange && onChange(newExprObj);
  };

  // 兼容RangePicker
  isRangePicker = (dsName, list) => {
    let result;
    if (dsName && list?.length > 0) {
      for (const item of list) {
        if (item?.children?.length > 0) {
          const temp = item?.children?.find((item2) => item2.value === dsName);
          if (
            temp?.componentName === 'RangePicker' ||
            temp?.componentName === 'RangeTimePicker'
          ) {
            result = true;
          }
        }
      }
    }
    return result;
  };

  getPopupContainer = (trigger) => {
    const serviceDialog = document.getElementById('service-choice-dialog');
    if (serviceDialog) {
      return serviceDialog;
    }
    const drawerBodyList = document.querySelectorAll('.next-drawer-body');
    if (drawerBodyList?.length > 0) {
      const drawerBody = drawerBodyList[drawerBodyList.length - 1];
      if (drawerBody && drawerBody.children?.length > 0) {
        for (const item of drawerBody.children) {
          if (item?.id && item?.id.startsWith('popupContainer')) {
            return item;
          }
        }
      }
    }
    return trigger.parentNode;
  };

  getSecondParamInput = (config) => {
    const { secondParam, value, currentComponentName, isRangePickerFlag } =
      config || {};
    if (currentComponentName === 'CnTable') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: '当前点击的行数据',
              value: __tableCurrentRow__,
            },
            {
              label: '选中数据主键列表(selectedRowKeys)',
              value: __selectedRowKeys__,
            },
            {
              label: '选中数据列表(selectedRowRecords)',
              value: __selectedRowRecords__,
            },
            {
              label: '表格请求返回的数据(tableData)',
              value: __tableData__,
            },
            {
              label: '表格的列头(columns)',
              value: __tableColumns__,
            },
            {
              label: '表格请求返回的分页信息(paging)',
              value: __paging__,
            },
            {
              label: '请求返回的额外信息(extra)',
              value: __tableExtra__,
            },
          ]}
        />
      );
    } else if (currentComponentName === 'CnTab') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: 'Tab的选中项',
              value: __tab_activeKey__,
            },
            {
              label: 'Tab的删除项',
              value: __tab_removeKey__,
            },
          ]}
        />
      );
    } else if (currentComponentName === 'CnStatisticCard') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: '当前选中的指标卡',
              value: __statisticCard_activeKey__,
            },
            {
              label: '当前选中的指标卡全部信息',
              value: __statisticCard_currentItem__,
            },
            {
              label: '当前点击的指标卡',
              value: __statisticCard_clickKey__,
            },
            {
              label: '当前点击的指标卡全部信息',
              value: __statisticCard_clickItem__,
            },
          ]}
        />
      );
    } else if (currentComponentName === 'CnStep') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: '当前是第几步',
              value: __step_current__,
            },
            {
              label: '当前步骤的编码',
              value: __step_activeKey__,
            },
            {
              label: '当前步骤的信息',
              value: __step_currentItem__,
            },
          ]}
        />
      );
    } else if (currentComponentName === 'CnLeftTree') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: '当前点击的节点编码',
              value: __left_tree_currentValue__,
            },
            {
              label: '当前选中的节点全部信息',
              value: __left_tree_currentItem__,
            },
          ]}
        />
      );
    } else if (currentComponentName === 'CnList') {
      return (
        <Select
          placeholder={'属性名'}
          value={secondParam || ''}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
              thirdParam: undefined,
            });
          }}
          size={'small'}
          autoWidth={false}
          className={'ctes-input'}
          dataSource={[
            {
              label: '列表的选中项',
              value: __list_activeKey__,
            },
            {
              label: '列表选中的全部信息',
              value: __list_currentItem__,
            },
          ]}
        />
      );
    }
    if (isRangePickerFlag) {
      return (
        <Select
          showSearch
          size='small'
          popupContainer={this.getPopupContainer}
          value={secondParam || ''}
          dataSource={rangePickerList}
          onChange={(v) => {
            this.changeValue(value, {
              secondParam: v,
            });
          }}
        />
      );
    }
    return (
      <Input
        size='small'
        placeholder={'属性名'}
        value={secondParam || ''}
        onChange={(v) => {
          this.changeValue(value, {
            secondParam: v,
            thirdParam: undefined,
          });
        }}
      />
    );
  };

  getThirdParamInput = (config) => {
    const { thirdParam, value, secondParam, currentComponentName } =
      config || {};
    if (secondParam === __paging__ && currentComponentName === 'CnTable') {
      return (
        <Select
          value={thirdParam || ''}
          size={'small'}
          autoWidth={false}
          dataSource={[
            {
              label: '当前页(currentPage)',
              value: 'currentPage',
            },
            {
              label: '每页显示几条(pageSize)',
              value: 'pageSize',
            },
            {
              label: '数据总个数(totalCount)',
              value: 'totalCount',
            },
          ]}
          onChange={(v) => {
            this.changeValue(value, {
              thirdParam: v,
            });
          }}
        />
      );
    }
    return (
      <Input
        placeholder={'属性名'}
        value={thirdParam || ''}
        size={'small'}
        className={'ctes-input'}
        onChange={(v) => {
          this.changeValue(value, {
            thirdParam: v,
          });
        }}
      />
    );
  };

  findComponentNameByDs = (ds) => {
    const { list } = this.state;
    if (Array.isArray(list)) {
      for (const item of list) {
        if (Array.isArray(item?.children)) {
          for (const item2 of item.children) {
            if (item2?.value === ds) {
              return item2?.componentName;
            }
          }
        }
      }
    }
  };

  render() {
    const { value } = this.props;
    const { list } = this.state;
    const { group, param, secondParam, thirdParam } = this.handleValue(value);
    const currentDs = group && param ? `${group}.${param}` : '';
    const currentComponentName = this.findComponentNameByDs(currentDs);
    const isRangePickerFlag = this.isRangePicker(currentDs, list);
    const showSecondParam =
      this.isDataSource(value) ||
      isRangePickerFlag ||
      value?.config?.showSecondParam;
    const needThirdParam =
      (currentComponentName === 'CnTable' &&
        (secondParam === __tableCurrentRow__ || secondParam === __paging__)) ||
      (currentComponentName === 'CnStep' &&
        secondParam === __step_currentItem__) ||
      (currentComponentName === 'CnLeftTree' &&
        secondParam === __left_tree_currentItem__);
    return (
      <div
        className={'cn-param-select-setter-wrap'}
        style={{ display: 'flex' }}
      >
        <Select
          showSearch
          size='small'
          autoWidth={false}
          // popupContainer={this.getPopupContainer}
          value={currentDs}
          dataSource={list}
          popupClassName={'cn-param-select-setter-popup'}
          // onChange={this.changeValue.bind(this, 'attr')}
          onChange={(v, m, detail) => {
            const { handleExprConfig } = detail || {};
            const newV = {
              group: undefined,
              param: undefined,
              secondParam: undefined,
            };
            if (v && v?.indexOf('.') !== -1) {
              const tempGroup = v.slice(0, v.indexOf('.')) || '';
              const tempParam = v.replace(`${tempGroup}.`, '');
              if (tempGroup && tempParam) {
                newV.group = tempGroup;
                newV.param = tempParam;
                newV.thirdParam = undefined;
              }
            }
            if (typeof handleExprConfig === 'function') {
              handleExprConfig?.(value, newV);
            } else if (isPlainObject(value)) {
              value.config = undefined;
            }
            this.changeValue(value, newV);
          }}
        />
        {showSecondParam &&
          this.getSecondParamInput({
            value,
            secondParam,
            currentComponentName,
            isRangePickerFlag,
          })}
        {needThirdParam &&
          this.getThirdParamInput({
            value,
            thirdParam,
            secondParam,
            currentComponentName,
          })}
      </div>
    );
  }
}
