import { Button } from '@cainiaofe/cn-ui';
import copy from 'copy-to-clipboard';
import React from 'react';
const IdSetter = (props) => {
    const value = props?.value;
    return (<div style={{ '-webkit-user-select': 'text' }}>
      {value}{' '}
      <Button size={'small'} onClick={() => {
            copy(value);
        }}>
        复制
      </Button>
    </div>);
};
export default IdSetter;
