import { DisplayPosition } from '../position/display-position';
import { CnRating as UIRating } from '@cainiaofe/cn-ui';
const Rating = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '评分组件',
    componentName: 'Rating',
    component: UIRating,
    formComponent: UIRating,
    thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01WTjjJ01r3OvbPibk1_!!6000000005575-2-tps-240-144.png',
    getDefaultProps: () => {
        return {
            count: 5,
            allowClear: true,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'allowHalf',
                title: '允许半星评分',
                display: 'inline',
                setter: 'BoolSetter',
            },
            {
                name: 'count',
                title: '评分总数',
                display: 'inline',
                setter: 'NumberSetter',
            },
        ];
    },
};
export default Rating;
