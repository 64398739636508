import {
  CnCheckboxGroup,
  componentMap as formComponentMap,
  CnTooltip,
  CnComplexCheckboxGroup,
} from '@cainiaofe/cn-ui';
import {
  condition,
  handleSelectDataSource,
  handleSelectRequestConfig,
} from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import {
  getRequestConfigSetterSnippet,
  getStaticDataSourceSnippet,
} from '@/common/manager/setter-snippet';
import { forwardRef } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import DocSetter from '@/common/setter/doc-setter';

const CheckboxGroup = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  thumbUrl:
    'https://img.alicdn.com/imgextra/i3/O1CN01HXb3rx1V6DotoA3cp_!!6000000002603-2-tps-240-144.png',
  title: '复选框组',
  componentName: 'CheckboxGroup',
  component: (props) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    if (type === 'complex') {
      return <CnComplexCheckboxGroup {...rest} />;
    }
    return <CnCheckboxGroup {...rest} />;
  },
  formComponent: forwardRef((props, ref) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    if (type === 'complex') {
      return <formComponentMap.CnComplexCheckboxGroup {...rest} ref={ref} />;
    }
    return <formComponentMap.CnCheckboxGroup {...rest} ref={ref} />;
  }),
  getDefaultProps: () => {
    return {
      dataSource: [
        {
          label: '选项1',
          value: 'value1',
        },
        {
          label: '选项2',
          value: 'value2',
        },
      ],
    };
  },
  formItemBeforeHandler: (formItem, config) => {
    const { isDesign, urlParams, formValue, state } = config;
    const tempProps = formItem?.['x-component-props'];
    if (isPlainObject(tempProps)) {
      const { extra } = tempProps;
      const { direction } = extra || {};
      if (direction) {
        tempProps.direction = direction;
      }
    }
    handleSelectRequestConfig({
      componentProps: formItem?.['x-component-props'],
      isDesign,
      urlParamsDataSource: urlParams,
      recordDataSource: formValue,
      state,
      handleDynamicUrl: true,
    });
    handleSelectDataSource({
      componentProps: formItem?.['x-component-props'],
      state,
      recordDataSource: formValue,
    });
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    handleSelectRequestConfig(config);
    handleSelectDataSource(config);
  },
  getPrototypeList: (position) => {
    const extraSetters = [
      {
        name: 'disabled',
        display: 'inline',
        title: '是否禁用',
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'BoolSetter',
                title: '布尔（true/false）',
              },
              getJSExpressionPrototype({ type: 'form' }),
            ],
          },
        },
      },
      {
        name: 'description',
        display: 'inline',
        title: '描述',
        setter: 'CnI18nSetter',
      },
      {
        name: 'icon',
        display: 'inline',
        title: '图标',
        setter: 'CnIconSetter',
      },
      {
        display: 'none',
        setter: (
          <DocSetter
            content={
              <div className=''>
                请到{' '}
                <a
                  target={'_blank'}
                  href={
                    'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'
                  }
                >
                  图标库
                </a>{' '}
                中寻找想用的图标，填写图标type即可。
                <a
                  target={'_blank'}
                  href={'https://yuque.antfin.com/c-one/next/ld6nao#L77sK'}
                >
                  体验Demo
                </a>
              </div>
            }
          />
        ),
      },
    ];
    // extraSetters.push({
    //   name: 'description',
    //   display: 'inline',
    //   title: '描述',
    //   setter: 'CnI18nSetter',
    // })
    const extraStyleSetters = [
      {
        name: 'type',
        title: '展示样式',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '普通型',
                value: 'normal',
              },
              {
                title: '增强型',
                value: 'complex',
              },
            ],
          },
        },
      },
      {
        name: 'direction',
        title: '选项的排列方式',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: '水平排列',
                value: 'hoz',
              },
              {
                title: '竖直排列',
                value: 'ver',
              },
            ],
          },
        },
      },
    ];
    return [
      {
        name: 'dataOrigin',
        title: '数据来源',
        display: 'inline',
        defaultValue: 'static',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              { title: '远程请求', value: 'request' },
              { title: '静态数据', value: 'static' },
            ],
          },
        },
      },
      getRequestConfigSetterSnippet(
        {
          condition: (prop) => {
            return (
              condition(prop, 'CheckboxGroup', 'componentName') &&
              prop?.parent?.getPropValue?.('dataOrigin') === 'request'
            );
          },
        },
        {
          position,
        },
      ),
      {
        name: 'dataSource',
        title: '复选框组静态数据',
        display: 'plain',
        // hidden: hidden('CheckboxGroup'),
        condition(prop) {
          return (
            condition(prop, 'CheckboxGroup', 'componentName') &&
            prop?.parent?.getPropValue?.('dataOrigin') === 'static'
          );
        },
        setter: getStaticDataSourceSnippet({
          extraArraySetterItems: extraSetters,
        }),
      },
      {
        name: 'extra',
        title: '样式',
        display: 'plain',
        collapsed: true,
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [...extraStyleSetters],
            },
          },
        },
      },
    ];
  },
};
export default CheckboxGroup;
