import { calculateTextExprValue, condition, setAdvancedConfigToProps, } from '../../util/util';
import { CnEmployeeSelect } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import { CnTooltip } from '@cainiaofe/cn-ui';
import React from 'react';
import { __advancedConfig__ } from '@/common/util/const';
import { getMixedSetterSnippet } from '@/common/manager/setter-snippet';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
// function getInitialValue(initialValue){
//   if(this.parent.getParam('componentName').toData() !== 'EmployeeSelect'){
//     return initialValue
//   }
// }
const useTooltip = (<CnTooltip align={'t'} trigger={<div>人员全部信息</div>}>
    <div style={{ width: '150px' }}>
      示例：
      <div>aliWorkNo: "xxx"</div>
      <div>buMail: "xxx@cainiao.com"</div>
      <div>department: "菜鸟集团-菜鸟-菜鸟-xxx部门"</div>
      <div>dingding: "xxx"</div>
      <div>name: "真名"</div>
      <div>nickName: "花名"</div>
      <div>workNo: "工号"</div>
      <div>workStatus: true</div>
    </div>
  </CnTooltip>);
const EmployeeSelect = {
    position: [
        DisplayPosition.form,
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN015WrNdW1kMuUPA5Cg5_!!6000000004670-2-tps-240-144.png',
    title: '员工选择器',
    componentName: 'EmployeeSelect',
    component: CnEmployeeSelect,
    formComponent: CnEmployeeSelect,
    getDefaultProps: () => {
        return {
            mode: 'single',
            serviceType: 'cainiao',
            __advancedConfig__: {
                valuePaddingZero: true,
                isOnJob: true,
            },
            // dataSource: [],
        };
    },
    // select的prototype列表
    getPrototypeList: () => {
        return [
            // {
            //   title: '员工选择器配置',
            //   type: 'group',
            //   display: 'accordion',
            //   collapsed: true,
            //   // hidden: hidden('EmployeeSelect'),
            //   condition(prop){
            //     return condition(prop,'EmployeeSelect','componentName')
            //   },
            // },
            {
                name: 'mode',
                title: '选择模式',
                display: 'inline',
                // supportVariable: true,
                // hidden: hidden('EmployeeSelect'),
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: '单选',
                                value: 'single',
                            },
                            {
                                title: '多选',
                                value: 'multiple',
                            },
                        ],
                    },
                },
            },
            {
                name: 'serviceType',
                display: 'inline',
                title: '数据来源',
                // hidden: hidden('EmployeeSelect'),
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            title: '选择数据来源',
                            componentName: 'RadioGroupSetter',
                            props: {
                                options: [
                                    {
                                        title: '菜鸟',
                                        value: 'cainiao',
                                    },
                                    {
                                        title: '集团',
                                        value: 'ali',
                                    },
                                ],
                            },
                        },
                        getJSExpressionPrototype({
                            type: 'formRequest',
                        }),
                    ],
                }),
            },
            {
                name: 'useDetailValue',
                display: 'inline',
                title: '返回结果的数据格式',
                defaultValue: false,
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: (<CnTooltip align={'t'} trigger={<div>工号</div>}>
                    示例：12345
                  </CnTooltip>),
                                value: false,
                            },
                            {
                                title: useTooltip,
                                value: true,
                            },
                        ],
                    },
                },
            },
            {
                name: 'mokelay',
                display: 'inline',
                title: '是否中心化',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: '是',
                                value: true,
                            },
                            {
                                title: '否',
                                value: false,
                            },
                            {
                                title: '不设置',
                            },
                        ],
                    },
                },
            },
            {
                title: '高级配置',
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'valuePaddingZero',
                                    title: '工号是否补0',
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                                {
                                    title: '员工属性（默认自有）',
                                    name: 'typeList',
                                    display: 'inline',
                                    setter: {
                                        componentName: 'CnSelectSetter',
                                        props: {
                                            options: [
                                                {
                                                    label: '自有',
                                                    value: 'REGULAR',
                                                },
                                                {
                                                    label: '外包',
                                                    value: 'OUTSOURCING',
                                                },
                                                {
                                                    label: '临时工',
                                                    value: 'CASUAL',
                                                },
                                            ],
                                            selectProps: {
                                                hasClear: true,
                                                // 开启多选
                                                mode: 'multiple',
                                            },
                                        },
                                    },
                                },
                                {
                                    name: 'isOnJob',
                                    title: '是否在职（默认在职）',
                                    display: 'inline',
                                    setter: {
                                        componentName: 'RadioGroupSetter',
                                        props: {
                                            options: [
                                                { title: '在职', value: true },
                                                { title: '离职', value: false },
                                                { title: '全部', value: 'all' },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
CnEmployeeSelect.filterBeforeHandler = (props, config) => {
    const newProps = {
        ...props,
    };
    const { serviceType } = props || {};
    const { recordDataSource, state } = config || {};
    if (newProps.dataSource === undefined) {
        delete newProps.dataSource;
    }
    newProps.serviceType = calculateTextExprValue(serviceType, {
        recordDataSource,
        state,
    });
    if (newProps.serviceType !== 'ali') {
        newProps.serviceType = 'cainiao';
    }
    setAdvancedConfigToProps(newProps);
    return newProps;
};
CnEmployeeSelect.formBeforeHandler = CnEmployeeSelect.filterBeforeHandler;
export default EmployeeSelect;
