import { DisplayPosition } from '../position/display-position';
const CnArrayTableIndex = {
    position: [DisplayPosition.cnArrayTable],
    title: '序号',
    componentName: 'CnArrayTableIndex',
    getDefaultProps: () => {
        return {
            width: 50,
        };
    },
    tableColumnBeforeHandler: ({ tableColumn, formItem }) => {
        const width = formItem?.['x-component-props']?.width;
        if (width && tableColumn?.['x-component-props']) {
            tableColumn['x-component-props'].width = width;
        }
    },
    getPrototypeList: () => {
        return [
            {
                name: 'width',
                title: '宽度',
                setter: 'NumberSetter',
            },
        ];
    },
};
export default CnArrayTableIndex;
