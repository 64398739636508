import { CnAsyncSelect, CnNumberPicker } from '@cainiaofe/cn-ui';
import './relative-date-select.scss';
import { timeUnitList, timeUnitList2 } from '@/common/util/expr-const';
export function RelativeDateSelect(props) {
    const { value, onChange, hideTime, componentName } = props;
    const { rela, num, unit, time } = value || {};
    const changeValue = (key, v) => {
        const newV = {};
        if (value) {
            newV.rela = rela;
            newV.num = num;
            newV.unit = unit;
            newV.time = time;
        }
        newV[key] = v;
        onChange(newV);
    };
    let realTimeUnitList = [...timeUnitList];
    if (componentName === 'RangePicker') {
        realTimeUnitList = [...timeUnitList2, ...timeUnitList];
    }
    return (<div className='relative-date-select-wrap'>
      <CnAsyncSelect value={rela} onChange={changeValue.bind(null, 'rela')} size={'small'} dataSource={[
            {
                label: '前',
                value: 'subtract',
            },
            {
                label: '后',
                value: 'add',
            },
        ]} style={{ width: '46px', flex: '0 0 46px' }}/>
      <CnNumberPicker value={num} onChange={changeValue.bind(null, 'num')} size={'small'} min={0} style={{ width: '40px', flex: '0 0 40px' }}/>
      <CnAsyncSelect value={unit} onChange={changeValue.bind(null, 'unit')} size={'small'} dataSource={realTimeUnitList} style={{ width: '46px', flex: '0 0 46px' }}/>
      {!hideTime && (<CnAsyncSelect value={time} onChange={changeValue.bind(null, 'time')} size={'small'} dataSource={[
                {
                    label: '00:00:00',
                    value: 'startOf',
                },
                {
                    label: '此刻',
                    value: 'now',
                },
                {
                    label: '23:59:59',
                    value: 'endOf',
                },
            ]} style={{ width: '60px', flex: '0 0 60px' }}/>)}
    </div>);
}
