import { condition, handleSelectDataSource, handleSelectRequestConfig, setAdvancedConfigToProps, } from '../../util/util';
import { componentMap as formComponentMap, CnCascaderSelect, CnTooltip, formilyReact, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import { getJSExpressionPrototype } from '../common-style';
import { getSelectParamSelectSetter } from '@/common/manager/filter-item/select';
import { handleRequestParams } from '@/common/util/request';
import isPlainObject from 'lodash/isPlainObject';
import { getExprSetterSnippet, getRequestExecuteSetter, getRequestWhenFocusSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { useRef } from 'react';
import { formComponentRefStorage, __advancedConfig__, } from '@/common/util/const';
import { componentRefSplit } from '@/common/util/expr-const';
const { useField } = formilyReact;
const CascaderSelect = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01svCpLF1OCuI6pqa9g_!!6000000001670-2-tps-240-144.png',
    title: '级联选择',
    componentName: 'CascaderSelect',
    component: CnCascaderSelect,
    formComponent: (props) => {
        const realRef = useRef(null);
        const { _dataSourceName } = props || {};
        const params = props?.requestConfig?.params;
        const field = useField?.();
        if (Array.isArray(params) && params?.length > 0) {
            let _params = {};
            const { getRequestParams } = props;
            if (typeof getRequestParams === 'function') {
                _params = getRequestParams();
            }
            if (typeof field?.index === 'number') {
                const temp = field.query('..').value();
                const currentRow = temp?.[field.index];
                if (isPlainObject(currentRow)) {
                    _params.tableCurrentRow = currentRow;
                }
            }
            const realParams = handleRequestParams(params, { ..._params });
            if (realParams) {
                if (props?.requestConfig?.method === 'post') {
                    props.requestConfig.data = realParams;
                    delete props.requestConfig.params;
                }
                else {
                    props.requestConfig.params = realParams;
                }
            }
            else {
                delete props.requestConfig.params;
            }
        }
        const extraProps = {
            getRequestParams: undefined,
        };
        const fieldEntire = field?.path?.entire;
        if (_dataSourceName && fieldEntire && realRef) {
            formComponentRefStorage[`${_dataSourceName}${componentRefSplit}${fieldEntire}`] = realRef;
        }
        return (<formComponentMap.CnCascaderSelect {...props} {...extraProps} ref={realRef}/>);
    },
    getDefaultProps: () => {
        return {
            dataOrigin: 'request',
            multiple: false,
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state, formProps } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
            ignoreHandleParam: true,
        });
        if (formItem?.['x-component-props']) {
            formItem['x-component-props'].getRequestParams = () => {
                return {
                    urlParamsDataSource: urlParams,
                    recordDataSource: formValue,
                    state,
                };
            };
            formItem['x-component-props']._dataSourceName =
                formProps?._dataSourceName;
        }
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
        setAdvancedConfigToProps(formItem?.['x-component-props']);
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        handleSelectRequestConfig(config);
        handleSelectDataSource(config);
    },
    // select的prototype列表
    getPrototypeList: (position) => {
        const paramSelectSetter = getSelectParamSelectSetter({ position });
        let extraConfigSetter;
        const requestExecuteSetter = getRequestExecuteSetter({
            exprSetter: getExprSetterSnippet({
                position,
                ignoreArrayTableCurrentRow: true,
            }),
        });
        if (requestExecuteSetter) {
            extraConfigSetter = [requestExecuteSetter];
        }
        return [
            {
                name: 'multiple',
                title: '选择模式',
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: '单选',
                                value: false,
                            },
                            {
                                title: '多选',
                                value: true,
                            },
                        ],
                    },
                },
            },
            {
                name: 'dataOrigin',
                title: '数据来源',
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: '远程请求', value: 'request' },
                            { title: '静态数据', value: 'static' },
                        ],
                    },
                },
            },
            {
                name: 'requestConfig',
                title: '查询服务',
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        mockDataTemplate: {
                            success: true,
                            data: [
                                {
                                    label: '选项一',
                                    value: 'first',
                                    children: [
                                        {
                                            label: '子选项一',
                                            value: 'sub1',
                                        },
                                    ],
                                },
                                {
                                    label: '选项二',
                                    value: 'second',
                                },
                            ],
                        },
                        paramTitleDom: <div className=''>请求参数配置：</div>,
                        responseDom: (<div style={{ paddingTop: '10px' }}>
                请求返回结果的数据结构：
                <a target={'_blank'} href='https://yuque.antfin.com/cn-framework-committee/cn-ui-data-structure/cn-async-select#iEuF0'>
                  接口文档
                </a>{' '}
                <CnTooltip v2 align={'t'} trigger={<a>接口预览</a>}>
                  <div style={{ width: '200px', height: '260px' }}>
                    <img style={{ width: '100%' }} src='https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg'/>
                  </div>
                </CnTooltip>
              </div>),
                        buttonText: '选择请求API',
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        extraConfigSetter,
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    paramSelectSetter,
                                    {
                                        componentName: 'StringSetter',
                                        title: '字符串',
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: `function(res) {
  // 需要返回的如下的数据结构
  // return {
  //   success: true,
  //   data: [
  //     {
  //        label:"xx",
  //        value:"xx",
  //     }
  //   ]
  // }
 return res;
}`,
                        // paramSetter:{
                        //   componentName: 'ParamSelectSetter',
                        //   props:{
                        //     dataKey: 'config',
                        //     labelKey: 'label',
                        //     valueKey: 'name',
                        //     groupName: '参数列表',
                        //   },
                        //   title:'选择参数',
                        // },
                    },
                },
                // hidden:hidden('Select'),
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            },
            {
                name: 'dataSource',
                title: '编辑静态数据',
                display: 'plain',
                tip: '编辑静态展示数据',
                // initialValue: [],
                setter: getStaticDataSourceSnippet(),
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'static');
                },
            },
            getRequestWhenFocusSetterSnippet(),
            {
                name: 'changeOnSelect',
                title: '允许选中父节点',
                display: 'inline',
                setter: 'BoolSetter',
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('multiple') === false);
                },
            },
            {
                name: 'searchRemote',
                title: '当新输入时 重新发请求',
                display: 'inline',
                tip: '开启时，每次输入都会重新发请求获取数据',
                setter: {
                    componentName: 'BoolSetter',
                    props: {},
                },
            },
            {
                name: 'searchKey',
                title: '远程搜索时的key',
                display: 'inline',
                setter: {
                    componentName: 'StringSetter',
                    props: {},
                },
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('searchRemote') === true);
                },
            },
            {
                title: '异步加载数据的配置',
                name: 'extraProps',
                display: 'accordion',
                collapsed: true,
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'enableRemoteLazyLoad',
                                    title: '开启异步加载',
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                    // condition(prop) {
                                    //   return condition(prop, 'Select', 'componentName') && prop?.parent?.getPropValue?.('mode') === 'multiple';
                                    // },
                                },
                                {
                                    name: 'remoteLazyLoadKey',
                                    title: '异步请求数据时的key',
                                    display: 'inline',
                                    setter: {
                                        componentName: 'StringSetter',
                                        props: {},
                                    },
                                    defaultValue: 'key',
                                },
                            ],
                        },
                    },
                },
            },
            {
                title: '高级配置',
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'canOnlyCheckLeaf',
                                    title: '只允许勾选叶子项',
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                                {
                                    name: 'showSearch',
                                    title: '允许搜索',
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default CascaderSelect;
