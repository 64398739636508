import { DisplayPosition } from '../position/display-position';
import { createFormItemSetters } from '../../../lowcode/cn-form-alc/prototype/form-item';
import { CnArrayTable as UICnArrayTable } from '@cainiaofe/cn-ui';
import { executeFunction, getArrayTableCurrentRowByField2, getRealizeValue, getRunTimeItem, makeFormItemSchema, } from '../../util/util';
import { componentMap as filterItemComponentMap } from './index';
import { createButtonListSetters, getButtonAction, } from '../button';
import { ButtonPosition } from '../position/button-position';
import { handleRequestParams } from '@/common/util/request';
import { columnSuffix } from '@/common/util/const';
import $i18n from 'panda-i18n';
import isEmpty from 'lodash/isEmpty';
import { __arrayTableCurrentRow__, __arrayTableCurrentRowIndex__, __arrayTableCurrentRowByWhole__, __arrayTableCurrentRowIndexByWhole__, __formValue__, } from '@/common/util/expr-const';
import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';
import { getJSXTemplate } from '@/common/manager/setter-snippet';
import { _getFormValues } from '@/common/util/biz-component-prop-name';
const add = 'add';
const remove = 'remove';
const getDefaultButtons = () => {
    return [
        {
            children: '添加一行',
            optType: 'arrayTableAdd',
            primaryKey: add,
        },
        {
            primaryKey: remove,
            children: '删除',
            optType: 'arrayTableRemove',
        },
    ];
};
const CnArrayTable = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01rmYBgM27O2d3S1SuZ_!!6000000007786-2-tps-240-144.png',
    title: '可编辑表格',
    componentName: 'CnArrayTable',
    formItemBeforeHandler: (formItem, config) => {
        const { formInstance, formItemConfig, formProps, isDesign, usedComponentList, urlParams, formValue, state, _context, } = config || {};
        const { _dataSourceName } = formProps || {};
        const { options } = formItemConfig || {};
        const { config: arrayTableFormConfig, buttons, style, __advancedConfig__, } = options || {};
        if (formItem) {
            let isReadOnly = false;
            if (formItem?.readOnly === true) {
                isReadOnly = true;
            }
            formItem.type = 'array';
            let componentProps = {};
            if (!formItem['x-component-props']) {
                formItem['x-component-props'] = componentProps;
            }
            else {
                componentProps = formItem['x-component-props'];
            }
            if (componentProps) {
                componentProps.scroll = {
                    x: 0,
                };
                componentProps.onRow = (record, index) => {
                    return {
                        onClick: () => {
                            // 保证点击行的时候有数据
                            set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayTableCurrentRowByWhole__}`, record);
                            set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayTableCurrentRowIndexByWhole__}`, index);
                        },
                    };
                };
                const { pagination } = componentProps;
                if (typeof __advancedConfig__?.summary === 'function') {
                    componentProps.summary = (data) => {
                        return executeFunction(__advancedConfig__.summary, data, UICnArrayTable?.Summary, _context?.state);
                    };
                }
                formItem.items = {
                    type: 'object',
                    properties: {},
                };
                const { openSort } = style || {};
                if (openSort) {
                    formItem.items.properties.__sortColumn__ = {
                        type: 'void',
                        'x-component': 'CnArrayTableColumn',
                        'x-component-props': {
                            width: 15,
                            title: '排序',
                            align: 'center',
                        },
                        properties: {
                            sort: {
                                type: 'void',
                                'x-component': 'CnArrayTableSortHandle',
                            },
                        },
                    };
                }
                if (Array.isArray(arrayTableFormConfig) &&
                    arrayTableFormConfig.length > 0) {
                    arrayTableFormConfig.forEach((item) => {
                        const { label, name, componentName, width = 70, fixed, } = item || {};
                        if (name && componentName) {
                            const columnName = name + columnSuffix;
                            if (columnName) {
                                const columnTitle = label;
                                const columnFormItem = makeFormItemSchema({
                                    formItemConfig: item,
                                    isDesign,
                                    urlParams,
                                    formValue,
                                    state,
                                    usedComponentList,
                                    formProps,
                                    _context,
                                    formInstance,
                                });
                                const columnItem = {
                                    type: 'void',
                                    'x-component': 'CnArrayTableColumn',
                                    'x-component-props': { title: columnTitle, width, fixed },
                                    properties: {
                                        [name]: columnFormItem || {},
                                    },
                                };
                                if (item.hidden === true) {
                                    columnItem['x-hidden'] = true;
                                }
                                else if (item.hidden?.group === __formValue__) {
                                    // const formValueJudgeHidden = executeObjectExpr(item.hidden, {
                                    //   [__dataSource__]: _context?.state,
                                    //   [__formValue__]: formValue,
                                    // },formValue || {}, _context?.state, {})
                                    // if(formValueJudgeHidden === true) {
                                    //   columnItem['x-hidden'] = true;
                                    // }
                                }
                                // if(columnFormItem && columnFormItem['x-hidden']) {
                                //   columnItem['x-hidden'] = true;
                                // }
                                // if(columnFormItem && columnFormItem['x-disabled']) {
                                //   columnItem['x-disabled'] = true;
                                // }
                                const componentDefine = getRunTimeItem(filterItemComponentMap, componentName);
                                if (componentDefine) {
                                    const tableColumnBeforeHandler = componentDefine?.tableColumnBeforeHandler;
                                    if (typeof tableColumnBeforeHandler === 'function') {
                                        tableColumnBeforeHandler({
                                            tableColumn: columnItem,
                                            formItem: columnFormItem,
                                        });
                                    }
                                }
                                formItem.items.properties[columnName] = columnItem;
                            }
                        }
                    });
                }
                // 处理按钮
                let realButtons = buttons;
                if (!buttons) {
                    realButtons = getDefaultButtons();
                }
                const optButtons = [];
                let addBtn;
                realButtons?.forEach((item) => {
                    if (item.optType === 'arrayTableAdd') {
                        addBtn = item;
                    }
                    else {
                        optButtons.push(item);
                    }
                });
                // 推断是否所有按钮都是隐藏的
                // let judgeAllBtnHidden = true;
                if (optButtons?.length > 0) {
                    // for(let item of optButtons) {
                    // const { hidden } = item || {}
                    // if(hidden?.group === __arrayTableCurrentRow__) {
                    //   judgeAllBtnHidden = false;
                    //   break;
                    // }
                    // const isHidden = executeObjectExpr(hidden, {
                    //   [__dataSource__]: _context?.state,
                    // },{}, _context?.state, {})
                    // if(isHidden !== true) {
                    //   judgeAllBtnHidden = false;
                    //   break;
                    // }
                    // }
                    const optProperties = {};
                    const { operateColumnWidth } = style || {};
                    const tempProps = {
                        title: $i18n.get({ id: 'OperateColumn', dm: '操作列' }),
                        lock: 'right',
                        fixed: 'right',
                    };
                    if (typeof operateColumnWidth === 'number') {
                        tempProps.width = `${operateColumnWidth}px`;
                    }
                    else {
                        tempProps.width = `100px`;
                    }
                    formItem.items.properties.operateColumn = {
                        type: 'void',
                        'x-component': 'CnArrayTableColumn',
                        'x-component-props': tempProps,
                        properties: optProperties,
                    };
                    optButtons?.forEach((item) => {
                        const { children, optType, primaryKey } = item || {};
                        if (optType && children && primaryKey) {
                            const action = getButtonAction({
                                ...item,
                                position: ButtonPosition.arrayTableOperate,
                            });
                            const temp = {
                                type: 'void',
                                title: '',
                            };
                            if (optType === 'arrayTableRemove' ||
                                optType === 'arrayTableMoveUp' ||
                                optType === 'arrayTableMoveDown') {
                                if (isReadOnly) {
                                    return;
                                }
                                temp['x-component'] = `Cn${optType.slice(0, 1).toUpperCase() + optType.slice(1)}`;
                            }
                            else {
                                temp['x-component'] = 'ArrayTableOptButton';
                                const componentDefine = getRunTimeItem({}, optType);
                                if (componentDefine?.component) {
                                    const componentProps = {
                                        text: true,
                                    };
                                    temp['x-component-props'] = componentProps;
                                    const component = getRealizeValue(componentDefine.component);
                                    if (component) {
                                        componentProps.getChildren = () => {
                                            return React.createElement(component, {
                                                children,
                                                buttonConfig: item,
                                                position: ButtonPosition.arrayTableOperate,
                                                _context,
                                                [_getFormValues]: () => {
                                                    return formInstance?.values;
                                                },
                                            });
                                        };
                                    }
                                }
                                else {
                                    const componentProps = {
                                        children: (<span style={{ verticalAlign: 'middle' }}>
                        {children}
                      </span>),
                                        text: true,
                                        type: 'primary',
                                        style: { margin: '0 3px' },
                                    };
                                    temp['x-component-props'] = componentProps;
                                    if (action) {
                                        componentProps.onClick = (arg) => {
                                            // 记录当前点击行数据
                                            if (arg?.field &&
                                                _dataSourceName &&
                                                _context?.state?.valueOf &&
                                                formItemConfig?.name) {
                                                const tempCurrentRow = getArrayTableCurrentRowByField2(arg?.field);
                                                const segments = arg?.field?.path?.segments;
                                                if (isPlainObject(tempCurrentRow)) {
                                                    set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRow__}`, { ...tempCurrentRow });
                                                    const currentIndex = segments?.[segments?.length - 2];
                                                    set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRowIndex__}`, currentIndex);
                                                }
                                                else {
                                                    let currentIndex = segments?.[1];
                                                    if (currentIndex !== 'number') {
                                                        currentIndex = segments?.[segments?.length - 2];
                                                    }
                                                    if (typeof currentIndex === 'number') {
                                                        const currentRow = arg?.field
                                                            ?.query('..')
                                                            ?.value?.()?.[currentIndex];
                                                        if (isPlainObject(currentRow)) {
                                                            set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRow__}`, { ...currentRow });
                                                            set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRowIndex__}`, currentIndex);
                                                        }
                                                    }
                                                }
                                            }
                                            action({
                                                buttonConfig: item,
                                                position: ButtonPosition.arrayTableOperate,
                                                state,
                                                urlParamsDataSource: urlParams,
                                                _context,
                                                arrayTableConfig: config,
                                                field: arg?.field,
                                            }, arg);
                                        };
                                    }
                                }
                            }
                            optProperties[primaryKey] = temp;
                        }
                    });
                    if (isEmpty(optProperties)) {
                        delete formItem.items.properties.operateColumn;
                    }
                }
                if (!isReadOnly && addBtn) {
                    const { children, primaryKey } = addBtn;
                    if (primaryKey) {
                        let addBtnComponentProps;
                        const addBtnDefaultValue = addBtn?.options?.defaultValue;
                        if (Array.isArray(addBtnDefaultValue) &&
                            addBtnDefaultValue.length > 0) {
                            const temp = handleRequestParams(addBtnDefaultValue, {
                                recordDataSource: formValue,
                                state: _context?.state,
                            });
                            if (temp && Object.keys(temp).length > 0) {
                                addBtnComponentProps = {
                                    defaultValue: temp,
                                };
                            }
                        }
                        else if (typeof addBtnDefaultValue === 'function') {
                            addBtnComponentProps = {
                                defaultValue: addBtnDefaultValue.bind(this, {}, _context?.state),
                            };
                        }
                        formItem.properties = {
                            [primaryKey]: {
                                type: 'void',
                                'x-component': 'CnArrayTableAddition',
                                title: children,
                            },
                        };
                        if (addBtnComponentProps) {
                            formItem.properties[primaryKey]['x-component-props'] =
                                addBtnComponentProps;
                        }
                    }
                }
                if (pagination && formItem['x-component-props']) {
                    formItem['x-component-props'].pagination = pagination;
                }
                delete formItem?.['x-component-props']?.title;
            }
        }
    },
    getDefaultProps: () => {
        return {
            config: [
                {
                    label: '序号',
                    name: '_index',
                    componentName: 'CnArrayTableIndex',
                    options: {
                        width: 50,
                    },
                },
            ],
            pagination: {
                pageSize: 10,
            },
        };
    },
    getPrototypeList: () => {
        const position = DisplayPosition.cnArrayTable;
        return [
            {
                name: 'config',
                title: '表格列配置',
                setter: createFormItemSetters({ position }),
            },
            {
                name: 'buttons',
                title: '操作列按钮',
                defaultValue: getDefaultButtons(),
                // supportVariable: true,
                setter: createButtonListSetters({
                    position: ButtonPosition.arrayTableOperate,
                }),
            },
            {
                name: 'style',
                title: '表格样式',
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'operateColumnWidth',
                                    title: '操作列宽度',
                                    setter: 'NumberSetter',
                                },
                                {
                                    name: 'openSort',
                                    title: '开启排序',
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: '__advancedConfig__',
                title: '高级配置',
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'summary',
                                    title: '数据统计',
                                    setter: getJSXTemplate({
                                        position: DisplayPosition.arrayTableSummary,
                                    }),
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: 'pagination',
                title: '分页设置',
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'pageSize',
                                    title: '每页显示条数',
                                    setter: 'NumberSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
        // let configure = [
        //   {
        //     name: 'id',
        //     setter: 'StringSetter',
        //     condition() {
        //       return false;
        //     },
        //   },
        //   {
        //     name: 'label',
        //     title: '字段名称',
        //     display: 'inline',
        //     isRequired: true,
        //     setter: 'StringSetter',
        //   },
        //   {
        //     name: 'name',
        //     title: '字段编码',
        //     display: 'inline',
        //     isRequired: true,
        //     setter: 'StringSetter',
        //   },
        //   {
        //     name: 'componentName',
        //     title: '组件类型',
        //     display: 'inline',
        //     extraProps: {
        //       setValue(target, value) {
        //         const defaultProps = getItemDefaultProps(position, value);
        //         if (defaultProps) {
        //           target?.parent?.setPropValue?.('options', defaultProps);
        //         } else {
        //           target?.parent?.setPropValue?.('options', {});
        //         }
        //         const formItemDefaultProps = getFormItemDefaultProps(position, value);
        //         if (formItemDefaultProps) {
        //           const keys = Object.keys(formItemDefaultProps);
        //           keys.forEach((key) => {
        //             target?.parent?.setPropValue?.(key, formItemDefaultProps[key]);
        //           });
        //         }
        //       },
        //     },
        //     setter: {
        //       componentName: 'SelectSetter',
        //       props: ()=>{
        //         return {
        //           showSearch: true,
        //           options: getItemListByPosition({ position }),
        //         }
        //       }
        //     },
        //   },
        // ]
        // const formItemPrototypeList = getItemPrototypeListByPosition({ position });
        // if (formItemPrototypeList?.length > 0) {
        //   configure.push({
        //     name: 'options',
        //     // display: 'accordion',
        //     display: 'block',
        //     title: '请完善组件的配置项',
        //     condition(prop) {
        //       const componentName = prop?.parent?.getPropValue?.('componentName');
        //       if (componentName) {
        //         const item = getItem(position, componentName) || {};
        //         const { getPrototypeList, configure = []} = item;
        //         if ((getPrototypeList && getPrototypeList()?.length > 0) || configure?.length > 0) {
        //           return true;
        //         }
        //       }
        //       return false;
        //     },
        //     extraProps: {
        //       defaultCollapsed: false,
        //     },
        //     setter: {
        //       componentName: 'ObjectSetter',
        //       props: {
        //         config: {
        //           items: [
        //             ...formItemPrototypeList,
        //           ],
        //         },
        //       },
        //     },
        //   });
        // }
        // if (position) {
        //   configure = [...configure, ...createValidatorSetters({ position: position.replace('display','validator') }), ...getItemStylePrototype({ position })];
        // }
        // return [
        //   {
        //     name: 'config',
        //     title: '表单项配置',
        //     // display: 'block',
        //     // initialValue: [
        //     //
        //     // ],
        //     setter: {
        //       componentName: 'ArraySetter',
        //       props: {
        //         mode: 'list',
        //         extraProps:{
        //           renderFooter(props) {
        //             return <AddOneBtn {...props} />
        //           },
        //         },
        //         itemSetter: {
        //           componentName: 'ObjectSetter',
        //           // initialValue(prop) {
        //           //
        //           // },
        //           props: {
        //             config: {
        //               items: configure,
        //             },
        //           },
        //         },
        //       },
        //     },
        //     // supportVariable: true,
        //   },
        // ];
    },
};
export default CnArrayTable;
