import React from 'react';
import CnFilterView from './filter-view';
import CnFilterProView from './filter-pro-view';
import { CnFilterPro } from '@cainiaofe/cn-ui';

class CnFilter extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  reset = () => {
    this.ref?.current?.reset?.();
  };

  getFormInstance = () => {
    return this.ref?.current?.getFormInstance?.();
  };

  search = () => {
    this.ref?.current?.search?.();
  };

  render() {
    const { isCnFilterPro } = this.props;
    if (isCnFilterPro === false || !CnFilterPro) {
      return <CnFilterView ref={this.ref} {...this.props} />;
    } else {
      return <CnFilterProView ref={this.ref} {...this.props} />;
    }
  }
}
CnFilter.displayName = 'CnFilter';
export default CnFilter;

export { CnFilter };
