import React from 'react';
import CnListView from './list-view';

class CnList extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  load = () => {
    this.ref?.current?.load?.();
  };

  reRender = () => {
    this.ref?.current?.reRender?.();
  };

  render() {
    return <CnListView ref={this.ref} {...this.props} />;
  }
}
CnList.displayName = 'CnList';
export default CnList;

export { CnList };
