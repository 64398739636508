import React, { useCallback } from 'react';
import { CnEntryPoints as UICnEntryPoints } from '@cainiaofe/cn-ui';
import {
  executeEventWithoutJS,
  isDesignMode,
} from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { useData } from '@/common/hook/useData';
import './view.scss';

const CnEntryPoints = (props) => {
  const {
    title = '',
    _context,
    subTitle,
    type,
    buttons,
    handleProps,
    dataFrom,
    events,
    ...rest
  } = props;

  const isDesign = isDesignMode(props);

  const { data = [] } = useData({
    dataFrom,
    _context,
    isDesign,
    designMockData: [{ label: '编辑态的mock数据', value: '0' }],
  });

  const onItemClick = useCallback((item) => {
    executeEventWithoutJS({
      eventType: 'onEntryItemClick',
      events,
      _context,
      position: ButtonPosition.entryPointEvent,
      recordDataSource: item,
    });
  }, []);

  const componentProps = {
    className: 'l2-cn-entry-points',
    viewMore: {
      visible: false,
    },
    style: {
      padding: 0,
    },
    dataSource: data,
    onItemClick,
  };
  return <UICnEntryPoints {...componentProps} />;
};
CnEntryPoints.displayName = 'CnEntryPoints';
export default CnEntryPoints;

export { CnEntryPoints };
