import { DisplayPosition } from '../position/display-position';
import { componentMap as formComponentMap } from '@cainiaofe/cn-ui';
import { getJSXTemplate, getMixedSetterSnippet, getObjectSetterSnippet, } from '@/common/manager/setter-snippet';
import { calculateTextExprValue, executeFunction, makeButtons, uuid, } from '../../util/util';
import { createButtonListSetters } from '@/common/manager/button';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
const CnFormGrid = 'CnFormGrid';
const CnCard = {
    position: [DisplayPosition.formDialog, DisplayPosition.form],
    title: '卡片',
    thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01ekg2Wb1qIgWuFihRE_!!6000000005473-2-tps-240-144.png',
    componentName: 'CnCard',
    formComponent: formComponentMap.CnCard,
    isFormContainer: true,
    allowParentList: ['root', 'CnFormStepItem', 'CnFormTabItem'],
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, getFormInstance, } = config || {};
        const { formStyle = {} } = formProps;
        const { cols, readOnly, layoutMode } = formStyle || {};
        if (formItem) {
            const { label, name, readOnly: formItemReadOnly, options = {}, } = formItemConfig;
            const { buttons = [] } = options;
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                if (componentProps.title) {
                    componentProps.title = calculateTextExprValue(componentProps.title, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (label && !componentProps.title) {
                    componentProps.title = label;
                }
                if (componentProps.titleTips) {
                    componentProps.titleTips = calculateTextExprValue(componentProps.titleTips, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (componentProps?.titleTag?.children) {
                    componentProps.titleTag.children = calculateTextExprValue(componentProps.titleTag.children, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (componentProps?.titleTag && !componentProps?.titleTag?.children) {
                    componentProps.titleTag = null;
                }
                if (Array.isArray(buttons) && buttons.length > 0) {
                    const action = makeButtons({
                        buttons: buttons.map((item) => {
                            let realChildren = item?.children;
                            if (typeof item?.children === 'function') {
                                realChildren = executeFunction(item.children, formValue, state);
                            }
                            return {
                                ...item,
                                size: 'small',
                                position: ButtonPosition.cardAction,
                                children: realChildren,
                            };
                        }),
                        _context,
                        state,
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        formInstance,
                        getFormInstance,
                    });
                    if (action) {
                        componentProps.action = action;
                        delete componentProps.buttons;
                    }
                }
            }
            // if(formItemReadOnly === undefined) {
            //   formItem.readOnly = readOnly;
            // }else if(isBoolean(formItemReadOnly)) {
            //   formItem.readOnly = formItemReadOnly;
            // }
            delete formItem['x-decorator'];
            formItem.properties = {};
            formItem.title = '';
            formItem.type = 'void';
            const { cols: cardCols, subCardCols } = componentProps;
            const formGridProps = {};
            if (cardCols) {
                formGridProps.cols = cardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            const realLayoutComponent = layoutMode === 'single' ? 'CnFormSingleCol' : CnFormGrid;
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': realLayoutComponent,
                    'x-component-props': formGridProps,
                    properties: {},
                },
                [`${name}_subCard_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': {
                        cols: subCardCols || 1,
                    },
                    properties: {},
                },
            };
        }
    },
    getDefaultProps: () => {
        return {
            foldable: true,
        };
    },
    getFormItemDefaultProps: () => {
        return {
            label: '卡片标题',
            name: `card_${uuid()}`,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'title',
                title: '标题',
                display: 'inline',
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            title: '文本',
                            componentName: 'CnI18nSetter',
                        },
                        getJSExpressionPrototype({
                            type: 'formRequest',
                        }),
                    ],
                }),
            },
            {
                name: 'titleTips',
                title: '标题提示',
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: '文本',
                                componentName: 'CnI18nSetter',
                            },
                            getJSXTemplate({
                                position: ButtonPosition.form,
                            }),
                        ],
                    },
                },
            },
            {
                name: 'titleTag',
                title: '标题标签',
                display: 'plain',
                setter: getObjectSetterSnippet({
                    items: [
                        {
                            name: 'children',
                            title: '标题右侧标签',
                            display: 'inline',
                            setter: getMixedSetterSnippet({
                                setters: [
                                    {
                                        title: '文本',
                                        componentName: 'CnI18nSetter',
                                    },
                                    getJSExpressionPrototype({
                                        type: 'form',
                                    }),
                                ],
                            }),
                        },
                    ],
                }),
            },
            {
                name: 'desc',
                title: '描述',
                display: 'inline',
                setter: 'CnI18nSetter',
            },
            {
                name: 'foldable',
                title: '支持折叠',
                display: 'inline',
                setter: 'BoolSetter',
            },
            {
                name: 'icon',
                title: '左侧图标',
                display: 'inline',
                setter: 'CnIconSetter',
            },
            {
                name: 'subCardCols',
                title: '子卡片布局',
                display: 'inline',
                setter: {
                    title: '选择布局',
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { value: 1, title: '单子卡片' },
                            { value: 2, title: '双子卡片' },
                        ],
                    },
                },
            },
            {
                name: 'buttons',
                title: '卡片右侧按钮',
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: createButtonListSetters({
                    position: ButtonPosition.cardAction,
                }),
            },
        ];
    },
};
export default CnCard;
