import { DisplayPosition } from '../position/display-position';
import { CnRange, componentMap as formComponentMap } from '@cainiaofe/cn-ui';
const Range = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title: '滑动输入条',
    componentName: 'Range',
    component: CnRange,
    formComponent: formComponentMap.CnRange,
};
export default Range;
