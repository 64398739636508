export var ButtonPosition;
(function (ButtonPosition) {
    // 表格操作列按钮区
    ButtonPosition["tableOperate"] = "btn_tableOperate";
    // 表格cell点击
    ButtonPosition["tableCell"] = "btn_tableCell";
    // 表格工具按钮区
    ButtonPosition["tableToolArea"] = "btn_tableToolArea";
    // 表格批量按钮去
    ButtonPosition["tableBatchArea"] = "btn_tableBatchArea";
    // 弹窗按钮区域
    ButtonPosition["dialog"] = "btn_dialog";
    // 表单弹窗按钮区域
    ButtonPosition["formDialog"] = "btn_formDialog";
    // 表单按钮区域
    ButtonPosition["form"] = "btn_form";
    // filter 默认值请求成功的回调动作
    ButtonPosition["filterDefaultValueAfterRequestSuccess"] = "btn_filterDefaultValueAfterRequestSuccess";
    // form 默认值请求成功的回调动作
    ButtonPosition["formDefaultValueAfterRequestSuccess"] = "btn_formDefaultValueAfterRequestSuccess";
    // form提交成功的回调动作
    ButtonPosition["formSubmitAfterRequestSuccess"] = "btn_formSubmitAfterRequestSuccess";
    // formDialog提交成功的回调动作
    ButtonPosition["formDialogSubmitAfterRequestSuccess"] = "btn_formDialogSubmitAfterRequestSuccess";
    // filter 输入框的回调
    ButtonPosition["filterItemEvent"] = "btn_filterItemEvent";
    // form 输入框回调
    ButtonPosition["formItemEvent"] = "btn_formItemEvent";
    // 表单弹窗输入框的回调
    ButtonPosition["formDialogItemEvent"] = "btn_formDialogItemEvent";
    // 可编辑表格输入框的回调
    ButtonPosition["cnArrayTableItemEvent"] = "btn_cnArrayTableItemEvent";
    // 自增卡片输入框的回调
    ButtonPosition["cnArraySubAreaCardItemEvent"] = "btn_cnArraySubAreaCardItemEvent";
    // tab item的点击动作
    ButtonPosition["tabItem"] = "btn_tabItem";
    // 页面初始加载
    ButtonPosition["pageDidMount"] = "btn_pageDidMount";
    // 页面异步数据
    ButtonPosition["pageAsyncRequestComplete"] = "btn_pageAsyncRequestCompelete";
    // 可编辑表格的操作区
    ButtonPosition["arrayTableOperate"] = "btn_arrayTableOperate";
    // 可编辑表格cell点击
    ButtonPosition["arrayTableCell"] = "btn_arrayTableCell";
    // 自增卡片右侧按钮
    ButtonPosition["arraySubAreaCardOperate"] = "btn_arraySubAreaCardOperate";
    // 卡片的右侧操作区按钮
    ButtonPosition["cardAction"] = "btn_cardAction";
    // 子卡片的右侧操作区按钮
    ButtonPosition["subCardAction"] = "btn_subCardAction";
    // 表格操作列按钮请求成功后的回调
    ButtonPosition["tableOperateAfterRequestSuccess"] = "btn_tableOperateAfterRequestSuccess";
    // 批量提交成功的回调动作
    ButtonPosition["tableBatchAreaAfterRequestSuccess"] = "btn_tableBatchAreaAfterRequestSuccess";
    // 表格单元格按钮请求成功后的回调
    ButtonPosition["tableCellAfterRequestSuccess"] = "btn_tableCellAfterRequestSuccess";
    // 表格工具栏请求成功的回调动作
    ButtonPosition["tableToolAreaAfterRequestSuccess"] = "btn_tableToolAreaAfterRequestSuccess";
    // 步骤条底部按钮
    ButtonPosition["step"] = "btn_step";
    // 动作流
    ButtonPosition["flowAction"] = "btn_flowAction";
    // tab 的回调事件
    ButtonPosition["tabEvent"] = "btn_tabEvent";
    // step 的回调事件
    ButtonPosition["stepEvent"] = "btn_stepEvent";
    // filter 的回调事件
    ButtonPosition["filterEvent"] = "btn_filterEvent";
    // 指标卡 的回调事件
    ButtonPosition["statisticCardEvent"] = "btn_statisticCardEvent";
    // form 回调事件
    ButtonPosition["formEvent"] = "btn_formEvent";
    // table 回调事件
    ButtonPosition["tableEvent"] = "btn_tableEvent";
    // 树节点按钮栏
    ButtonPosition["treeNode"] = "btn_treeNode";
    // 树节点的回调
    ButtonPosition["treeEvent"] = "btn_treeEvent";
    // 结果反馈底部按钮栏
    ButtonPosition["result"] = "btn_result";
    // CnPage右侧按钮区
    ButtonPosition["pageRightButton"] = "btn_pageRightButton";
    // 子表格操作列按钮区
    ButtonPosition["childTableOperate"] = "btn_childTableOperate";
    // 表单项右侧按钮区
    ButtonPosition["formItemRightButton"] = "btn_formItemRightButton";
    // 列表右侧按钮
    ButtonPosition["listItemRightButton"] = "btn_listItemRightButton";
    // 入口组件的回调
    ButtonPosition["entryPointEvent"] = "btn_entryPointEvent";
    // 列表项点击的回调
    ButtonPosition["listItemEvent"] = "btn_listItemEvent";
    // 子卡片右侧按钮（单独使用的子卡片，非表单中的子卡片）
    ButtonPosition["subCardActionNotInForm"] = "btn_subCardActionNotInForm";
    // 筛选栏扩展按钮
    ButtonPosition["filterExtendButton"] = "btn_filterExtendButton";
    // 弹窗回调事件
    ButtonPosition["dialogEvent"] = "btn_dialogEvent";
    // 瓷片右侧按钮
    ButtonPosition["tileRightButton"] = "btn_tileRightButton";
    // 指标卡右侧按钮
    ButtonPosition["statisticCardRightButton"] = "btn_statisticCardRightButton";
    // 布局Blocks组件按钮区域
    ButtonPosition["blockButton"] = "btn_blockButton";
})(ButtonPosition || (ButtonPosition = {}));
