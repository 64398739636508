import React from 'react';
import { CnButton, CnDialog } from '@cainiaofe/cn-ui';
import ReactionsBuilder from '@alife/reactions-builder';

const FormilyReactionSetter = (props) => {
  const { value, onChange } = props;
  const [visible, setVisible] = React.useState(false);
  const builderRef = React.useRef(null);
  const closeDialog = () => {
    setVisible(false);
  };

  return (
    <div>
      <CnButton onClick={() => setVisible(true)}>配置表单联动</CnButton>
      <CnDialog
        size={'autoLarge'}
        title='表单联动配置'
        visible={visible}
        onOk={() => {
          const newReactions = builderRef?.current?.getReactions?.();
          if (Array.isArray(newReactions)) {
            onChange(newReactions);
          }
          closeDialog();
        }}
        onCancel={closeDialog}
        onClose={closeDialog}
      >
        <ReactionsBuilder ref={builderRef} reactions={value} />
      </CnDialog>
    </div>
  );
};
export default FormilyReactionSetter;
