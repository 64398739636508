import { DisplayPosition } from '../position/display-position';
import { CnNumberPicker, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
import React from 'react';
import NumberPickerSetter from '@/common/setter/number-picker-setter';
import { getBizExtendPrototype, handleBizExtendComponentProps, } from '@/common/manager/plugin';
import { setAdvancedConfigToProps } from '@/common/util/util';
const NumberPicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01K3NSfo1hM2tR2Ug1Z_!!6000000004262-2-tps-240-144.png',
    title: '数字输入框',
    componentName: 'NumberPicker',
    component: CnNumberPicker,
    formComponent: formComponentMap.CnNumberPicker,
    getFormItemDefaultProps: () => {
        return {
            placeholder: '请输入',
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            handleBizExtendComponentProps(componentProps, 'CnNumberPicker');
            setAdvancedConfigToProps(componentProps);
        }
    },
    // filterItemBeforeHandler:(filterItemProps, componentProps)=>{
    //
    // },
    getPrototypeList: () => {
        return [
            {
                name: 'step',
                title: '步长',
                display: 'inline',
                setter: {
                    componentName: <NumberPickerSetter />,
                    props: {
                        placeholder: '请输入数字增减步长',
                        precision: 2,
                        style: {
                            width: '100%',
                        },
                    },
                },
            },
            {
                name: 'precision',
                title: '精度（小数点位数）',
                display: 'inline',
                setter: 'NumberSetter',
            },
            {
                name: 'max',
                title: '最大值',
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'min',
                title: '最小值',
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'innerAfter',
                title: '数字输入框后附加内容',
                display: 'inline',
                setter: 'StringSetter',
            },
            ...getBizExtendPrototype({
                componentName: 'CnNumberPicker',
            }),
        ];
    },
};
export default NumberPicker;
